/* eslint-disable react-hooks/exhaustive-deps */
import { Spin } from "antd";
import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import toggleAssignCategoryToUserApi from "../../apis/categories-apis/assignCategoryToStoreApi";
import getAllCategoriesApi from "../../apis/categories-apis/getAllCategoriesApis";
import getAllUserCategoriesApi from "../../apis/categories-apis/getAllUserCategoriesApi";
import SearchNotificationsProfile from "../../components/search-notifications-profile/SearchNotificationsProfile";
import UserCategoriesContext from "../../contexts/user-categories-context/UserCategoriesContext";
import UserContext from "../../contexts/user-context/UserProvider";
import checkRes from "../../utils/checkRes";
import errorNotification from "../../utils/errorNotification";
import successNotification from "../../utils/successNotification";
import "./CategoriesPage.scss";

const UserCategoriesPage = () => {
  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
    document.title = "الفئات";
  }, [isMount]);
  const { user } = useContext(UserContext);
  const [userCategories, setUserCategories] = React.useState([]);
  const {
    fetchCount,
    allFetchedCategories,
    setAllFetchedCategories,
    setFetchCount,
    setIsLoading,
    setSelectedCategory,
    selectedCategory,
  } = useContext(UserCategoriesContext);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getAllCategoriesApi();
        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedCategories(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getAllUserCategoriesApi();
        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.length >= 0) {
            const data = res.data.data;
            setUserCategories(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      } catch (e) {
        setIsLoading(false);
        console.log("error res : ", e.response);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  const handleToggleCategory = async (cat) => {
    try {
      setIsLoading(true);
      setSelectedCategory(cat);
      const res = await toggleAssignCategoryToUserApi(
        {
          category_id: cat.id,
        },
        user?.token
      );
      // is response is success
      if (checkRes(res) && res.data?.data) {
        setFetchCount((prev) => prev + 1);
        setSelectedCategory(null);
        setIsLoading(false);
        successNotification({
          title: "العملية تمت بنجاح",
          message: "---",
        });
      } else {
        setIsLoading(false);
        setSelectedCategory(null);
        errorNotification({
          title: "حدث خطأ اثناء العملية",
          message: res?.data?.message || "حاول فى وقت لاحق",
        });
      }
    } catch (error) {
      setIsLoading(false);
      setSelectedCategory(null);
      console.log(error);
    }
  };

  const renderCategories = () => {
    const myCatsIds =
      userCategories?.length > 0 && userCategories.map((c) => c.id);

    return allFetchedCategories.map((cat) => {
      if (myCatsIds?.length > 0 && myCatsIds.includes(cat.id))
        return (
          <li
            className={`category-li selected ${
              selectedCategory?.id === cat.id ? "loading" : ""
            }`}
            onClick={() => handleToggleCategory(cat)}
            key={cat.id}
          >
            <div className="cat-img">
              <img src={cat?.image ? cat.image : ""} alt={cat.name} />
            </div>
            <div className="cat-name">{cat?.name ? cat.name : ""}</div>
            {selectedCategory?.id === cat.id ? (
              <div className="loading-wrap">
                <Spin />
              </div>
            ) : null}
          </li>
        );
      return (
        <li
          className={`category-li ${
            selectedCategory?.id === cat.id ? "loading" : ""
          }`}
          key={cat.id}
          onClick={() => handleToggleCategory(cat)}
        >
          <div className="cat-img">
            <img src={cat?.image ? cat.image : ""} alt={cat.name} />
          </div>
          <div className="cat-name">{cat?.name ? cat.name : ""}</div>
          {selectedCategory?.id === cat.id ? (
            <div className="loading-wrap">
              <Spin />
            </div>
          ) : null}
        </li>
      );
    });
  };

  return (
    <div className="custom-container">
      <div className="categories-page shared-custom-page">
        <div className="page-head-wrap">
          <div className="custom-page-title-profile-wrap">
            <div className="page-title">
              <h1 className="bold-font">فئــات المتجر</h1>
              <p>كشف وعرض كافة فئــات المتجر</p>
            </div>
            <SearchNotificationsProfile />
          </div>
        </div>

        <div className="categories-wrap">
          <ul className="categories-ul">
            {allFetchedCategories?.length > 0 && renderCategories()}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserCategoriesPage;
