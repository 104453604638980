/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { Table, Empty, Pagination } from "antd";
import tableColumns from "./tableColumns";
import checkRes from "../../utils/checkRes";
import { useHistory } from "react-router-dom";
import UserContext from "../../contexts/user-context/UserProvider";
import routerLinks from "../../components/app/routerLinks";
import AllExtrasContext from "../../contexts/all-extras-context/AllExtrasContext";
import getAllProductExtrasApi from "../../apis/extras-apis/getAllProductExtrasApi";

const PageTable = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);

  const {
    isLoading,
    setIsLoading,
    setModalOpened,
    allFetchedExtras,
    setAllFetchedExtras,
    fetchCount,
    setFetchCount,
    setSelectedExtra,
    allExtrasPagination,
    setAllExtrasPagination,
  } = useContext(AllExtrasContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
    }));
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getAllProductExtrasApi(user?.token);
        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedExtras(data);
            if (res.data.data?.pagination) {
              setAllExtrasPagination(res.data.data.pagination);
            }

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchCount, user]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedExtras?.length >= 0) {
      const mappedData = mapDataSource(allFetchedExtras);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedExtras, allFetchedExtras?.length]);

  return (
    <>
      <Table
        locale={{
          emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>,
        }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedExtras,
          setModalOpened,
          setFetchCount,
          setSelectedExtra,
          setIsLoading,
          user?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      <Pagination
        defaultCurrent={1}
        // current={ordersPagination.current_page}
        pageSize={allExtrasPagination.per_page}
        total={allExtrasPagination.total}
        // itemRender={itemRender}
        onChange={(page, pageSize) => {
          setFetchCount((prev) => prev + 1);
          history.push(`${routerLinks.allClients}?page=${page}`);
        }}
        hideOnSinglePage={true}
      />
    </>
  );
};

export default PageTable;
