import axios from 'axios';

const getAllProductsApi = async (token, filterValues) => {
  const url = `/auth-seller-products?page=${
    filterValues.page ? filterValues.page : ''
  }&category_id=${
    filterValues.category_id ? filterValues.category_id : ''
  }&name=${filterValues.name ? filterValues.name : ''}&active=${
    filterValues?.active ? filterValues.active : ''
  }`;

  try {
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default getAllProductsApi;
