import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import AllCategoriesContext from '../../contexts/all-categories-context/AllCategoriesContext';

const PageModal = () => {
  const { modalOpened, setModalOpened, setSelectedCategory, selectedCategory } =
    useContext(AllCategoriesContext);
  return (
    <Modal
      transitionName=""
      className="product-form-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <div className="modal-title">
          {selectedCategory ? 'تعديل الفئة' : 'إضافة فئة جديدة'}
        </div>
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedCategory(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedCategory(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default PageModal;
