/* eslint-disable eqeqeq */
import { ReadOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import countWords from '../../utils/countWords';
import trimWords from '../../utils/trimWords';
import changeOrderStatusApi from '../../apis/orders/chageOrderStatusApi';
import checkRes from '../../utils/checkRes';

const tableColumns = (
  allFetchedOrders,
  setDetailsModalOpened,
  setDelegatesModalOpened,
  setFetchCount,
  setSelectedOrder,
  setIsLoading,
  token
) => {
  // const renderProductNames = (details) => {
  //   return (
  //     <ul className="p-names-ul">
  //       {details.map((obj) => (
  //         <li key={obj?.product?.id} className="product-name">
  //           {obj?.product?.name ? obj.product.name : ''}
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // };

  const handleRenderDetailsClick = (record) => {
    const found = allFetchedOrders.find((place) => place.id === record.id);
    if (found) {
      setSelectedOrder({ ...found });
      setDetailsModalOpened(true);
    }
  };

  const handleOrderStatus = (type) => {
    if (type === 'accept-order') return 2;
    else if (type === 'reject-order') return 3;
    return '';
  };

  const handleSuccessMessage = (type) => {
    if (type === 'accept-order') return 'تمت الموافقة على الطلب';
    else if (type === 'reject-order') return 'تم رفض الطلب';
    return 'العميلة تمت بنجاح';
  };

  const handleChangeOrderStatus = async (record, type) => {
    try {
      setIsLoading(true);
      const res = await changeOrderStatusApi(
        {
          order_id: record.id,
          status: handleOrderStatus(type)
        },
        token
      );
      if (checkRes(res)) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: handleSuccessMessage(type)
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };

  const handleOpenDelegatesModal = (record) => {
    const found = allFetchedOrders.find((place) => place.id === record.id);
    if (found) {
      setSelectedOrder({ ...found });
      setDelegatesModalOpened(true);
    }
  };

  const renderActions = (obj) => {
    if (obj?.status == 1) {
      return (
        <div className="action-btns-wrap">
          <Button
            className="accept-btn"
            type="primary"
            onClick={() => handleChangeOrderStatus(obj, 'accept-order')}
          >
            قبول
          </Button>
          <Button
            className="reject-btn"
            onClick={() => handleChangeOrderStatus(obj, 'reject-order')}
          >
            رفض
          </Button>
        </div>
      );
    } else if (obj?.status == 2) {
      return (
        <div className="action-btns-wrap">
          {obj.is_assign == 1 ? (
            <Button
              className="accept-btn"
              type="primary"
              onClick={() => handleOpenDelegatesModal(obj)}
            >
              تعيين مندوب
            </Button>
          ) : (
            <div className="action-btns-wrap">
              <div className="reject-btn">تعيين مندوب</div>
            </div>
          )}
          {/* <Button
            className="reject-btn"
            onClick={() => handleChangeOrderStatus(obj, 'reject-order')}
          >
            رفض
          </Button> */}
        </div>
      );
    } else if (obj?.status == 3) {
      return (
        <div className="action-btns-wrap">
          <div className="reject-btn">تم رفض الطلب</div>
          {/* <Button
            className="accept-btn"
            type="primary"
            onClick={() => handleChangeOrderStatus(obj, 'accept-order')}
          >
            قبول
          </Button> */}
        </div>
      );
    } else if (obj?.status == 4)
      return (
        <div className="action-btns-wrap">
          <div className="reject-btn">المندوب ( {obj?.agent?.name} )</div>
        </div>
      );
    else if (obj?.status == 10)
      return (
        <div className="action-btns-wrap">
          <div className="reject-btn">
            تم تعيين مندوب خارجى ( {obj?.agent?.name} ){' '}
          </div>
        </div>
      );
    else if (obj?.status == 11 && obj.is_assign == 1) {
      return (
        <div className="action-btns-wrap">
          <Button
            className="accept-btn"
            type="primary"
            onClick={() => handleOpenDelegatesModal(obj)}
          >
            تعيين مندوب
          </Button>
          <div className="action-btns-wrap">
            <div
              className="reject-btn"
              style={{ maxWidth: 162, height: 'auto' }}
            >
              تم رفض الطلب من قبل جميع المندوبين
            </div>
          </div>
          {/* <Button
              className="reject-btn"
              onClick={() => handleChangeOrderStatus(obj, 'reject-order')}
            >
              رفض
            </Button> */}
        </div>
      );
    } else if (obj?.status == 11 && obj.is_assign == 2) {
      return (
        <div className="action-btns-wrap">
          <div className="reject-btn" style={{ maxWidth: 162, height: 'auto' }}>
            تم رفض الطلب من قبل جميع المندوبين
          </div>

          {/* <Button
              className="reject-btn"
              onClick={() => handleChangeOrderStatus(obj, 'reject-order')}
            >
              رفض
            </Button> */}
        </div>
      );
    } else if (obj?.status == 6)
      return (
        <div className="action-btns-wrap">
          <div className="reject-btn">تم تسليم الطلب للعميل</div>
        </div>
      );
    else if (obj?.status == 5)
      return (
        <div className="action-btns-wrap">
          <div className="reject-btn">
            المندوب ( {obj?.agent?.name} ) وافق على الطلب
          </div>
        </div>
      );
    else if (obj?.status == 8)
      return (
        <div className="action-btns-wrap">
          <div className="reject-btn">
            المندوب ( {obj?.agent?.name} ) رفض الطلب
          </div>
        </div>
      );
    else if (obj?.status == 7)
      return (
        <div className="action-btns-wrap">
          <div className="reject-btn">تم إلغاء الطلب</div>
        </div>
      );
    return '----';
  };

  return [
    {
      title: 'إســم العميل',
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.client?.name ? record.client.name : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'التاريخ',
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self" style={{ direction: 'ltr' }}>
              {record?.date
                ? record?.time
                  ? `${record.date} / ${record.time}`
                  : record.date
                : ''}
            </span>
          </div>
        );
      }
    },
    // {
    //   title: 'عنوان العميل',
    //   dataIndex: 'address',
    //   // width: 192,
    //   render: (_, record) => {
    //     if (record.address) {
    //       const wordsCount = countWords(String(record.address));
    //       if (wordsCount > 5) {
    //         return (
    //           <Popover title="اقرأ المزيد" content={String(record.address)}>
    //             <Button size="large" type="text">
    //               <p className="has-read-more">
    //                 <span>{trimWords(String(record.address), 5)}</span>
    //                 <ReadOutlined
    //                   className="read-more-book"
    //                   style={{ margin: '0 4px' }}
    //                 />
    //               </p>
    //             </Button>
    //           </Popover>
    //         );
    //       } else
    //         return (
    //           <div className="row-cell row-text">{String(record.address)}</div>
    //         );
    //     } else {
    //       return '----';
    //     }
    //   }
    // },
    // {
    //   title: 'المشتريات',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     if (record?.details?.length >= 0) {
    //       return (
    //         <Popover
    //           title="اقرأ المزيد"
    //           content={renderProductNames(record.details)}
    //         >
    //           <Button
    //             size="large"
    //             type="dashed"
    //             shape="round"
    //             icon={<EyeOutlined />}
    //           />
    //         </Popover>
    //       );
    //     } else {
    //       return '----';
    //     }
    //   }
    // },

    {
      title: 'سعر المشتريات',
      dataIndex: 'purchases_price',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {String(record?.total) ? record.total : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'التفاصيل',
      dataIndex: 'details',
      // width: 192,
      render: (_, record) => {
        return (
          <Tooltip title="عرض التفاصيل">
            <Button
              size="large"
              type="dashed"
              shape="round"
              icon={<EyeOutlined />}
              onClick={() => handleRenderDetailsClick(record)}
            />
          </Tooltip>
        );
      }
    },

    {
      title: 'الاعدادات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
