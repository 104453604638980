/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import getAllProductExtrasApi from '../apis/extras-apis/getAllProductExtrasApi';
import checkRes from '../utils/checkRes';
const useProductExtras = () => {
  const [allProductExtras, setAllProductExtras] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const fetchInfo = async () => {
      try {
        const res = await getAllProductExtrasApi();

        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllProductExtras(data);
          } else {
            //
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchInfo();

    return () => {
      isMounted = false;
    };
  }, []);

  return allProductExtras;
};

export default useProductExtras;
