import { createContext, useState } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (v) => {},
  isLoadingNotifications: false,
  setIsLoadingNotifications: (v) => {},
  myFetchedNotifications: [],
  setMyFetchedNotifications: (v) => {},
  notificationsPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  // FCM
  fcmNotificationFetchCount: 0,
  setFcmNotificationFetchCount: (v) => {},
  fcmNotificationPayload: null,
  setFcmNotificationPayload: (v) => {}
};

const MyNotificationsContext = createContext(INITIAL_VALUES);

export const MyNotificationsProvider = ({ children }) => {
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(
    INITIAL_VALUES.isLoadingNotifications
  );
  const [myFetchedNotifications, setMyFetchedNotifications] = useState(
    INITIAL_VALUES.myFetchedNotifications
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [notificationsPagination, setNotificationsPagination] = useState(
    INITIAL_VALUES.notificationsPagination
  );
  // FCM
  const [fcmNotificationFetchCount, setFcmNotificationFetchCount] = useState(
    INITIAL_VALUES.fcmNotificationFetchCount
  );
  const [fcmNotificationPayload, setFcmNotificationPayload] = useState(
    INITIAL_VALUES.fcmNotificationPayload
  );

  return (
    <MyNotificationsContext.Provider
      value={{
        isLoadingNotifications,
        setIsLoadingNotifications,
        myFetchedNotifications,
        setMyFetchedNotifications,
        fetchCount,
        setFetchCount,
        notificationsPagination,
        setNotificationsPagination,
        //
        fcmNotificationFetchCount,
        setFcmNotificationFetchCount,
        fcmNotificationPayload,
        setFcmNotificationPayload
      }}
    >
      {children}
    </MyNotificationsContext.Provider>
  );
};

export default MyNotificationsContext;
