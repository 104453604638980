import axios from 'axios';

// level 1=>main , 2=>sub
// cat_id ==> when createing sub category send the cat_id of the main category

const toggleAssignCategoryToUserApi = async (values, token) => {
  try {
    const url = '/add-user-category';
    const res = await axios.post(url, values, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default toggleAssignCategoryToUserApi;
