import { createContext, useState } from 'react';

const INITIAL_VALUES = {
  changePasswordModalOpened: false,
  setChangePasswordModalOpened: (v) => {}
};

const ChangePasswordContext = createContext(INITIAL_VALUES);

export const ChangePasswordProvider = ({ children }) => {
  const [changePasswordModalOpened, setChangePasswordModalOpened] = useState(
    INITIAL_VALUES.changePasswordModalOpened
  );
  return (
    <ChangePasswordContext.Provider
      value={{
        changePasswordModalOpened,
        setChangePasswordModalOpened
      }}
    >
      {children}
    </ChangePasswordContext.Provider>
  );
};

export default ChangePasswordContext;
