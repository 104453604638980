import { useContext } from "react";
import UserContext from "../contexts/user-context/UserProvider";
import { useHistory } from "react-router-dom";
import routerLinks from "../components/app/routerLinks";

const checkSuccessResponse = (res) => {
  // return res?.status === 200 && res?.data?.status === 1;
  return (
    res?.status === 200 &&
    res?.data?.success &&
    res?.data?.response_code === 200
  );
};

const checkUnAuthenticated = (err) => {
  return err?.response?.status === 401;
};

const useCustomApiRequest = () => {
  const history = useHistory();
  const { removeCurrentUser } = useContext(UserContext);

  const customApiRequest = async (
    req,
    successCallback,
    unAuthCallback,
    errorCallback
  ) => {
    try {
      const res = await req;
      console.log("custom res : ", res);
      if (checkSuccessResponse(res)) {
        successCallback &&
          typeof successCallback === "function" &&
          successCallback(res);
      }
    } catch (error) {
      errorCallback &&
        typeof errorCallback === "function" &&
        errorCallback(error);
      console.log("eeeeee : ", error.message);
      console.log("error config : ", error.config);
      console.log("err res : ", error?.response);
      console.log("err req : ", error?.request);
      console.log("err status : ", error?.status);
      if (checkUnAuthenticated(error)) {
        unAuthCallback &&
          typeof unAuthCallback === "function" &&
          unAuthCallback();
        removeCurrentUser();
        history.push(routerLinks.signinPage);
      }
    }
  };

  return customApiRequest;
};

export default useCustomApiRequest;
