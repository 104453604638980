/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { Table, Empty, Pagination } from "antd";
import tableColumns from "./tableColumns";
import UserContext from "../../contexts/user-context/UserProvider";
import OrdersContext from "../../contexts/orders-context/OrdersContext";
import getAllOrdersApi from "../../apis/orders/getAllOrdersApi";
import checkRes from "../../utils/checkRes";
import routerLinks from "../../components/app/routerLinks";
import getAllUserDelegatesApi from "../../apis/delegates/getAllUserDelegatesApi";
import UserDelegatesContext from "../../contexts/user-delegates-context/UserDelegatesContext";
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = () => {
  const history = useHistory();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { user } = useContext(UserContext);
  const {
    isLoading,
    setIsLoading,
    setDetailsModalOpened,
    setDelegatesModalOpened,
    allFetchedOrders,
    setAllFetchedOrders,
    fetchCount,
    setFetchCount,
    setSelectedOrder,
    ordersPagination,
    setOrdersPagination,
    ordersFilter,
  } = useContext(OrdersContext);

  const { setIsLoading: setIsLoadingDelegates, setAllFetchedDelegates } =
    useContext(UserDelegatesContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
    }));
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getAllOrdersApi(user?.token, {
          ...ordersFilter,
          ...values,
        });
        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedOrders(data);
            if (res.data.data?.pagination) {
              setOrdersPagination(res.data.data.pagination);
            }

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedOrders?.length >= 0) {
      const mappedData = mapDataSource(allFetchedOrders);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedOrders, allFetchedOrders?.length]);

  // fetch delegates
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoadingDelegates(true);
        const res = await getAllUserDelegatesApi(user?.token);
        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedDelegates(data);
            setIsLoadingDelegates(false);
          } else {
            setIsLoadingDelegates(false);
          }
        }
      } catch (error) {
        setIsLoadingDelegates(false);
        console.log(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  return (
    <>
      <Table
        locale={{
          emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>,
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedOrders,
          setDetailsModalOpened,
          setDelegatesModalOpened,
          setFetchCount,
          setSelectedOrder,
          setIsLoading,
          user?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      <Pagination
        defaultCurrent={1}
        current={ordersPagination.current_page}
        pageSize={ordersPagination.per_page}
        total={ordersPagination.total}
        showSizeChanger={false}
        // itemRender={itemRender}
        onChange={(page, pageSize) => {
          setFetchCount((prev) => prev + 1);
          history.push(`${routerLinks.orders}?page=${page}`);
        }}
        hideOnSinglePage={true}
      />
    </>
  );
};

export default PageTable;
