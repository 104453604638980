import React from 'react';

const ClientsIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.434"
      height="22.156"
      viewBox="0 0 25.434 22.156"
    >
      <g id="customer" transform="translate(0 -33)">
        <path
          id="Path_8407"
          data-name="Path 8407"
          d="M173.322,41.644A4.322,4.322,0,1,0,169,37.322,4.327,4.327,0,0,0,173.322,41.644Zm0-7.153a2.832,2.832,0,1,1-2.832,2.832A2.835,2.835,0,0,1,173.322,34.49Z"
          transform="translate(-160.605)"
          fill={color ? color : '#5f758e'}
        />
        <path
          id="Path_8408"
          data-name="Path 8408"
          d="M379.732,102.464A2.732,2.732,0,1,0,377,99.732,2.735,2.735,0,0,0,379.732,102.464Zm0-3.974a1.242,1.242,0,1,1-1.242,1.242A1.243,1.243,0,0,1,379.732,98.49Z"
          transform="translate(-358.272 -60.821)"
          fill={color ? color : '#5f758e'}
        />
        <path
          id="Path_8409"
          data-name="Path 8409"
          d="M22.061,241H20.909a3.378,3.378,0,0,0-3.065,1.955A6,6,0,0,0,13.412,241H12.023a6,6,0,0,0-4.433,1.955A3.378,3.378,0,0,0,4.526,241H3.373A3.367,3.367,0,0,0,0,244.353v5.425a1.46,1.46,0,0,0,1.461,1.456H6.018a1.742,1.742,0,0,0,1.733,1.59h9.933a1.742,1.742,0,0,0,1.733-1.59h4.5a1.512,1.512,0,0,0,1.513-1.507v-5.374A3.367,3.367,0,0,0,22.061,241ZM1.49,244.353a1.875,1.875,0,0,1,1.883-1.862H4.526a1.875,1.875,0,0,1,1.883,1.862v.51c-.5,1.3-.4,2.087-.4,4.88H1.49Zm16.443,6.731a.25.25,0,0,1-.249.249H7.751a.25.25,0,0,1-.249-.249v-4.071a4.527,4.527,0,0,1,4.522-4.522h1.389a4.527,4.527,0,0,1,4.522,4.522Zm6.011-1.357c0,.026.278.017-4.521.017,0-2.814.1-3.578-.4-4.88v-.51a1.875,1.875,0,0,1,1.883-1.862h1.152a1.875,1.875,0,0,1,1.883,1.862Z"
          transform="translate(0 -197.667)"
          fill={color ? color : '#5f758e'}
        />
        <path
          id="Path_8410"
          data-name="Path 8410"
          d="M27.732,102.464A2.732,2.732,0,1,0,25,99.732,2.735,2.735,0,0,0,27.732,102.464Zm0-3.974a1.242,1.242,0,1,1-1.242,1.242A1.243,1.243,0,0,1,27.732,98.49Z"
          transform="translate(-23.758 -60.821)"
          fill={color ? color : '#5f758e'}
        />
      </g>
    </svg>
  );
};

export default ClientsIcon;
