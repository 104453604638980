import axios from 'axios';

const getAllUserCategoriesApi = async (token) => {
  // const cancelTokenSource = axios.CancelToken.source();
  try {
    const res = await axios.get(`/user-categories`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
      // validateStatus: (v) => {
      //   console.log('v status: ', v);
      // }
      // cancelToken: cancelTokenSource.token
    });
    return res;
  } catch (e) {
    // if (axios.isCancel(e)) {
    //   console.log('Cought cancel');
    // } else {
    //   console.log('er1 : ', e);
    //   console.log('response error : ', e.response);
    //   console.log('request error : ', e.reuqest);
    //   throw e;
    // }
    // throw new Error(e);
    throw e;
  }
};

export default getAllUserCategoriesApi;
