import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
// import { CategoryPage } from '../../pages/category/CategoryPage';
import NotFoundPage from "../../pages/not-found-page/NotFoundPage";
import routerLinks from "./routerLinks";
import ProfilePage from "../../pages/profile-page/ProfilePage";
import { OrdersProvider } from "../../contexts/orders-context/OrdersContext";
import OrdersPage from "../../pages/orders-page/OrdersPage";
import protectMe from "../../utils/protectMe";
import UserContext from "../../contexts/user-context/UserProvider";
import { UserDelegatesProvider } from "../../contexts/user-delegates-context/UserDelegatesContext";
import { AllDelegatesProvider } from "../../contexts/all-delegates-context/AllDelegatesContext";
import { UserCategoriesProvider } from "../../contexts/user-categories-context/UserCategoriesContext";
import UserCategoriesPage from "../../pages/user-categories-page/UserCategoriesPage";
import ProductsPage from "../../pages/products-page/ProductsPage";
import { ProductsProvider } from "../../contexts/products-context/ProductsContext";
import UserDelegatesPage from "../../pages/user-delegates-page/UserDelegatesPage";
import AllDelegatesPage from "../../pages/all-delegates-page/AllDelegatesPage";
import SellersPage from "../../pages/sellers-page/SellersPage";
import { SellersProvider } from "../../contexts/sellers-context/SellersContext";
import FamiliesPage from "../../pages/families-page/FamiliesPage";
import { FamiliesProvider } from "../../contexts/families-context/FamiliesContext";
import UserClientsPage from "../../pages/user-clients-page/UserClientsPage";
import { UserClientsProvider } from "../../contexts/user-clients-context/UserClientsContext";
import { AllClientsProvider } from "../../contexts/all-clients-context/AllClientsContext";
import AllClientsPage from "../../pages/all-clients-page/AllClientsPage";
import AllCategoriesPage from "../../pages/all-categories-page/AllCategoriesPage";
import { AllCategoriesProvider } from "../../contexts/all-categories-context/AllCategoriesContext";
import { AllExtrasProvider } from "../../contexts/all-extras-context/AllExtrasContext";
import AllExtrasPage from "../../pages/extras-page/AllExtrasPage";
// const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

const Routes = () => {
  const { loggedIn } = useContext(UserContext);
  const { user } = useContext(UserContext);
  return (
    <Switch>
      {/* <Route exact path={routerLinks.homePage}>
        <StatisticsProvider>
          <HomePage />
        </StatisticsProvider>
      </Route> */}

      {protectMe(
        routerLinks.orders,
        // <OrdersProvider>
        <UserDelegatesProvider>
          <OrdersPage />
        </UserDelegatesProvider>,
        // </OrdersProvider>
        12,
        routerLinks.signinPage,
        loggedIn
      )}
      {user?.is_admin &&
        protectMe(
          routerLinks.allCategories,
          <AllCategoriesProvider>
            <AllCategoriesPage />
          </AllCategoriesProvider>,
          113,
          routerLinks.signinPage,
          loggedIn
        )}
      {protectMe(
        routerLinks.userCategories,
        <UserCategoriesProvider>
          <UserCategoriesPage />
        </UserCategoriesProvider>,
        13,
        routerLinks.signinPage,
        loggedIn
      )}
      {user?.is_admin &&
        protectMe(
          routerLinks.allExtras,
          <AllExtrasProvider>
            <AllExtrasPage />
          </AllExtrasProvider>,
          13,
          routerLinks.signinPage,
          loggedIn
        )}
      {protectMe(
        routerLinks.products,
        <ProductsProvider>
          <ProductsPage />
        </ProductsProvider>,
        14,
        routerLinks.signinPage,
        loggedIn
      )}
      {protectMe(
        routerLinks.delegates,
        <UserDelegatesProvider>
          <UserDelegatesPage />
        </UserDelegatesProvider>,
        15,
        routerLinks.signinPage,
        loggedIn
      )}
      {user?.is_admin &&
        protectMe(
          routerLinks.allDelegates,
          <AllDelegatesProvider>
            <AllDelegatesPage />
          </AllDelegatesProvider>,
          16,
          routerLinks.signinPage,
          loggedIn
        )}
      {user?.is_admin &&
        protectMe(
          routerLinks.sellers,
          <SellersProvider>
            <SellersPage />
          </SellersProvider>,
          132,
          routerLinks.signinPage,
          loggedIn
        )}
      {user?.is_admin &&
        protectMe(
          routerLinks.families,
          <FamiliesProvider>
            <FamiliesPage />
          </FamiliesProvider>,
          117,
          routerLinks.signinPage,
          loggedIn
        )}
      {protectMe(
        routerLinks.userClients,
        <UserClientsProvider>
          <UserClientsPage />
        </UserClientsProvider>,
        1001,
        routerLinks.signinPage,
        loggedIn
      )}
      {user?.is_admin &&
        protectMe(
          routerLinks.allClients,
          <AllClientsProvider>
            <AllClientsPage />
          </AllClientsProvider>,
          17,
          routerLinks.signinPage,
          loggedIn
        )}

      {/* {StoreRoutes()} */}
      {/* ./store routes */}
      <Route exact path={routerLinks.ProfilePage} component={ProfilePage} />
      {/* not found page */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
