import EditIcon from '../../common/icons/EditIcon';

const tableColumns = (
  allFetchedCategories,
  setModalOpened,
  setFetchCount,
  setSelectedCategory,
  setIsLoading,
  token
) => {
  const handleEditClick = (record) => {
    const found = allFetchedCategories.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedCategory({ ...found });
      setModalOpened(true);
    }
  };

  return [
    {
      title: 'صورة الإضافة',
      dataIndex: 'image',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="img-wrap">
              {record?.image ? (
                <img
                  style={{
                    width: 62,
                    height: 62,
                    borderRadius: 4,
                    objectFit: 'cover',
                    objectPosition: 'top center',
                    border: '1px solid rgba(0, 0, 0, .1)'
                  }}
                  src={record.image}
                  alt={record.name}
                />
              ) : (
                ''
              )}
            </span>
          </div>
        );
      }
    },
    {
      title: 'إســم الإضافة',
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.name ? record.name : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'الاعدادات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            <div className="edit-btn" onClick={() => handleEditClick(record)}>
              <EditIcon />
              تعديل
            </div>
          </div>
        );
      }
    }
  ];
};

export default tableColumns;
