import axios from 'axios';

const getAllFamiliesApi = async (token, filterValues) => {
  const url = `/all-families?status=${
    filterValues?.status ? filterValues.status : ''
  }&page=${filterValues.page ? filterValues.page : ''}&category_id=${
    filterValues.category_id ? filterValues.category_id : ''
  }&name=${filterValues.name ? filterValues.name : ''}&activity=${
    filterValues.activity ? filterValues.activity : ''
  }`;

  try {
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default getAllFamiliesApi;
