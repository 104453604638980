import React, { useContext } from 'react';
import { Modal, Rate } from 'antd';
import FamiliesContext from '../../contexts/families-context/FamiliesContext';
import './FamiliesPageDetailsModal.scss';

const FamiliesPageDetailsModal = () => {
  const {
    detailsModalOpened,
    setDetailsModalOpened,
    setSelectedFamily,
    selectedFamily
  } = useContext(FamiliesContext);

  const renderSellerMainInfo = () => {
    return (
      <div className="delegate-main-info">
        {selectedFamily?.name && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الإســم بالكــامل</div>
            <div className="info-value">{selectedFamily.name}</div>
          </div>
        )}
        {selectedFamily.phone && (
          <div className="custom-render-info-wrap">
            <div className="info-label">رقــم الهاتف</div>
            <div className="info-value">{selectedFamily.phone}</div>
          </div>
        )}
        {selectedFamily?.email && (
          <div className="custom-render-info-wrap">
            <div className="info-label">البريد الاكترونى</div>
            <div className="info-value">{selectedFamily.email}</div>
          </div>
        )}
        {selectedFamily.information?.nationality && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الجنســـة</div>
            <div className="info-value">
              {selectedFamily.information?.nationality}
            </div>
          </div>
        )}
        {selectedFamily?.address && (
          <div className="custom-render-info-wrap">
            <div className="info-label">العنــوان</div>
            <div className="info-value">{selectedFamily.address}</div>
          </div>
        )}
        {selectedFamily?.information?.identity_number && (
          <div className="custom-render-info-wrap">
            <div className="info-label">رقم الهوية</div>
            <div className="info-value">
              {selectedFamily.information.identity_number}
            </div>
          </div>
        )}
        {selectedFamily?.information?.tax_record && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الرقم الضريبى</div>
            <div className="info-value">
              {selectedFamily.information.tax_record}
            </div>
          </div>
        )}

        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            gridColumn: '1 / -1'
          }}
        >
          <span className="name-it-self">
            <Rate
              allowHalf
              disabled
              style={{ fontSize: 32 }}
              defaultValue={
                selectedFamily.rate ? parseFloat(selectedFamily.rate) : 0
              }
            />
          </span>
        </div>
      </div>
    );
  };

  return (
    <Modal
      transitionName=""
      className="seller-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">تفاصيل الأسرة المنتجة</div>}
      visible={detailsModalOpened}
      onOk={() => {
        setDetailsModalOpened(false);
        setSelectedFamily(null);
      }}
      onCancel={() => {
        setDetailsModalOpened(false);
        setSelectedFamily(null);
      }}
      footer={false}
    >
      <div className="seller-img-wrap">
        <img
          src={selectedFamily?.image ? selectedFamily.image : ''}
          alt="seller"
        />
      </div>

      {renderSellerMainInfo()}
    </Modal>
  );
};

export default FamiliesPageDetailsModal;
