import React from 'react';
import MainAppSearch from './MainAppSearch';
import MainAppProfileMenu from './MainAppProfileMenu';
import MainAppNotifications from './MainAppNotifications';
import { ChangePasswordProvider } from '../../contexts/change-password-context/ChangePasswordContext';
import './SearchNotificationsProfile.scss';
// import { MyNotificationsProvider } from '../../contexts/notifications-context/MyNotificationsContext';

const SearchNotificationsProfile = (props) => {
  const { noSearch = true } = props;

  return (
    <div className="search-notifications-profile-wrap">
      {noSearch ? null : <MainAppSearch />}

      <div className="notifications-profile-wrap">
        {/* <MyNotificationsProvider>
          <MainAppNotifications />
        </MyNotificationsProvider> */}
        <MainAppNotifications />
        <ChangePasswordProvider>
          <MainAppProfileMenu />
        </ChangePasswordProvider>
      </div>
    </div>
  );
};

export default SearchNotificationsProfile;
