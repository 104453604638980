/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useEffect, useContext } from 'react';
import { Tooltip, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import NestedColorsFieldArr from './NestedColorsFieldArr';
import { nanoid } from 'nanoid';
import VerifiedIcon from '../../common/icons/VerifiedIcon';
import BlockedIcon from '../../common/icons/BlockedIcon';
import UserContext from '../../contexts/user-context/UserProvider';
import ProductsContext from '../../contexts/products-context/ProductsContext';
import toggleProductExtraActivationApi from '../../apis/products/toggleProductExtraActivationApi';
import checkRes from '../../utils/checkRes';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import useProductExtras from '../../custom-hooks/useProductExtras';

const RenderExtrasFields = ({
  allFetchedProducts,
  selectedProduct,
  setSelectedProduct,
  allExtras,
  extrasFields,
  removeExtraField,
  watch,
  setValue,
  setError,
  clearErrors,
  control,
  errors,
  register
}) => {
  const allProductExtras = useProductExtras();
  const { user } = useContext(UserContext);
  const { setIsLoading, setFetchCount } = useContext(ProductsContext);
  const findExtraObj = (extraId) => {
    return (
      selectedProduct.product_extras?.length > 0 &&
      selectedProduct.product_extras.find((ex) => ex?.extra?.id == extraId)
    );
  };
  const toggleToggleProductExtraClick = async (extraId) => {
    const foundExtraObj = findExtraObj(extraId);
    if (foundExtraObj?.product_extra_id) {
      try {
        setIsLoading(true);
        const res = await toggleProductExtraActivationApi(
          {
            id: foundExtraObj.product_extra_id
          },
          user?.token
        );
        if (checkRes(res)) {
          setIsLoading(false);
          setFetchCount((prevState) => prevState + 1);
          successNotification({
            title: 'العملية تمت بنجاح',
            message: 'تم تغيير الحالة بنجاح'
          });
        } else {
          setIsLoading(false);
          errorNotification({
            title: 'حدث خطأ اثناء العملية',
            message: res?.data?.message
              ? res.data.message
              : 'من فضلك حاول فى وقت لاحق'
          });
        }
      } catch (error) {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ',
          message: 'حاول فى وقت لاحق'
        });
      }
    }
  };
  const handleToggleProductExtraBtnIcon = (extraId) => {
    const foundExtraObj = findExtraObj(extraId);
    if (foundExtraObj) {
      if (foundExtraObj.active) {
        return <VerifiedIcon color="#fff" />;
      }
      return <BlockedIcon color="#444" />;
    }
  };
  const handleToggleProductExtraBtnText = (extraId) => {
    const foundExtraObj = findExtraObj(extraId);
    if (foundExtraObj) {
      if (foundExtraObj.active) {
        return 'مفعل';
      }
      return 'غير مفعل';
    }
  };
  const handleToggleProductExtraBtnClass = (extraId) => {
    const foundExtraObj = findExtraObj(extraId);
    if (foundExtraObj) {
      if (foundExtraObj.active) {
        return '';
      }
      return 'dimmed';
    }
  };

  useEffect(() => {
    if (allFetchedProducts?.length > 0 && selectedProduct) {
      console.log('all fetched products....: ', allFetchedProducts);
      console.log('fetching ....');
      const found = allFetchedProducts.find(
        (obj) => obj.id === selectedProduct.id
      );
      if (found) {
        setSelectedProduct({ ...found });
      }
    }
  }, [allFetchedProducts, allFetchedProducts?.length]);

  return (
    extrasFields?.length > 0 && (
      <ul className="fields-array-ul without-padding">
        {extrasFields.map((item, index) => {
          return (
            <li className={`extra-field-delete-li`} key={item.id}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                {allExtras?.length > 0 &&
                  allExtras.find(
                    (obj) =>
                      obj.id == watch(`extras.${index}.extra_id`) &&
                      obj.type === 2
                  ) && (
                    <NestedColorsFieldArr
                      allProductExtras={allProductExtras}
                      extrasFields={extrasFields}
                      getNewColorObj={(defaultColor) => {
                        return {
                          color: defaultColor,
                          extra_custom_id: item.extra_custom_id,
                          color_custom_id: nanoid(),
                          is_new_color: true
                        };
                      }}
                      control={control}
                      register={register}
                      nestedIndex={index}
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                      watch={watch}
                    />
                  )}
                {errors?.extras?.length && errors.extras[index]?.colors && (
                  <p style={{ color: 'red' }}>
                    {errors.extras[index].colors.message}
                  </p>
                )}
              </div>
              <div className="fields-delete-btn">
                <div className="li-fields">
                  <div className="select-label-wrap">
                    <p className="label-p">إختار الاضافة</p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        disabled={selectedProduct && item.disabledSelect}
                        name={`extras.${index}.extra_id`}
                        errorMsg={
                          errors?.extras &&
                          errors.extras[index]?.extra_id &&
                          errors.extras[index].extra_id.message
                        }
                        validateStatus={
                          errors?.extras &&
                          errors.extras[index]?.extra_id &&
                          errors?.extras[index]?.extra_id
                            ? 'error'
                            : ''
                        }
                        control={control}
                        setValue={setValue}
                        placeholder="إختار الاضافة"
                        options={
                          allExtras?.length > 0 &&
                          allExtras.map((p) => {
                            return {
                              title: p.name,
                              value: String(p.id)
                            };
                          })
                        }
                        showSearch={true}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        formClassName="create-product-form"
                      />
                    </div>
                  </div>

                  {/* {watch(`extras.${index}.extra_id`) && ( */}
                  <div className="prices-wrap">
                    <div className="text-field-label-wrap">
                      <p className="label-p">سعر الإضافة</p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name={`extras.${index}.price`}
                          type="text"
                          placeholder="سعر الإضافة..."
                          errorMsg={
                            errors?.extras &&
                            errors.extras[index]?.price &&
                            errors.extras[index].price.message
                          }
                          validateStatus={
                            errors?.extras &&
                            errors.extras[index]?.price &&
                            errors?.extras[index]?.price
                              ? 'error'
                              : ''
                          }
                          control={control}
                          defaultValue={
                            item?.price
                              ? item.price
                              : allExtras?.length > 0
                              ? allExtras.find(
                                  (p) =>
                                    // eslint-disable-next-line eqeqeq
                                    p.id == watch(`extras.${index}.extra_id`)
                                )?.price
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {!selectedProduct ? (
                  extrasFields?.length >= 1 &&
                  !selectedProduct && (
                    <Tooltip title="حذف">
                      <Button
                        className="delete-field-btn"
                        size="large"
                        type="dashed"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => removeExtraField(index)}
                      />
                    </Tooltip>
                  )
                ) : selectedProduct && item.disabledSelect ? (
                  <Button
                    className={`custom-shared-btn custom-extra-btn ${handleToggleProductExtraBtnClass(
                      item.extra_id
                    )}`}
                    type="primary"
                    size="large"
                    icon={handleToggleProductExtraBtnIcon(item.extra_id)}
                    onClick={() => toggleToggleProductExtraClick(item.extra_id)}
                  >
                    {handleToggleProductExtraBtnText(item.extra_id)}
                  </Button>
                ) : (
                  <Tooltip title="حذف">
                    <Button
                      className="delete-field-btn"
                      size="large"
                      type="dashed"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => removeExtraField(index)}
                    />
                  </Tooltip>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    )
  );
};

export default RenderExtrasFields;
