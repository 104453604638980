import React from 'react';
import SearchIcon from '../../common/icons/SearchIcon';

const MainAppSearch = () => {
  return (
    <div className="main-app-search">
      <label>
        <SearchIcon />
        <input type="text" name="app_search" />
      </label>
    </div>
  );
};

export default MainAppSearch;
