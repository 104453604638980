/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import UserContext from '../../contexts/user-context/UserProvider';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import FileInput from '../../common/file-input/FileInput';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import ceateNewExtraApi from '../../apis/extras-apis/createNewExtraApi';
import AllExtrasContext from '../../contexts/all-extras-context/AllExtrasContext';

const schema = Yup.object().shape({
  name: Yup.string().required('ادخل اسم الإضافة'),
  name_ar: Yup.string().required('ادخل اسم الإضافة'),
  type: Yup.string().required('إختار نوع الاضافة')
});

const TableForm = () => {
  // const [urls, setUrls] = React.useState([]);
  const { user } = useContext(UserContext);
  const { setModalOpened, setIsLoading, setFetchCount, selectedExtra } =
    useContext(AllExtrasContext);
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    register,
    unregister,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      name_ar: '',
      type: '',
      image: null
    }
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data.name) formData.append('name', data.name);
    if (data.name_ar) formData.append('name_ar', data.name_ar);
    if (data.type) formData.append('type', data.type);
    if (data?.image?.length > 0) {
      formData.append('image', data.image[0]);
    }
    try {
      setIsLoading(true);
      let res;
      if (selectedExtra) {
        formData.append('id', selectedExtra.id);
        res = await ceateNewExtraApi(user?.token, formData, true);
      } else {
        res = await ceateNewExtraApi(user?.token, formData);
      }

      if (checkRes(res)) {
        setIsLoading(false);
        setModalOpened(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تمت الاضافة بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ',
          message: res?.data?.message ? res.data.message : 'حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
      console.log(error);
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedExtra) {
      setValue('name', selectedExtra?.name_en ? selectedExtra.name_en : '');
      setValue('name_ar', selectedExtra?.name_ar ? selectedExtra.name_ar : '');
      setValue('type', selectedExtra?.type ? String(selectedExtra.type) : '');
    }
  }, [selectedExtra, selectedExtra?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="create-product-form custom-shared-form"
      form={form}
      layout="vertical"
      fields={[
        {
          name: 'extras',
          value: watch('extras')
        }
      ]}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">إســـم الإضافة بالعربية</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name_ar"
              type="text"
              placeholder="إســم الإضافة بالعربية..."
              errorMsg={errors?.name_ar?.message}
              validateStatus={errors?.name_ar ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">اسم الإضافة بالانجليزية</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder="اسم الإضافة بالانجليزية..."
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="select-label-wrap">
          <p className="label-p">نوع الإضافة</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="type"
              errorMsg={errors?.type?.message}
              validateStatus={errors?.type ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="نوع الإضافة"
              options={[
                {
                  title: 'إضافة بنص كتابى',
                  value: '1'
                },
                {
                  title: 'إضافة بالالوان',
                  value: '2'
                }
              ]}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="create-product-form"
            />
          </div>
        </div>

        <FileInput
          name="image"
          label="صورة الإضافة"
          // accept="image/png, image/jpg, image/jpeg, image/gif"
          accept="image/*"
          multiple={false}
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          // setUrls={setUrls}
          dropzoneText="اسحب صورة الإضافة وضعها هنا ..."
          className="product-images-dropzone"
          dropzoneUrls={
            selectedExtra?.image ? [{ url: selectedExtra.image }] : []
          }
          canDelete={false}
        />

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmitting}
        >
          إضافة
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
