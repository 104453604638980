/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import { Select } from "antd";
import UserContext from "../../contexts/user-context/UserProvider";
import checkRes from "../../utils/checkRes";
import SelectDropDown from "../../common/icons/SelectDropDown";
import SearchIcon from "../../common/icons/SearchIcon";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import allUserStatusArr from "../../utils/allUserStatusArr";
import getAllDelegatesApi from "../../apis/delegates/getAllDelegatesApi";
import AllDelegatesContext from "../../contexts/all-delegates-context/AllDelegatesContext";
import "./TableFilter.scss";
import routerLinks from "../../components/app/routerLinks";

const TableFilter = () => {
  const { Option } = Select;
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const history = useHistory();
  const {
    setIsLoading,
    setAllFetchedDelegates,
    setDelegatesPagination,
    allDelegatesFilter,
    setAllDelegatesFilter,
  } = useContext(AllDelegatesContext);
  // const [filterValues, setFilterValues] = React.useState({
  //   mame: '',
  //   status: ''
  // });
  async function handleChange(filterType, value) {
    // console.log(`selected ${value}`);
    setAllDelegatesFilter((prevState) => {
      if (filterType === "name") {
        return {
          ...prevState,
          name: value,
        };
      } else if (filterType === "status") {
        return {
          ...prevState,
          status: value,
        };
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await getAllDelegatesApi(user?.token, {
        ...allDelegatesFilter,
        ...values,
        page: 1,
      });

      // is response is success
      if (checkRes(res) && res.data?.data?.data?.length >= 0) {
        const data = res.data.data.data;
        history.push(`${routerLinks?.allDelegates}?page=1`);
        setAllFetchedDelegates(data);
        if (res.data.data?.pagination) {
          setDelegatesPagination(res.data.data.pagination);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  return (
    <div className="custom-table-fitler" style={{ marginTop: 42 }}>
      <div className="form-excel-export-wrap">
        {/* <div className="add-section">
          <Button
            type="primary"
            className="custom-shared-btn"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              height: 42,
              marginLeft: 18
            }}
            onClick={() => {
              setModalOpened(true);
            }}
          >
            <AddCircledIcon color="#fff" />
            إضافة مندوب جديد
          </Button>
        </div> */}
        <form className="filter-form" onSubmit={handleSubmit}>
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder="أدخل اسم المندوب للبحث"
                type="text"
                name="name"
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </label>
          </div>

          <label className="entries-wrap">
            {/* <span className="select-title">إختار الموظف</span> */}
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange("status", v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الحالات</Option>
              {allUserStatusArr?.length > 0 &&
                allUserStatusArr.map((obj, index) => (
                  <Option key={index} value={obj.id}>
                    {obj.name}
                  </Option>
                ))}
            </Select>
          </label>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button>
        </form>
      </div>
    </div>
  );
};
export default TableFilter;
