import React from 'react';

const CalendarIcon = () => {
  return (
    <svg
      id="Group_26"
      data-name="Group 26"
      xmlns="http://www.w3.org/2000/svg"
      width="15.954"
      height="16.766"
      viewBox="0 0 15.954 16.766"
    >
      <path
        id="Path_224"
        data-name="Path 224"
        d="M18.642,3.524H17.424V3.135a.635.635,0,1,0-1.271,0v.406H13.33V3.135a.635.635,0,1,0-1.271,0v.406H9.218V3.135a.635.635,0,1,0-1.271,0v.406H6.73A2.041,2.041,0,0,0,4.7,5.571V17.236a2.041,2.041,0,0,0,2.03,2.03H18.624a2.041,2.041,0,0,0,2.03-2.03V5.553A2,2,0,0,0,18.642,3.524ZM6.747,4.794H7.965V5.2a.635.635,0,0,0,1.271,0V4.794h2.841V5.2a.635.635,0,1,0,1.271,0V4.794h2.841V5.2a.635.635,0,1,0,1.271,0V4.794h1.218a.783.783,0,0,1,.777.777v1.2H5.971v-1.2A.772.772,0,0,1,6.747,4.794ZM18.642,18.013H6.747a.783.783,0,0,1-.777-.777V8.024H19.4v9.212A.757.757,0,0,1,18.642,18.013Z"
        transform="translate(-4.7 -2.5)"
      />
      <path
        id="Path_225"
        data-name="Path 225"
        d="M22.447,41H20.912a.512.512,0,0,0-.512.512v.547a.512.512,0,0,0,.512.512h1.535a.512.512,0,0,0,.512-.512v-.547A.512.512,0,0,0,22.447,41Z"
        transform="translate(-17.629 -34.205)"
      />
      <path
        id="Path_226"
        data-name="Path 226"
        d="M67.13,41H65.612a.512.512,0,0,0-.512.512v.547a.512.512,0,0,0,.512.512h1.535a.512.512,0,0,0,.512-.512v-.547A.516.516,0,0,0,67.13,41Z"
        transform="translate(-54.44 -34.205)"
      />
      <path
        id="Path_227"
        data-name="Path 227"
        d="M44.747,41H43.212a.512.512,0,0,0-.512.512v.547a.512.512,0,0,0,.512.512h1.535a.512.512,0,0,0,.512-.512v-.547A.512.512,0,0,0,44.747,41Z"
        transform="translate(-35.994 -34.205)"
      />
      <path
        id="Path_228"
        data-name="Path 228"
        d="M22.447,57.8H20.912a.512.512,0,0,0-.512.512v.547a.512.512,0,0,0,.512.512h1.535a.512.512,0,0,0,.512-.512v-.547A.512.512,0,0,0,22.447,57.8Z"
        transform="translate(-17.629 -48.041)"
      />
      <path
        id="Path_229"
        data-name="Path 229"
        d="M67.13,57.8H65.612a.512.512,0,0,0-.512.512v.547a.512.512,0,0,0,.512.512h1.535a.512.512,0,0,0,.512-.512v-.547A.516.516,0,0,0,67.13,57.8Z"
        transform="translate(-54.44 -48.041)"
      />
      <path
        id="Path_230"
        data-name="Path 230"
        d="M44.747,57.8H43.212a.512.512,0,0,0-.512.512v.547a.512.512,0,0,0,.512.512h1.535a.512.512,0,0,0,.512-.512v-.547A.512.512,0,0,0,44.747,57.8Z"
        transform="translate(-35.994 -48.041)"
      />
      <path
        id="Path_231"
        data-name="Path 231"
        d="M22.447,74.7H20.912a.512.512,0,0,0-.512.512v.547a.512.512,0,0,0,.512.512h1.535a.512.512,0,0,0,.512-.512v-.547A.512.512,0,0,0,22.447,74.7Z"
        transform="translate(-17.629 -61.958)"
      />
      <path
        id="Path_232"
        data-name="Path 232"
        d="M67.13,74.7H65.612a.512.512,0,0,0-.512.512v.547a.512.512,0,0,0,.512.512h1.535a.512.512,0,0,0,.512-.512v-.547A.516.516,0,0,0,67.13,74.7Z"
        transform="translate(-54.44 -61.958)"
      />
      <path
        id="Path_233"
        data-name="Path 233"
        d="M44.747,74.7H43.212a.512.512,0,0,0-.512.512v.547a.512.512,0,0,0,.512.512h1.535a.512.512,0,0,0,.512-.512v-.547A.512.512,0,0,0,44.747,74.7Z"
        transform="translate(-35.994 -61.958)"
      />
    </svg>
  );
};

export default CalendarIcon;
