/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { notification } from 'antd';
// import successNotification from '../../utils/successNotification';
// import { store } from 'react-notifications-component';

const duration = 4;
const ForegroundNotifications = ({
  fcmNotificationPayload,
  fcmNotificationFetchCount,
  setFcmNotificationFetchCount,
  setFcmNotificationPayload
}) => {
  useEffect(() => {
    if (fcmNotificationFetchCount > 0 && fcmNotificationPayload) {
      console.log('count');
      notification.success({
        message: fcmNotificationPayload?.notification?.title,
        description: fcmNotificationPayload?.notification?.body,
        duration,
        onClick: () => {
          console.log('Notification Clicked!');
        }
      });
      // successNotification({
      //   id: 'fcm-notification',
      //   title: fcmNotificationPayload?.notification?.title,
      //   message: fcmNotificationPayload?.notification?.body,
      //   duration: 1000,
      //   onClick: () => {
      //     console.log('Notification Clicked!');
      //   }
      // });
    }

    setTimeout(() => {
      // store.removeNotification('fcm-notification');
      setFcmNotificationFetchCount(0);
      setFcmNotificationPayload(null);
    }, duration * 1000);
  }, [fcmNotificationFetchCount, fcmNotificationPayload]);
  return null;
};

export default ForegroundNotifications;
