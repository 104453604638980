import CategoriesIcon from "../../common/icons/CategoriesIcon";
import ClientsIcon from "../../common/icons/ClientsIcon";
import DashboardIcon from "../../common/icons/DashboardIcon";
import DelegatesIcon from "../../common/icons/DelegatesIcon";
import FamliesIcon from "../../common/icons/FamliesIcon";
import MessagesIcon from "../../common/icons/MessagesIcon";
import OrdersIcon from "../../common/icons/OrdersIcon";
import ProductsIcon from "../../common/icons/ProductsIcon";
import StoreIcon from "../../common/icons/StoreIcon";
// import TransferIcon from '../../common/icons/TransferIcon';
// import HrIcon from '../../common/icons/HrIcon';
import routerLinks from "../app/routerLinks";
// import ProjectsIcons from '../../common/icons/ProjectsIcons';
// import CRMIcon from '../../common/icons/CRMIcon';
// import WarehouseIcon from '../../common/icons/WarehouseIcon';
export const adminMainAppBarLinks = [
  {
    id: 1,
    to: routerLinks.homePage,
    name: "لوحة التحــكم",
    isLink: true,
    icon: (color) =>
      color ? <DashboardIcon color={color} /> : <DashboardIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 2,
    to: routerLinks.orders,
    name: "الطلبات",
    isLink: true,
    icon: (color) => (color ? <OrdersIcon color={color} /> : <OrdersIcon />),
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 2001,
    to: routerLinks.allCategories,
    name: "جميع الفئات",
    isLink: true,
    icon: (color) =>
      color ? <CategoriesIcon color={color} /> : <CategoriesIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 20001,
    to: routerLinks.userCategories,
    name: "فئات المتجر",
    isLink: true,
    icon: (color) =>
      color ? <CategoriesIcon color={color} /> : <CategoriesIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 30001,
    to: routerLinks.allExtras,
    name: "إضافات المنتج",
    isLink: true,
    icon: (color) =>
      color ? <CategoriesIcon color={color} /> : <CategoriesIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 2002,
    to: routerLinks.products,
    name: "المنتجات",
    isLink: true,
    icon: (color) =>
      color ? <ProductsIcon color={color} /> : <ProductsIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 4,
    to: routerLinks.delegates,
    name: "مندوبين المتجر",
    isLink: true,
    icon: (color) =>
      color ? <DelegatesIcon color={color} /> : <DelegatesIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 41,
    to: routerLinks.allDelegates,
    name: "جميع المندوبين",
    isLink: true,
    icon: (color) =>
      color ? <DelegatesIcon color={color} /> : <DelegatesIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 5,
    to: routerLinks.sellers,
    name: "المتاجر",
    isLink: true,
    icon: (color) => (color ? <StoreIcon color={color} /> : <StoreIcon />),
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 6,
    to: routerLinks.families,
    name: "الأســر المنتجة",
    isLink: true,
    icon: (color) => (color ? <FamliesIcon color={color} /> : <FamliesIcon />),
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 7,
    to: routerLinks.userClients,
    name: "عملاء المتجر",
    isLink: true,
    icon: (color) => (color ? <ClientsIcon color={color} /> : <ClientsIcon />),
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 71,
    to: routerLinks.allClients,
    name: "جميع العملاء",
    isLink: true,
    icon: (color) => (color ? <ClientsIcon color={color} /> : <ClientsIcon />),
    subMenu: [],
    visibleSubMenu: false,
  },
  // {
  //   id: 3221,
  //   to: routerLinks.messages,
  //   name: "الرســائل",
  //   isLink: true,
  //   icon: (color) =>
  //     color ? <MessagesIcon color={color} /> : <MessagesIcon />,
  //   subMenu: [],
  //   visibleSubMenu: false,
  // },
  // {
  //   id: 6,
  //   to: routerLinks.storePage,
  //   name: 'المخزون',
  //   icon: (color) => (color ? <StoreIcon color={color} /> : <StoreIcon />),
  //   visibleSubMenu: false,
  //   subMenu: [
  //     {
  //       id: 1,
  //       to: routerLinks.storeItemManagerPage,
  //       name: 'المنتجات'
  //     },
  //     {
  //       id: 2,
  //       to: routerLinks.storeProductsIngredientsPage,
  //       name: 'مكونات المنتجات'
  //     },
  //     {
  //       id: 4,
  //       to: routerLinks.storeConversionPage,
  //       name: 'تحويل مخزون'
  //     },
  //     {
  //       id: 5,
  //       to: routerLinks.storeInventory,
  //       name: 'جرد المخزون'
  //     },
  //     {
  //       id: 6,
  //       to: routerLinks.purchaseOrderPage,
  //       name: 'امر شراء'
  //     },

  //     {
  //       id: 8,
  //       to: routerLinks.suppliersPage,
  //       name: 'التجـــار'
  //     }

  //   ]
  // },
  // {
  //   id: 4,
  //   to: routerLinks.salesPage,
  //   name: 'المبيعات',
  //   icon: (color) =>
  //     color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
  //   visibleSubMenu: false,
  //   subMenu: [
  //     {
  //       id: 87287,
  //       name: 'العملاء',
  //       to: routerLinks.clienstPage
  //     },
  //     {
  //       id: 8720003487,
  //       name: 'الشيفتات',
  //       to: routerLinks.shiftsPage
  //     }
  //   ]
  // },
];
export const sellerMainAppBarLinks = [
  {
    id: 1,
    to: routerLinks.homePage,
    name: "لوحة التحــكم",
    isLink: true,
    icon: (color) =>
      color ? <DashboardIcon color={color} /> : <DashboardIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 2,
    to: routerLinks.orders,
    name: "الطلبات",
    isLink: true,
    icon: (color) => (color ? <OrdersIcon color={color} /> : <OrdersIcon />),
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 20001,
    to: routerLinks.userCategories,
    name: "فئات المتجر",
    isLink: true,
    icon: (color) =>
      color ? <CategoriesIcon color={color} /> : <CategoriesIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 2002,
    to: routerLinks.products,
    name: "المنتجات",
    isLink: true,
    icon: (color) =>
      color ? <ProductsIcon color={color} /> : <ProductsIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 4,
    to: routerLinks.delegates,
    name: "مندوبين المتجر",
    isLink: true,
    icon: (color) =>
      color ? <DelegatesIcon color={color} /> : <DelegatesIcon />,
    subMenu: [],
    visibleSubMenu: false,
  },
  {
    id: 7,
    to: routerLinks.userClients,
    name: "عملاء المتجر",
    isLink: true,
    icon: (color) => (color ? <ClientsIcon color={color} /> : <ClientsIcon />),
    subMenu: [],
    visibleSubMenu: false,
  },
  // {
  //   id: 3221,
  //   to: routerLinks.messages,
  //   name: "الرســائل",
  //   isLink: true,
  //   icon: (color) =>
  //     color ? <MessagesIcon color={color} /> : <MessagesIcon />,
  //   subMenu: [],
  //   visibleSubMenu: false,
  // },
];
