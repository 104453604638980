import React, { useContext } from 'react';
import { Modal, Rate } from 'antd';
import AllDelegatesContext from '../../contexts/all-delegates-context/AllDelegatesContext';
import './DelegateDetailsModal.scss';

const AllDelegatesDetailsModal = () => {
  const {
    detailsModalOpened,
    setDetailsModalOpened,
    setSelectedDelegate,
    selectedDelegate
  } = useContext(AllDelegatesContext);

  const renderDelegateMainInfo = () => {
    return (
      <div className="delegate-main-info">
        {selectedDelegate?.name && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الإســم بالكــامل</div>
            <div className="info-value">{selectedDelegate.name}</div>
          </div>
        )}
        {selectedDelegate.phone && (
          <div className="custom-render-info-wrap">
            <div className="info-label">رقــم الهاتف</div>
            <div className="info-value">{selectedDelegate.phone}</div>
          </div>
        )}
        {selectedDelegate.information?.nationality && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الجنســـة</div>
            <div className="info-value">
              {selectedDelegate.information?.nationality}
            </div>
          </div>
        )}
        {selectedDelegate?.address && (
          <div className="custom-render-info-wrap">
            <div className="info-label">العنــوان</div>
            <div className="info-value">{selectedDelegate.address}</div>
          </div>
        )}
        {selectedDelegate?.information?.identity_number && (
          <div className="custom-render-info-wrap">
            <div className="info-label">رقم الهوية</div>
            <div className="info-value">
              {selectedDelegate.information.identity_number}
            </div>
          </div>
        )}
        {selectedDelegate?.email && (
          <div className="custom-render-info-wrap">
            <div className="info-label">البريد الاكترونى</div>
            <div className="info-value">{selectedDelegate.email}</div>
          </div>
        )}

        <div
          style={{
            display: 'grid',
            justifyContent: 'center',
            gridColumn: '1 / -1'
          }}
        >
          <span className="name-it-self">
            <Rate
              allowHalf
              disabled
              style={{ fontSize: 32 }}
              defaultValue={
                selectedDelegate.rate ? parseFloat(selectedDelegate.rate) : 0
              }
            />
          </span>
        </div>
      </div>
    );
  };

  const renderDelegateImages = () => {
    return (
      <div className="delegate-images">
        <ul className="images-ul">
          {selectedDelegate?.information?.driving_image && (
            <li>
              <div className="img-label">صورة القيادة</div>
              <div className="img-wrap">
                <img src={selectedDelegate.information.driving_image} alt="" />
              </div>
            </li>
          )}
          {/* {selectedDelegate?.information?.vehicle_image && (
            <li>
              <div className="img-label">صورة المركبة</div>
              <div className="img-wrap">
                <img src={selectedDelegate.information.vehicle_image} alt="" />
              </div>
            </li>
          )} */}
          {selectedDelegate?.information?.vehicle_tablet_image && (
            <li>
              <div className="img-label">لوحة المركبة</div>
              <div className="img-wrap">
                <img
                  src={selectedDelegate.information.vehicle_tablet_image}
                  alt=""
                />
              </div>
            </li>
          )}
          {selectedDelegate?.information?.vehicle_registration && (
            <li>
              <div className="img-label">إستمارة المركبة</div>
              <div className="img-wrap">
                <img
                  src={selectedDelegate?.information?.vehicle_registration}
                  alt=""
                />
              </div>
            </li>
          )}
        </ul>

        {selectedDelegate?.vehicle_images?.length > 0 && (
          <div>
            <div className="img-label">صور المركبة</div>
            <ul className="images-ul">
              {selectedDelegate.vehicle_images.map((img) => (
                <li key={img?.id}>
                  <div className="img-wrap">
                    <img src={img?.image} alt="" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      transitionName=""
      className="delegate-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">تفاصيل المندوب</div>}
      visible={detailsModalOpened}
      onOk={() => {
        setDetailsModalOpened(false);
        setSelectedDelegate(null);
      }}
      onCancel={() => {
        setDetailsModalOpened(false);
        setSelectedDelegate(null);
      }}
      footer={false}
    >
      {renderDelegateMainInfo()}

      {renderDelegateImages()}
    </Modal>
  );
};

export default AllDelegatesDetailsModal;
