import React from 'react';

const DelegatesIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.126"
      height="24.084"
      viewBox="0 0 24.126 24.084"
    >
      <g
        id="fast-delivery_2_"
        data-name="fast-delivery (2)"
        transform="translate(0.266 -0.068)"
      >
        <path
          id="Path_44"
          data-name="Path 44"
          d="M19,9.514a4.612,4.612,0,1,0-4.589-5.073H3.227a.461.461,0,0,0-.461.461V18.127A6.622,6.622,0,0,0,1.4,19.912L.167,22.082a1.275,1.275,0,0,0,0,1.261,1.121,1.121,0,0,0,.974.56H5.449a1.306,1.306,0,0,0,1.372-.719l.343-.546,2.431.116a8.061,8.061,0,0,0,4.157-.661c1.845-.68,4.852-1.967,6.5-2.595l0,0a1.37,1.37,0,0,0,.9-1.689,1.411,1.411,0,0,0-1.77-.918l-.008,0-.935.226V9.481A4.637,4.637,0,0,0,19,9.514Zm0-8.3A3.689,3.689,0,1,1,15.309,4.9,3.693,3.693,0,0,1,19,1.213ZM8.76,5.364H12.45V9.6l-1.6-1.008a.461.461,0,0,0-.492,0L8.76,9.6ZM19.592,17.787c.353-.077.607.026.68.276a.457.457,0,0,1-.329.564c-1.636.621-4.675,1.923-6.515,2.6a7.2,7.2,0,0,1-3.789.6l-2.7-.129a.461.461,0,0,0-.412.215l-.487.773a.939.939,0,0,1-.193.249,1.352,1.352,0,0,1-.4.039H1.14a.2.2,0,0,1-.179-.1.344.344,0,0,1,.01-.342L2.2,20.363l0-.007c1.663-2.993,3.917-2.8,6.453-2.407a8.756,8.756,0,0,0,1.961.158c.432-.04,3.144-.348,3.594-.39a.366.366,0,0,1,.27.087.422.422,0,0,1,.149.29c.006.073.023.266-.494.49a20.8,20.8,0,0,1-3.311,1.132.461.461,0,1,0,.211.9A21.764,21.764,0,0,0,14.5,19.431a1.919,1.919,0,0,0,.83-.615Zm-2.069-.449-2,.484A1.324,1.324,0,0,0,14.126,16.8c-.227.021-.994.106-1.806.2-.772.085-1.569.174-1.788.194a8.234,8.234,0,0,1-1.739-.152,10.086,10.086,0,0,0-3.76-.073,5.082,5.082,0,0,0-1.346.506V5.364h4.15v5.073a.461.461,0,0,0,.707.39l2.06-1.3,2.06,1.3a.461.461,0,0,0,.707-.39V5.364H14.41a4.624,4.624,0,0,0,3.113,3.908v8.067Z"
          transform="translate(0 0)"
          fill={color ? color : '#5f758e'}
          stroke={color ? color : '#5f758e'}
          strokeWidth="0.5"
        />
        <path
          id="Path_45"
          data-name="Path 45"
          d="M100.5,279.427h1.614a.461.461,0,0,0,0-.922H100.5A.461.461,0,0,0,100.5,279.427Z"
          transform="translate(-95.425 -265.355)"
          fill={color ? color : '#5f758e'}
          stroke={color ? color : '#5f758e'}
          strokeWidth="0.5"
        />
        <path
          id="Path_46"
          data-name="Path 46"
          d="M103.724,318.505H100.5a.461.461,0,0,0,0,.922h3.228A.461.461,0,0,0,103.724,318.505Z"
          transform="translate(-95.424 -303.51)"
          fill={color ? color : '#5f758e'}
          stroke={color ? color : '#5f758e'}
          strokeWidth="0.5"
        />
        <path
          id="Path_47"
          data-name="Path 47"
          d="M375.677,44.238h1.237a.461.461,0,0,0,.461-.461V41.533a.461.461,0,0,0-.922,0v1.782h-.776A.461.461,0,0,0,375.677,44.238Z"
          transform="translate(-357.916 -38.874)"
          fill={color ? color : '#5f758e'}
          stroke={color ? color : '#5f758e'}
          strokeWidth="0.5"
        />
        <path
          id="Path_48"
          data-name="Path 48"
          d="M462.493,90.574a.461.461,0,0,0,0-.922h0A.461.461,0,0,0,462.493,90.574Z"
          transform="translate(-440.728 -85.211)"
          fill={color ? color : '#5f758e'}
          stroke={color ? color : '#5f758e'}
          strokeWidth="0.5"
        />
        <path
          id="Path_49"
          data-name="Path 49"
          d="M342.958,90.113a.461.461,0,1,0-.461.461h0A.461.461,0,0,0,342.958,90.113Z"
          transform="translate(-326.266 -85.211)"
          fill={color ? color : '#5f758e'}
          stroke={color ? color : '#5f758e'}
          strokeWidth="0.5"
        />
        <path
          id="Path_50"
          data-name="Path 50"
          d="M402.436,149.648a.461.461,0,1,0,.461.461h0A.461.461,0,0,0,402.436,149.648Z"
          transform="translate(-383.438 -142.44)"
          fill={color ? color : '#5f758e'}
          stroke={color ? color : '#5f758e'}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};

export default DelegatesIcon;
