import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  detailsModalOpened: false,
  setDetailsModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedDelegates: null,
  setAllFetchedDelegates: (v) => {},
  delegatesPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setDelegatesPagination: (v) => {},
  selectedDelegate: null,
  setSelectedDelegate: (v) => {},
  // filter
  userDelegatesFilter: {
    page: 1,
    mame: '',
    status: ''
  },
  setUserDelegatesFilter: (v) => {}
};

const UserDelegatesContext = createContext(INITIAL_VALUES);

export const UserDelegatesProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedDelegates, setAllFetchedDelegates] = useState(
    INITIAL_VALUES.allFetchedDelegates
  );
  const [selectedDelegate, setSelectedDelegate] = useState(
    INITIAL_VALUES.selectedDelegate
  );
  const [delegatesPagination, setDelegatesPagination] = useState(
    INITIAL_VALUES.delegatesPagination
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES.detailsModalOpened
  );
  //
  const [userDelegatesFilter, setUserDelegatesFilter] = useState(
    INITIAL_VALUES.userDelegatesFilter
  );

  return (
    <UserDelegatesContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        detailsModalOpened,
        setDetailsModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedDelegates,
        setAllFetchedDelegates,
        selectedDelegate,
        setSelectedDelegate,
        delegatesPagination,
        setDelegatesPagination,
        userDelegatesFilter,
        setUserDelegatesFilter
      }}
    >
      {children}
    </UserDelegatesContext.Provider>
  );
};

export default UserDelegatesContext;
