/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Button, Modal } from 'antd';
import ProductsContext from '../../contexts/products-context/ProductsContext';
import deleteProductExtraColorApi from '../../apis/products/deleteProductExtraColorApi';
import UserContext from '../../contexts/user-context/UserProvider';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';
import checkRes from '../../utils/checkRes';

const ProductColorPickerModal = ({
  removeColorsField,
  nestedIndex,
  watch,
  extrasFields,
  colorsFields,
  children,
  setValue
}) => {
  const {
    colorPickerModalOpened,
    setColorPickerModalOpened,
    selectedColorPicker,
    setSelectedColorPicker,
    setIsLoading,
    setFetchCount,
    selectedProduct,
    isLoading
  } = useContext(ProductsContext);
  const { user } = useContext(UserContext);
  const handleDeleteExtraColor = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteProductExtraColorApi(user?.token, { id });
      if (checkRes(res)) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف اللون بنجاح'
        });
        setColorPickerModalOpened(false);
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };

  return (
    <Modal
      transitionName=""
      className="product-color-picker-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <div className="modal-title">
          {selectedColorPicker ? 'تعديل اللون' : 'إضافة لون جديد'}
        </div>
      }
      visible={colorPickerModalOpened}
      onOk={() => {
        setColorPickerModalOpened(false);
        setSelectedColorPicker(null);
      }}
      onCancel={() => {
        setColorPickerModalOpened(false);
        // if (!watch(`extras.${nestedIndex}.colors.${colorIndex}.color`))
        //   removeColorsField(colorIndex);
        setSelectedColorPicker(null);
      }}
      footer={false}
    >
      {children}
      <div
        className="btns-wrap"
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 18
        }}
      >
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          onClick={() => {
            setColorPickerModalOpened(false);
            setSelectedColorPicker(null);
          }}
        >
          حفظ
        </Button>

        <Button
          className="submit-btn del-btn"
          htmlType="submit"
          type="primary"
          loading={isLoading}
          onClick={() => {
            if (
              !selectedProduct ||
              (selectedProduct && selectedColorPicker?.is_new_color)
            ) {
              const foundExtraIndex = extrasFields.findIndex(
                (ex) =>
                  ex.extra_custom_id == selectedColorPicker?.extra_custom_id
              );
              if (foundExtraIndex >= 0) {
                const foundColorIndex =
                  watch(`extras.${foundExtraIndex}`).colors?.length > 0 &&
                  watch(`extras.${foundExtraIndex}`).colors.findIndex(
                    (c) =>
                      c.color_custom_id == selectedColorPicker?.color_custom_id
                  );
                if (foundColorIndex >= 0) {
                  // removeColorsField(foundColorIndex);
                  setValue(
                    `extras.${foundExtraIndex}.colors`,
                    watch(`extras.${foundExtraIndex}.colors`).filter(
                      (c) =>
                        c.color_custom_id != selectedColorPicker.color_custom_id
                    )
                  );
                }
              }
              setColorPickerModalOpened(false);
              setSelectedColorPicker(null);
            } else {
              handleDeleteExtraColor(selectedColorPicker?.id);
            }
          }}
        >
          حذف
        </Button>
      </div>
    </Modal>
  );
};

export default ProductColorPickerModal;
