/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button, Modal, Pagination, Spin } from 'antd';
import OrdersContext from '../../contexts/orders-context/OrdersContext';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import SearchIcon from '../../common/icons/SearchIcon';
import UserDelegatesContext from '../../contexts/user-delegates-context/UserDelegatesContext';
import debounce from 'lodash.debounce';
import assignOrderToDelegateApi from '../../apis/orders/assignOrderToDelegateApi';
import UserContext from '../../contexts/user-context/UserProvider';
import checkRes from '../../utils/checkRes';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';
import getAllUserDelegatesApi from '../../apis/delegates/getAllUserDelegatesApi';
import './OrderDelegatesModal.scss';
import assignOrderToFreelanceDelegateApi from '../../apis/orders/assignOrderToFreelanceDelegateApi';

const OrderDelegatesModal = () => {
  const { user } = useContext(UserContext);
  const {
    setFetchCount: setFetchOrderCount,
    delegatesModalOpened,
    setDelegatesModalOpened,
    selectedOrder
  } = useContext(OrdersContext);

  const {
    allFetchedDelegates,
    isLoading,
    setIsLoading,
    selectedDelegate,
    setSelectedDelegate,
    setAllFetchedDelegates,
    setDelegatesPagination,
    delegatesPagination,
    fetchCount,
    setFetchCount
  } = useContext(UserDelegatesContext);

  const [query, setQuery] = useState('');
  const [uesrDelegatesPage, setUserDelegatesPage] = useState(1);
  const changeHandler = (event) => {
    setQuery(event.target.value);
  };

  // const debouncedCallback = debounce(callback, waitTime);
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 400), []);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getAllUserDelegatesApi(user?.token, {
          name: query,
          page: query ? 1 : uesrDelegatesPage
        });
        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedDelegates(data);
            if (res.data.data?.pagination) {
              setDelegatesPagination(res.data.data.pagination);
            }

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [query, fetchCount]);

  const [isLoadingAssignOrder, setIsLoadingAssignOrder] = useState(false);
  const handleAssignOrder = async (delegate, order) => {
    setSelectedDelegate(delegate);
    try {
      setIsLoadingAssignOrder(true);
      const res = await assignOrderToDelegateApi(
        {
          order_id: order?.id,
          user_id: delegate?.id
        },
        user?.token
      );
      if (checkRes(res)) {
        setIsLoadingAssignOrder(false);
        setDelegatesModalOpened(false);
        setSelectedDelegate(null);
        setFetchOrderCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تمت إرساال الطلب بنجاح'
        });
      } else {
        setIsLoadingAssignOrder(false);
        setSelectedDelegate(null);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoadingAssignOrder(false);
      setSelectedDelegate(null);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };

  const renderDelegatesList = (delegates) => {
    return delegates.map((delegate) => (
      <li key={delegate.id} className="delegates-li">
        <div className="img-name-wrap">
          <div className="delegate-img">
            <img
              src={delegate.image ? delegate.image : ''}
              alt={delegate.name}
            />
          </div>
          <div className="delegate-name">{delegate.name}</div>
        </div>
        {(selectedOrder.status == 2 || selectedOrder.status == 11) &&
        selectedOrder.is_assign == 1 &&
        delegate?.status === 1 ? (
          <Button
            type="primary"
            className="custom-shared-btn"
            onClick={() => handleAssignOrder(delegate, selectedOrder)}
            loading={
              isLoadingAssignOrder && selectedDelegate?.id === delegate.id
            }
          >
            إرسـال الطلب
          </Button>
        ) : (
          <Button
            // type="primary"
            style={{
              backgroundColor: '#eee',
              cursor: 'default',
              pointerEvents: 'none'
            }}
            className="custom-shared-btn"
          >
            المندوب غير مفعل
          </Button>
        )}
        {selectedOrder.status == 4 && (
          <div className="custom-shared-btn">تم الارســال</div>
        )}
      </li>
    ));
  };

  const handleAutomaticAssignOrder = async (order) => {
    try {
      setIsLoadingAssignOrder(true);
      const res = await assignOrderToFreelanceDelegateApi(
        order?.id,
        user?.token
      );
      if (checkRes(res)) {
        setIsLoadingAssignOrder(false);
        setDelegatesModalOpened(false);
        setFetchOrderCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تمت إرساال الطلب بنجاح'
        });
      } else {
        setIsLoadingAssignOrder(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoadingAssignOrder(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };

  return (
    <Modal
      transitionName=""
      className="shared-custom-modal order-delegates-modal"
      width="90%"
      style={{ maxWidth: '542px' }}
      title={<div className="modal-title">إقتراح للمناديب المتاحين لديك</div>}
      visible={delegatesModalOpened}
      onOk={() => {
        setDelegatesModalOpened(false);
        // setSelectedOrder(null);
      }}
      onCancel={() => {
        setDelegatesModalOpened(false);
        // setSelectedOrder(null);
      }}
      footer={false}
    >
      <form className="filter-form">
        <div className="table-search">
          <label>
            {isLoading ? <Spin size="small" /> : <SearchIcon />}
            <input
              placeholder="إبحث عن المندوب ...."
              type="text"
              name="table_search"
              onChange={debouncedChangeHandler}
              // onKeyUp={handleTableSearch}
            />
          </label>
        </div>
      </form>

      {allFetchedDelegates?.length > 0 && (
        <ul className="delegates-ul">
          {renderDelegatesList(allFetchedDelegates)}
        </ul>
      )}
      {(!allFetchedDelegates || allFetchedDelegates?.length === 0) && (
        <p style={{ textAlign: 'center', fontSize: '18px', color: '#ae3a7d' }}>
          لا يوجد مندوبين
        </p>
      )}

      <Pagination
        style={{ marginTop: 22 }}
        showSizeChanger={false}
        defaultCurrent={1}
        current={delegatesPagination.current_page}
        pageSize={delegatesPagination.per_page}
        total={delegatesPagination.total}
        // itemRender={itemRender}
        onChange={(page, pageSize) => {
          setFetchCount((prev) => prev + 1);
          setUserDelegatesPage(page);
          // history.push(`${routerLinks.orders}?page=${page}`);
        }}
        hideOnSinglePage={true}
      />

      {selectedOrder?.status != 11 && (
        <div className="automatic-assign-order-btn-wrap">
          <Button
            type="primary"
            className="custom-shared-btn"
            onClick={() => handleAutomaticAssignOrder(selectedOrder)}
            loading={isLoadingAssignOrder && !selectedDelegate}
          >
            مندوب خارجى
          </Button>
        </div>
      )}

      {isLoadingAssignOrder && !selectedDelegate && <LoadingModal />}
    </Modal>
  );
};

export default OrderDelegatesModal;
