import React from 'react';

const CategoriesIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.861"
      height="22.259"
      viewBox="0 0 23.861 22.259"
    >
      <path
        d="M22.011,28.69V17.824a.7.7,0,0,0-.7-.7H2.5a.7.7,0,0,0-.7.7V29.556A2.107,2.107,0,0,0,.47,32.977L3.632,36.81c1.957,2.29,4.036,2.571,7.127,2.571a23.016,23.016,0,0,0,6.149-.515l2.251-.538a1.964,1.964,0,0,0,1.6.84h1.109a2.056,2.056,0,0,0,2-2.1V30.788A2.065,2.065,0,0,0,22.011,28.69Zm-3.155,1.444-.935-.475a5.561,5.561,0,0,0-4.878-.079,6.706,6.706,0,0,1-1.838.711H7.96a1.954,1.954,0,0,0-1.952,1.952v.512l-.018-.019L3.672,30.221a2.138,2.138,0,0,0-.467-.382V22.875H8.949v2.573a.7.7,0,0,0,.7.7H14.08a.7.7,0,0,0,.7-.7V22.875h5.825V28.69a2.024,2.024,0,0,0-1.751,1.444Zm-8.5-7.258h3.025v1.871H10.353Zm10.254-1.4H14.782V18.526h5.825v2.945Zm-7.229-2.945v2.945H10.353V18.526Zm-4.429,0v2.945H3.2V18.526ZM16.582,37.5a22.331,22.331,0,0,1-5.8.469c-2.9,0-4.3-.047-6.069-2.053L1.553,32.084a.71.71,0,0,1,1.086-.912l2.318,2.516A3.187,3.187,0,0,0,7.29,34.71h6.425a.7.7,0,1,0,0-1.4h-6.3V32.243a.549.549,0,0,1,.548-.548H11.2a6.425,6.425,0,0,0,2.43-.842,4.164,4.164,0,0,1,3.652.058l1.47.747V36.98Zm5.875-.438a.655.655,0,0,1-.594.7H20.754a.655.655,0,0,1-.594-.7V30.788a.655.655,0,0,1,.594-.7h1.109a.655.655,0,0,1,.594.7Z"
        transform="translate(0.001 -17.122)"
        fill={color ? color : '#5f758e'}
      />
    </svg>
  );
};

export default CategoriesIcon;
