/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import UserContext from "../../contexts/user-context/UserProvider";
import checkRes from "../../utils/checkRes";
import SearchIcon from "../../common/icons/SearchIcon";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import AllClientsContext from "../../contexts/all-clients-context/AllClientsContext";
import getAllClientsApi from "../../apis/clients-apis/getAllClientsApi";
import "./TableFilter.scss";
import routerLinks from "../../components/app/routerLinks";

const TableFilter = () => {
  // const { Option } = Select;
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    setIsLoading,
    setAllFetchedClients,
    setAllClientsPagination,
    setAllClientsFilter,
    allClientsFilter,
  } = useContext(AllClientsContext);
  // const [filterValues, setFilterValues] = React.useState({
  //   // status: '',
  //   mame: ''
  // });
  async function handleChange(filterType, value) {
    // console.log(`selected ${value}`);
    setAllClientsFilter((prevState) => {
      if (filterType === "name") {
        return {
          ...prevState,
          name: value,
        };
      } else if (filterType === "status") {
        return {
          ...prevState,
          status: value,
        };
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await getAllClientsApi(user?.token, {
        ...allClientsFilter,
        ...values,
        page: 1,
      });

      // is response is success
      if (checkRes(res) && res.data?.data?.data?.length >= 0) {
        const data = res.data.data.data;
        history.push(`${routerLinks.allClients}?page=1`);
        setAllFetchedClients(data);
        if (res.data.data?.pagination) {
          setAllClientsPagination(res.data.data.pagination);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  return (
    <div className="custom-table-fitler" style={{ marginTop: 42 }}>
      <div className="form-excel-export-wrap">
        <form
          className="filter-form"
          onSubmit={handleSubmit}
          style={{
            gap: 10,
          }}
        >
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder="أدخل اسم العميل للبحث"
                type="text"
                name="table_search"
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </label>
          </div>
          {/* <label className="entries-wrap">
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange('status', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الحالات</Option>
              {allUserStatusArr?.length > 0 &&
                allUserStatusArr.map((obj, index) => (
                  <Option key={index} value={obj.id}>
                    {obj.name}
                  </Option>
                ))}
            </Select>
          </label> */}

          <button className="filter-submit-btn" type="submit">
            بحث
          </button>
        </form>
      </div>
    </div>
  );
};
export default TableFilter;
