import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAx74uvW98y5HuzluWZeViE7RTfNkAJVbY',
  authDomain: 'floweration-fde17.firebaseapp.com',
  projectId: 'floweration-fde17',
  storageBucket: 'floweration-fde17.appspot.com',
  messagingSenderId: '655759057212',
  appId: '1:655759057212:web:0e4653729376eff097fe39',
  measurementId: 'G-552DRH8SWB'
};

firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getCurrentToken = async (setIsTokenFound) => {
  let currentToken = '';
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setIsTokenFound(true);
      console.log('current token : ', currentToken);
    } else {
      setIsTokenFound(false);
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};

// foreground FCM
export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
