const allUserStatusArr = [
  {
    id: 1,
    name: 'مفعل'
  },
  {
    id: 2,
    name: 'قيد الانتظار'
  },
  {
    id: 3,
    name: 'غير مفعل'
  }
];

export default allUserStatusArr;
