import React, { useEffect, useState } from "react";
import SearchNotificationsProfile from "../../components/search-notifications-profile/SearchNotificationsProfile";
import ProfilePageForm from "./ProfilePageForm";
import "./ProfilePage.scss";

const ProfilePage = () => {
  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
    document.title = "معلومات المستخدم";
  }, [isMount]);
  return (
    <div className="shared-custom-page profile-page">
      <div className="page-head-wrap">
        <div className="page-title">
          <h2 className="bold-font">بروفايل المستخدم</h2>
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="img-form-wrap">
        <ProfilePageForm />
      </div>
    </div>
  );
};

export default ProfilePage;
