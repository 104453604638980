import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu, Button, Avatar } from "antd";
import { Link as RouterLink } from "react-router-dom";
import {
  UserOutlined,
  LogoutOutlined,
  LoadingOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import routerLinks from "../app/routerLinks";
import UserContext from "../../contexts/user-context/UserProvider";
import signoutApi from "../../apis/auth/signout";
import ChangePasswordContext from "../../contexts/change-password-context/ChangePasswordContext";
import ChangePasswordModal from "./ChangePasswordModal";
import successNotification from "../../utils/successNotification";
import errorNotification from "../../utils/errorNotification";
import checkRes from "../../utils/checkRes";

const MainAppProfileMenu = () => {
  const { user, removeCurrentUser } = useContext(UserContext);
  const { changePasswordModalOpened, setChangePasswordModalOpened } =
    useContext(ChangePasswordContext);
  const [loadingSignout, setLoadingSignout] = useState(false);
  const history = useHistory();
  const handleSignout = async () => {
    try {
      setLoadingSignout(true);
      let res;
      res = await signoutApi(user?.token);
      if (checkRes(res)) {
        setLoadingSignout(false);
        removeCurrentUser();
        history.push(routerLinks.signinPage);

        successNotification({
          title: "العملية تمت بنجاح",
          message: "تم تسجيل الخروج بنجاح",
        });
      } else {
        setLoadingSignout(false);
        errorNotification({
          title: "حدث خطأ اثناء تسجيل الخروج",
          message: "من فضلك حاول فى وقت لاحق",
        });
      }
    } catch (error) {
      setLoadingSignout(false);
      errorNotification({
        title: "حدث خطأ اثناء تسجيل الخروج",
        message: "من فضلك حاول فى وقت لاحق",
      });
      console.log(error);
    }
  };

  return (
    <div className="avatar-wrapper">
      <Dropdown
        arrow
        trigger={["click"]}
        // disabled={loadingSignout}
        overlay={
          <Menu>
            <Menu.Item key="1" icon={<UserOutlined />}>
              <RouterLink to={routerLinks.ProfilePage}>
                الملــف الشخصى
              </RouterLink>
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<UserOutlined />}
              onClick={() => setChangePasswordModalOpened(true)}
            >
              تغيير كلمة السر
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<LogoutOutlined />}
              onClick={handleSignout}
            >
              تسجيل الخروج
            </Menu.Item>
          </Menu>
        }
      >
        <Button className="profile-menu-btn" type="text">
          <Avatar size={38} icon={<UserOutlined />} src={user?.image} />
          {loadingSignout ? <LoadingOutlined /> : <CaretDownOutlined />}
        </Button>
      </Dropdown>

      {changePasswordModalOpened && <ChangePasswordModal />}
    </div>
  );
};

export default MainAppProfileMenu;
