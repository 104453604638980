import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { Modal } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ChangePasswordContext from '../../contexts/change-password-context/ChangePasswordContext';
import errorNotification from '../../utils/errorNotification';
import changePasswordApi from '../../apis/auth/changePasswordApi';
import UserContext from '../../contexts/user-context/UserProvider';
import checkRes from '../../utils/checkRes';
import successNotification from '../../utils/successNotification';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const schema = Yup.object().shape({
  old_password: Yup.string().required('أدخل كلمة المرور القديمة'),
  new_password: Yup.string().required('أدخل كلمة المرور الجديدة'),
  confirm_new_password: Yup.string()
    .required('اعد كلمة المرور')
    .oneOf([Yup.ref('new_password')], 'كلمة المرور غير مطابقة')
});

const ChangePasswordModal = () => {
  const { changePasswordModalOpened, setChangePasswordModalOpened } =
    useContext(ChangePasswordContext);
  const { user } = useContext(UserContext);
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      old_password: '',
      new_password: '',
      confirm_new_password: ''
    }
  });

  const [passwrodVisible, setPasswordVisible] = useState(false);

  const onSubmit = async (data) => {
    try {
      const res = await changePasswordApi(data, user?.token);
      if (checkRes(res)) {
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تغيير كلمة السر بنجاح'
        });
        setChangePasswordModalOpened(false);
      } else {
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  return (
    <Modal
      transitionName=""
      className="product-form-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '442px' }}
      title={<div className="modal-title">تغيير كلمة السر ؟</div>}
      visible={changePasswordModalOpened}
      onOk={() => {
        setChangePasswordModalOpened(false);
      }}
      onCancel={() => {
        setChangePasswordModalOpened(false);
      }}
      footer={false}
    >
      <Form
        className="create-product-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="text-field-label-wrap text-field-wrap with-eye-icon">
            <p className="label-p">كلمة المرور القديمة</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="old_password"
                type={passwrodVisible ? 'text' : 'password'}
                placeholder="كلمة المرور القديمة..."
                errorMsg={errors?.old_password?.message}
                validateStatus={errors?.old_password ? 'error' : ''}
                control={control}
              />
              {watch('old_password') && (
                <div
                  className="eye-icon-btn"
                  onClick={() => {
                    setPasswordVisible((prevState) => !prevState);
                  }}
                >
                  {passwrodVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              )}
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">كلمة المرور الجديدة</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="new_password"
                type={passwrodVisible ? 'text' : 'password'}
                placeholder="كلمة المرور الجديدة..."
                errorMsg={errors?.new_password?.message}
                validateStatus={errors?.new_password ? 'error' : ''}
                control={control}
              />
              {watch('new_password') && (
                <div
                  className="eye-icon-btn"
                  onClick={() => {
                    setPasswordVisible((prevState) => !prevState);
                  }}
                >
                  {passwrodVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              )}
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">أعد كلمة المرور الجديدة</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="confirm_new_password"
                type={passwrodVisible ? 'text' : 'password'}
                placeholder="أعد كلمة المرور الجديدة..."
                errorMsg={errors?.confirm_new_password?.message}
                validateStatus={errors?.confirm_new_password ? 'error' : ''}
                control={control}
              />
              {watch('confirm_new_password') && (
                <div
                  className="eye-icon-btn"
                  onClick={() => {
                    setPasswordVisible((prevState) => !prevState);
                  }}
                >
                  {passwrodVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              )}
            </div>
          </div>

          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmitting}
          >
            تعديل
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
