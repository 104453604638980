import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  detailsModalOpened: false,
  setDetailsModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedUserClients: null,
  setAllFetchedUserClients: (v) => {},
  userClientsPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setUserClientsPagination: (v) => {},
  selectedUserClient: null,
  setSelectedUserClient: (v) => {},
  userClientsFilter: {
    mame: ''
  },
  setUserClientsFilter: (v) => {}
};

const UserClientsContext = createContext(INITIAL_VALUES);

export const UserClientsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedUserClients, setAllFetchedUserClients] = useState(
    INITIAL_VALUES.allFetchedUserClients
  );
  const [selectedUserClient, setSelectedUserClient] = useState(
    INITIAL_VALUES.selectedUserClient
  );
  const [userClientsPagination, setUserClientsPagination] = useState(
    INITIAL_VALUES.userClientsPagination
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES.detailsModalOpened
  );
  const [userClientsFilter, setUserClientsFilter] = useState(
    INITIAL_VALUES.userClientsFilter
  );

  return (
    <UserClientsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        detailsModalOpened,
        setDetailsModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedUserClients,
        setAllFetchedUserClients,
        selectedUserClient,
        setSelectedUserClient,
        userClientsPagination,
        setUserClientsPagination,
        userClientsFilter,
        setUserClientsFilter
      }}
    >
      {children}
    </UserClientsContext.Provider>
  );
};

export default UserClientsContext;
