import axios from 'axios';

const myNotificationsApi = async (token) => {
  try {
    const res = await axios.get('/my-notifications', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default myNotificationsApi;
