import axios from 'axios';

const getSingleOrderApi = async (token, filterValues) => {
  const url = `/order?order_id=${
    filterValues?.order_id ? filterValues.order_id : ''
  }`;
  try {
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default getSingleOrderApi;
