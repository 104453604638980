import axios from 'axios';

const getAllUserClientsApi = async (token, filterValues) => {
  const url = `/user-clients?page=${
    filterValues?.page ? filterValues.page : '1'
  }&name=${filterValues.name ? filterValues.name : ''}&status=${
    filterValues.status ? filterValues.status : ''
  }`;
  try {
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default getAllUserClientsApi;
