/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import getAllUserCategoriesApi from '../apis/categories-apis/getAllUserCategoriesApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
const useUserCategories = () => {
  const [allUserCats, setAllUserCats] = useState(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    let isMounted = true;
    const fetchInfo = async () => {
      try {
        const res = await getAllUserCategoriesApi(user?.token);

        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllUserCats(data);
          } else {
            //
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchInfo();

    return () => {
      isMounted = false;
    };
  }, []);

  return allUserCats;
};

export default useUserCategories;
