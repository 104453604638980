/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import slugify from "slugify";
import Logo from "../../common/logo/Logo";
import MainAppBarContext from "../../contexts/main-app-bar-context/MainAppBarProvider";
import MainAppBarSideMenu from "./MainAppBarSubMenu";
import "./MainAppBar.scss";

const MainAppBar = () => {
  const { pathname } = useLocation();
  const { links, setLinks, setSelectedLink, activeLink } =
    useContext(MainAppBarContext);
  const handleMenuBtnClick = (id) => {
    setLinks((prevState) => {
      return prevState.map((menu) =>
        menu.id === id
          ? { ...menu, visibleSubMenu: true }
          : { ...menu, visibleSubMenu: false }
      );
    });

    const foundLink = links.find((link) => link.id === id);
    if (foundLink) setSelectedLink({ ...foundLink, visibleSubMenu: true });
  };

  const ref = useRef();
  const handleClickOutsideMenu = (event) => {
    if (ref && ref.current && ref.current.contains(event.target)) {
      // inside
      // to be added later
    } else {
      // outside
      event.stopPropagation();
      setLinks((prevState) => {
        return prevState.map((obj) => ({ ...obj, visibleSubMenu: false }));
      });
      setSelectedLink(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideMenu);
    document.addEventListener("keyup", handleClickOutsideMenu);

    return () => {
      document.removeEventListener("click", handleClickOutsideMenu);
      document.removeEventListener("keyup", handleClickOutsideMenu);
    };
  }, []);

  const renderMainLinks = () => {
    return (
      <ul className="app-bar-ul">
        {links.map((item) => {
          const { id, isLink, to, name, icon, visibleSubMenu, subMenu } = item;
          return isLink ? (
            <RouterLink
              to={to}
              className="link-li"
              key={id}
              onClick={() => handleMenuBtnClick(id)}
            >
              <div
                className={`nav-link
                ${slugify(pathname) === slugify(to) ? "active-link" : ""}
                ${visibleSubMenu ? "active-link" : ""}
                ${
                  activeLink && slugify(activeLink?.to) === slugify(to)
                    ? "active-link"
                    : ""
                }
                `}
              >
                <span className="icon-span">
                  {icon(
                    slugify(pathname) === slugify(to) ||
                      visibleSubMenu ||
                      (activeLink && slugify(activeLink?.to) === slugify(to))
                      ? "#5745a9"
                      : ""
                  )}
                </span>
                <span className="name-span">{name}</span>
                <div className="custom-radius-wrap">
                  <div className="custom-top-left-radius"></div>
                  <div className="custom-bottom-left-radius"></div>
                </div>
              </div>
              <MainAppBarSideMenu
                visibleSubMenu={visibleSubMenu}
                name={id}
                menuItems={subMenu}
              />
            </RouterLink>
          ) : (
            <li
              className="link-li"
              key={id}
              onClick={() => handleMenuBtnClick(id)}
            >
              <div
                className={`nav-link
                ${slugify(pathname) === slugify(to) ? "active-link" : ""}
                ${visibleSubMenu ? "active-link" : ""}
                ${
                  activeLink && slugify(activeLink?.to) === slugify(to)
                    ? "active-link"
                    : ""
                }
                `}
                // to={path}
              >
                <span className="icon-span">
                  {icon(
                    slugify(pathname) === slugify(to) ||
                      visibleSubMenu ||
                      (activeLink && slugify(activeLink?.to) === slugify(to))
                      ? "#fff"
                      : ""
                  )}
                </span>
                <span className="name-span">{name}</span>
              </div>
              <MainAppBarSideMenu
                visibleSubMenu={visibleSubMenu}
                name={id}
                menuItems={subMenu}
              />
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="main-app-bar-wrap" ref={ref}>
      <Logo className="side-menu-logo" />

      {renderMainLinks()}
    </div>
  );
};

export default MainAppBar;
