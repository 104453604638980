/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useContext } from 'react';
import NotificationsIcon from '../../common/icons/NotificationsIcon';
import { Menu, Dropdown, Spin } from 'antd';
import MyNotificationsContext from '../../contexts/notifications-context/MyNotificationsContext';
import myNotificationsApi from '../../apis/notifications-apis/myNotificationsApi';
import UserContext from '../../contexts/user-context/UserProvider';
import checkRes from '../../utils/checkRes';

const MainAppNotifications = () => {
  const [settingsClicked, setSettingsClicked] = React.useState(false);
  const notificationsBodyRef = useRef(null);
  const divRef = useRef(null);
  const {
    isLoadingNotifications,
    setIsLoadingNotifications,
    myFetchedNotifications,
    setMyFetchedNotifications,
    setNotificationsPagination,
    fetchCount,
    setFetchCount
  } = useContext(MyNotificationsContext);
  const { user } = useContext(UserContext);
  // const { fcmNotificationPayload } = useContext(MyNotificationsContext);
  // console.log('fcm payload : ', fcmNotificationPayload);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoadingNotifications(true);
        const res = await myNotificationsApi(user?.token);
        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.data.length >= 0) {
            const data = res.data.data.data;
            setMyFetchedNotifications(data);
            if (res.data.data?.pagination) {
              setNotificationsPagination(res.data.data.pagination);
            }
            setIsLoadingNotifications(false);
          } else {
            setIsLoadingNotifications(false);
          }
        }
      } catch (error) {
        setIsLoadingNotifications(false);
        console.log(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchCount, user]);

  useEffect(() => {
    if (myFetchedNotifications?.length > 0 && settingsClicked) {
      console.log(
        'notifbodyref : ',
        notificationsBodyRef?.current?.props?.className
      );
      const dropdownUlClassName =
        notificationsBodyRef?.current?.props?.className;
      if (dropdownUlClassName) {
        const dropdownUlElement = document.querySelector(
          `.${dropdownUlClassName}`
        );
        if (dropdownUlElement) {
          // dropdownUlElement.scrollTop = dropdownUlElement.scrollHeight;
          dropdownUlElement.addEventListener('scroll', () => {
            console.log('scrolltop: ', dropdownUlElement.scrollTop);
            console.log('scrollY: ', dropdownUlElement.scroll);
            console.log('scroll height: ', dropdownUlElement.scrollHeight);
          });
          if (divRef?.current) {
            console.log('div ref : ', divRef);
            // console.log('div classname : ', divRef.current.className);
            // console.log('div height : ', divRef.current.scrollTop);
          }
        }
      }
    }
  }, [settingsClicked, myFetchedNotifications]);

  const renderNotificationsMenue = () => {
    if (isLoadingNotifications) {
      return (
        <Menu.Item className="notifications-menu-link" key={1}>
          <div
            className="notification-content"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Spin />
          </div>
        </Menu.Item>
      );
    }
    if (myFetchedNotifications?.length > 0) {
      return myFetchedNotifications.map(({ id, title, body, icon }) => (
        <Menu.Item className="notifications-menu-link" key={id}>
          <div className="notification-content">
            <div className="notification-img" ref={divRef}>
              <img src={icon} alt="icon" />
            </div>
            <div className="notification-data">
              <div className="notification-title">{title}</div>
              <div className="notification-desc">{body}</div>
            </div>
          </div>
        </Menu.Item>
      ));
    }
    if (
      !isLoadingNotifications &&
      (!myFetchedNotifications || myFetchedNotifications?.length === 0)
    ) {
      return (
        <Menu.Item className="notifications-menu-link" key={1}>
          <div className="notification-content">لا يوجد إشعارات</div>
        </Menu.Item>
      );
    }
    return <Menu.Item className="notifications-menu-link" key={1}></Menu.Item>;
  };

  const menu = (
    <Menu className="notifications-dropdown-ul" ref={notificationsBodyRef}>
      {renderNotificationsMenue()}
    </Menu>
  );
  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      arrow
      placement="bottomLeft"
      overlayClassName="notifications-dropdown-wrap"
      onVisibleChange={() => {
        setSettingsClicked((prevState) => !prevState);
      }}
    >
      <div
        className="main-app-notifications"
        onClick={() => {
          setFetchCount((prev) => prev + 1);
        }}
      >
        <div
          className={`notification-btn-wrap ${
            settingsClicked ? 'clicked' : ''
          }`}
        >
          <NotificationsIcon color="#000" />
          {myFetchedNotifications?.length > 0 && (
            <span className="active-notifications"></span>
          )}
        </div>
      </div>
    </Dropdown>
  );
};

export default MainAppNotifications;
