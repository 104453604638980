import axios from 'axios';

const deleteProductImageApi = async (token, values) => {
  try {
    const res = await axios.post('/delete-product-image', values, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default deleteProductImageApi;
