import React from 'react';

const NotificationsIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
    >
      <path
        id="notifications"
        d="M15.581,11.886l-1.6-1.629V6.244a6.217,6.217,0,0,0-1.443-4.076A6.087,6.087,0,0,0,8.8.052,5.952,5.952,0,0,0,4.068,1.5,6.061,6.061,0,0,0,2.559,3.555a6.124,6.124,0,0,0-.538,2.5v4.2l-1.6,1.629a1.485,1.485,0,0,0,.227,2.26,1.448,1.448,0,0,0,.805.25h2.99V14.7a3.472,3.472,0,0,0,1.107,2.391A3.394,3.394,0,0,0,8,18a3.394,3.394,0,0,0,2.453-.9A3.472,3.472,0,0,0,11.559,14.7V14.4h2.99a1.448,1.448,0,0,0,.805-.25,1.472,1.472,0,0,0,.534-.659,1.488,1.488,0,0,0-.307-1.6ZM9.78,14.7a1.7,1.7,0,0,1-.593,1.108A1.66,1.66,0,0,1,8,16.2a1.66,1.66,0,0,1-1.186-.386A1.7,1.7,0,0,1,6.22,14.7V14.4H9.78ZM2.225,12.6l1.05-1.062A1.81,1.81,0,0,0,3.8,10.257v-4.2a4.3,4.3,0,0,1,.378-1.76A4.253,4.253,0,0,1,5.242,2.851,4.125,4.125,0,0,1,8.569,1.8,4.313,4.313,0,0,1,11.2,3.316a4.4,4.4,0,0,1,1,2.891v4.05a1.811,1.811,0,0,0,.516,1.278L13.775,12.6Z"
        transform="translate(0 0)"
        fill={color ? color : '#ae3a7d'}
      />
    </svg>
  );
};

export default NotificationsIcon;
