import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  detailsModalOpened: false,
  setDetailsModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedClients: null,
  setAllFetchedClients: (v) => {},
  allClientsPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setAllClientsPagination: (v) => {},
  selectedClient: null,
  setSelectedClient: (v) => {},
  allClientsFilter: {
    mame: ''
  },
  setAllClientsFilter: (v) => {}
};

const AllClientsContext = createContext(INITIAL_VALUES);

export const AllClientsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedClients, setAllFetchedClients] = useState(
    INITIAL_VALUES.allFetchedClients
  );
  const [selectedClient, setSelectedClient] = useState(
    INITIAL_VALUES.selectedClient
  );
  const [allClientsPagination, setAllClientsPagination] = useState(
    INITIAL_VALUES.allClientsPagination
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES.detailsModalOpened
  );
  const [allClientsFilter, setAllClientsFilter] = useState(
    INITIAL_VALUES.allClientsFilter
  );

  return (
    <AllClientsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        detailsModalOpened,
        setDetailsModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedClients,
        setAllFetchedClients,
        selectedClient,
        setSelectedClient,
        allClientsPagination,
        setAllClientsPagination,
        allClientsFilter,
        setAllClientsFilter
      }}
    >
      {children}
    </AllClientsContext.Provider>
  );
};

export default AllClientsContext;
