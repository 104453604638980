import React, { useContext } from 'react';
import { Modal } from 'antd';
import DelegatesTableForm from './UserDelegatesTableForm';
import DelegatesContext from '../../contexts/user-delegates-context/UserDelegatesContext';

const DelegatesTableFormModal = () => {
  const { modalOpened, setModalOpened, selectedDelegate, setSelectedDelegate } =
    useContext(DelegatesContext);

  return (
    <Modal
      transitionName=""
      className="product-form-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <div className="modal-title">
          {selectedDelegate ? 'تعديل بيانات المندوب' : 'إضافة مندوب جديد'}
        </div>
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedDelegate(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedDelegate(null);
      }}
      footer={false}
    >
      <DelegatesTableForm />
    </Modal>
  );
};

export default DelegatesTableFormModal;
