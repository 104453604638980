import React, { useContext } from 'react';
import { Modal } from 'antd';
import AllClientsContext from '../../contexts/all-clients-context/AllClientsContext';

const AllClientsPageDetailsModal = () => {
  const {
    detailsModalOpened,
    setDetailsModalOpened,
    setSelectedClient,
    selectedClient
  } = useContext(AllClientsContext);

  const renderSellerMainInfo = () => {
    return (
      <div className="delegate-main-info">
        {selectedClient?.name && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الإســم بالكــامل</div>
            <div className="info-value">{selectedClient.name}</div>
          </div>
        )}
        {selectedClient.phone && (
          <div className="custom-render-info-wrap">
            <div className="info-label">رقــم الهاتف</div>
            <div className="info-value">{selectedClient.phone}</div>
          </div>
        )}
        {selectedClient?.email && (
          <div className="custom-render-info-wrap">
            <div className="info-label">البريد الاكترونى</div>
            <div className="info-value">{selectedClient.email}</div>
          </div>
        )}
        {selectedClient?.balance && (
          <div className="custom-render-info-wrap">
            <div className="info-label">رصيد المحفظة</div>
            <div className="info-value">{selectedClient.balance}</div>
          </div>
        )}

        {selectedClient?.addresses?.length > 0 &&
          selectedClient.addresses.map(
            (ad) =>
              ad && (
                <div
                  className="custom-render-info-wrap"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gridColumn: '1 / -1',
                    padding: '14px 22px'
                  }}
                >
                  {ad.street && (
                    <>
                      <div className="info-label">شارع : </div>
                      <div
                        className="info-value"
                        style={{ marginRight: 2, marginLeft: 12 }}
                      >
                        {ad.street}
                      </div>
                    </>
                  )}
                  {ad.building && (
                    <>
                      <div className="info-label">مبنى : </div>
                      <div
                        className="info-value"
                        style={{ marginRight: 2, marginLeft: 12 }}
                      >
                        {ad.building}
                      </div>
                    </>
                  )}
                  {ad.apartment && (
                    <>
                      <div className="info-label">شقة : </div>
                      <div
                        className="info-value"
                        style={{ marginRight: 2, marginLeft: 12 }}
                      >
                        {ad.apartment}
                      </div>
                    </>
                  )}
                </div>
              )
          )}
      </div>
    );
  };

  return (
    <Modal
      transitionName=""
      className="seller-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">تفاصيل العميل</div>}
      visible={detailsModalOpened}
      onOk={() => {
        setDetailsModalOpened(false);
        setSelectedClient(null);
      }}
      onCancel={() => {
        setDetailsModalOpened(false);
        setSelectedClient(null);
      }}
      footer={false}
    >
      <div className="seller-img-wrap">
        <img
          src={selectedClient?.image ? selectedClient.image : ''}
          alt="clinet"
        />
      </div>

      {renderSellerMainInfo()}
    </Modal>
  );
};

export default AllClientsPageDetailsModal;
