import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./components/app/App";

import { BrowserRouter as Router } from "react-router-dom";
import { MyNotificationsProvider } from "./contexts/notifications-context/MyNotificationsContext";
import { OrdersProvider } from "./contexts/orders-context/OrdersContext";
import "react-circular-progressbar/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import 'antd/dist/antd.css';
// import 'antd/dist/antd.less'; will back
import "react-big-calendar/lib/sass/styles.scss";
import "./scss/index.scss";

import Loading from "./common/loading/Loading";
import { UserProvider } from "./contexts/user-context/UserProvider";
import { MainAppBarProvider } from "./contexts/main-app-bar-context/MainAppBarProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<Loading />}>
    <UserProvider>
      <Router>
        <OrdersProvider>
          <MainAppBarProvider>
            <MyNotificationsProvider>
              <App />
            </MyNotificationsProvider>
          </MainAppBarProvider>
        </OrdersProvider>
      </Router>
    </UserProvider>
  </Suspense>
);
