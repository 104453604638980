/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext, lazy } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { MainAppBarProvider } from "../../contexts/main-app-bar-context/MainAppBarProvider";
import Loading from "../../common/loading/Loading";
import Routes from "./Routes";
import "../../i18n";
import AppLayout from "./Layout";
import { useEffect } from "react";
import axios from "axios";
import { ConfigProvider } from "antd";
import LoginPage from "../../pages/login-page/LoginPage";
import routerLinks from "./routerLinks";
import { ReactNotifications } from "react-notifications-component";
import NotFoundPage from "../../pages/not-found-page/NotFoundPage";
import UserContext from "../../contexts/user-context/UserProvider";
import getUserInfo from "../../apis/auth/myInfoApi";
import DocTitleScrollTop from "../../utils/DocTitleScrollTop";
import checkRes from "../../utils/checkRes";
import MyNotificationsContext from "../../contexts/notifications-context/MyNotificationsContext";
import { onMessageListener } from "../../firebase";
import ForegroundNotifications from "../foreground-notification/ForegroundNotification";
import OrdersContext from "../../contexts/orders-context/OrdersContext";
axios.defaults.baseURL = "https://floweration.fatoorah.online/api";
const HomePage = lazy(() => import("../../pages/home-page/HomePage"));

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n.dir()]);

  DocTitleScrollTop("");

  const { loggedIn, user, removeCurrentUser } = useContext(UserContext);
  const history = useHistory();

  const {
    fcmNotificationFetchCount,
    setFcmNotificationFetchCount,
    fcmNotificationPayload,
    setFcmNotificationPayload,
  } = useContext(MyNotificationsContext);
  const { setFetchCount: setFetchOrdersCount } = useContext(OrdersContext);
  onMessageListener()
    .then((payload) => {
      setFcmNotificationFetchCount((prev) => prev + 1);
      setFcmNotificationPayload({
        // title: payload.notification.title,
        // body: payload.notification.body
        ...payload,
      });
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    if (
      fcmNotificationFetchCount > 0 &&
      fcmNotificationPayload?.data?.click_action == 1
    ) {
      setFetchOrdersCount((prev) => prev + 1);
    }
  }, [fcmNotificationFetchCount, fcmNotificationPayload]);

  useEffect(() => {
    if (user) {
      let isMounted = true;
      const fetchInfo = async () => {
        try {
          const res = await getUserInfo(user?.token);
          if (isMounted) {
            // is response is success
            if (checkRes(res)) {
            } else {
              removeCurrentUser();
              history.push(routerLinks.signinPage);
            }
          }
        } catch (error) {
          removeCurrentUser();
          history.push(routerLinks.signinPage);
        }
      };

      fetchInfo();

      return () => {
        isMounted = false;
      };
    }
  }, []);
  // }, [pathname]);

  return (
    <>
      <div className={`app app-${i18n.dir()}`}>
        <Suspense fallback={<Loading />}>
          <ConfigProvider direction={i18n.dir()}>
            <ReactNotifications className={i18n.dir()} />

            <Switch>
              <Route exact path={routerLinks.signinPage}>
                {!loggedIn ? (
                  <LoginPage />
                ) : (
                  <AppLayout>
                    <HomePage />
                    <ForegroundNotifications
                      fcmNotificationPayload={fcmNotificationPayload}
                      fcmNotificationFetchCount={fcmNotificationFetchCount}
                      setFcmNotificationFetchCount={
                        setFcmNotificationFetchCount
                      }
                      setFcmNotificationPayload={setFcmNotificationPayload}
                    />
                  </AppLayout>
                )}
              </Route>

              {loggedIn && (
                <AppLayout>
                  <Routes />
                  <ForegroundNotifications
                    fcmNotificationPayload={fcmNotificationPayload}
                    fcmNotificationFetchCount={fcmNotificationFetchCount}
                    setFcmNotificationFetchCount={setFcmNotificationFetchCount}
                    setFcmNotificationPayload={setFcmNotificationPayload}
                  />
                </AppLayout>
              )}

              <Route path="*" component={NotFoundPage} />
            </Switch>
          </ConfigProvider>
        </Suspense>
      </div>
    </>
  );
}

export default App;
