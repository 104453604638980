import React, { useContext } from 'react';
import { Modal } from 'antd';
import ProductsContext from '../../contexts/products-context/ProductsContext';
import ProductImagesTableForm from './ProductImagesTableForm';

const ProductImagesModal = () => {
  const { imagesModalOpened, setImagesModalOpened, setSelectedProduct } =
    useContext(ProductsContext);

  return (
    <Modal
      transitionName=""
      className="product-form-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">صور المنتج</div>}
      visible={imagesModalOpened}
      onOk={() => {
        setImagesModalOpened(false);
        setSelectedProduct(null);
      }}
      onCancel={() => {
        setImagesModalOpened(false);
        setSelectedProduct(null);
      }}
      footer={false}
    >
      <ProductImagesTableForm />
    </Modal>
  );
};

export default ProductImagesModal;
