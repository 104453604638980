import { Button, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const tableColumns = (
  allFetchedClients,
  setModalOpened,
  setDetailsModalOpened,
  setFetchCount,
  setSelectedClient,
  setIsLoading,
  token
) => {
  const handleViewDetails = (record) => {
    const found = allFetchedClients.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedClient({ ...found });
      setDetailsModalOpened(true);
    }
  };

  return [
    {
      title: 'صورة العميل',
      dataIndex: 'image',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="img-wrap">
              {record?.image ? (
                <img
                  style={{
                    width: 62,
                    height: 62,
                    borderRadius: 4,
                    objectFit: 'cover',
                    objectPosition: 'top center',
                    border: '1px solid rgba(0, 0, 0, .1)'
                  }}
                  src={record.image}
                  alt={record.name}
                />
              ) : (
                ''
              )}
            </span>
          </div>
        );
      }
    },
    {
      title: 'إســم العميل',
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.name ? record.name : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.phone ? record.phone : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'البريد الاكترونى',
      dataIndex: 'email',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.email ? record.email : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'تفاصيل العميل',
      dataIndex: 'details',
      // width: 192,
      render: (_, record) => {
        return (
          <Tooltip title="عرض التفاصيل">
            <Button
              size="large"
              type="dashed"
              shape="round"
              icon={<EyeOutlined />}
              onClick={() => handleViewDetails(record)}
            />
          </Tooltip>
        );
      }
    }
  ];
};

export default tableColumns;
