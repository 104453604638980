import "./ProductsPage.scss";

import React, { useContext, useEffect, useState } from "react";
import SearchNotificationsProfile from "../../components/search-notifications-profile/SearchNotificationsProfile";
import PageTable from "./PageTable";
import ProductsContext from "../../contexts/products-context/ProductsContext";
import ProductTableFormModal from "./ProductTableFormModal";
import ProductImagesModal from "./ProductImagesModal";
import TableFilter from "./TableFilter";

const ProductsPage = () => {
  const { formModalOpened, imagesModalOpened } = useContext(ProductsContext);
  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
    document.title = "المنتجات";
  }, [isMount]);

  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <div className="page-head-wrap">
          <div className="custom-page-title-profile-wrap">
            <div className="page-title">
              <h2 className="bold-font">المنتجات</h2>
              <p>كشف وعرض كافة المنتجات</p>
            </div>
            <SearchNotificationsProfile />
          </div>
        </div>

        <TableFilter />

        <PageTable />

        {formModalOpened && <ProductTableFormModal />}
        {imagesModalOpened && <ProductImagesModal />}
      </div>
    </div>
  );
};

export default ProductsPage;
