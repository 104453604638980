import React, { useContext, useEffect, useState } from "react";
import SearchNotificationsProfile from "../../components/search-notifications-profile/SearchNotificationsProfile";
import AllClientsContext from "../../contexts/all-clients-context/AllClientsContext";
import AllClientsPageDetailsModal from "./AllClientsPageDetailsModal";
import PageTable from "./PageTable";
import TableFilter from "./TableFilter";

const AllClientsPage = () => {
  const { detailsModalOpened } = useContext(AllClientsContext);
  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
    document.title = "العملاء";
  }, [isMount]);
  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <div className="page-head-wrap">
          <div className="custom-page-title-profile-wrap">
            <div className="page-title">
              <h2 className="bold-font">جميع العملاء</h2>
              <p>كشف وعرض كافة جميع العملاء</p>
            </div>
            <SearchNotificationsProfile />
          </div>
        </div>

        <TableFilter />

        <PageTable />

        {detailsModalOpened && <AllClientsPageDetailsModal />}
      </div>
    </div>
  );
};

export default AllClientsPage;
