import axios from 'axios';

const getAllOrdersApi = async (token, filterValues) => {
  const url = `/show-all-seller-orders?page=${
    filterValues?.page ? filterValues.page : '1'
  }&from_date=${
    filterValues?.from_date ? filterValues.from_date : ''
  }&to_date=${filterValues?.to_date ? filterValues.to_date : ''}&client_name=${
    filterValues?.client_name ? filterValues.client_name : ''
  }&payment_type=${
    filterValues?.payment_type ? filterValues.payment_type : ''
  }&payment_status=${
    filterValues?.payment_status ? filterValues.payment_status : ''
  }`;
  try {
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default getAllOrdersApi;
