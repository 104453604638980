import axios from 'axios';

export const signinApi = async (values, langIsoCode) => {
  const { email, password, device_token } = values;
  try {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('device_token', device_token);
    const signinRes = await axios.post('/login', formData, {
      headers: {
        lang: langIsoCode,
        Accept: 'application/json'
      }
    });
    return signinRes;
  } catch (error) {
    console.log(error);
  }
};
