import React, { useContext, useEffect, useState } from "react";
import PageTable from "./PageTable";
import OrderDetailsModal from "./OrderDetailsModal";
import OrdersContext from "../../contexts/orders-context/OrdersContext";
import OrderDelegatesModal from "./OrderDelegatesModal";
import SearchNotificationsProfile from "../../components/search-notifications-profile/SearchNotificationsProfile";
import TableFilter from "./TableFilter";
import "./OrdersPage.scss";

const OrdersPage = () => {
  const { detailsModalOpened, delegatesModalOpened } =
    useContext(OrdersContext);
  const [isMount, setIsMount] = useState(false);

  useEffect(() => {
    setIsMount(true);
    document.title = "الطلبات";
  }, [isMount]);

  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <div className="page-head-wrap">
          <div className="custom-page-title-profile-wrap">
            <div className="page-title">
              <h2 className="bold-font">الطلبات</h2>
              <p>كشف وعرض كافة الطلبــات</p>
            </div>
            <SearchNotificationsProfile />
          </div>
        </div>

        <div className="add-section">
          {/* <div className="section-text">
          <p className="bold-font">وحدات القياس</p>
        </div> */}
        </div>

        <TableFilter />
        <PageTable />

        {detailsModalOpened && <OrderDetailsModal />}
        {delegatesModalOpened && <OrderDelegatesModal />}
      </div>
    </div>
  );
};

export default OrdersPage;
