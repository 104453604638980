import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  detailsModalOpened: false,
  setDetailsModalOpened: (v) => {},
  delegatesModalOpened: false,
  setDelegatesModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedOrders: null,
  setAllFetchedOrders: (v) => {},
  ordersPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setOrdersPagination: (v) => {},
  selectedOrder: null,
  setSelectedOrder: (v) => {},
  // filter
  ordersFilter: {
    page: 1,
    from_date: '',
    to_date: '',
    client_name: '',
    payment_type: '',
    payment_status: ''
  },
  setOrdersFilter: (v) => {}
};

const OrdersContext = createContext(INITIAL_VALUES);

export const OrdersProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES.detailsModalOpened
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedOrders, setAllFetchedOrders] = useState(
    INITIAL_VALUES.allFetchedOrders
  );
  const [selectedOrder, setSelectedOrder] = useState(
    INITIAL_VALUES.selectedOrder
  );
  const [ordersPagination, setOrdersPagination] = useState(
    INITIAL_VALUES.ordersPagination
  );
  const [delegatesModalOpened, setDelegatesModalOpened] = useState(
    INITIAL_VALUES.delegatesModalOpened
  );
  const [ordersFilter, setOrdersFilter] = useState(INITIAL_VALUES.ordersFilter);

  return (
    <OrdersContext.Provider
      value={{
        isLoading,
        setIsLoading,
        detailsModalOpened,
        setDetailsModalOpened,
        delegatesModalOpened,
        setDelegatesModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedOrders,
        setAllFetchedOrders,
        selectedOrder,
        setSelectedOrder,
        ordersPagination,
        setOrdersPagination,
        //
        ordersFilter,
        setOrdersFilter
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export default OrdersContext;
