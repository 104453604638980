import React, { useContext, useEffect, useState } from "react";
import SearchNotificationsProfile from "../../components/search-notifications-profile/SearchNotificationsProfile";
import PageTable from "./PageTable";
import UserDelegatesContext from "../../contexts/user-delegates-context/UserDelegatesContext";
import DelegatesDetailsModal from "./UserDelegatesDetailsModal";
import DelegatesTableFormModal from "./UserDelegatesTableFormModal";
import TableFilter from "./TableFilter";
import "./DelegatesPage.scss";

const UserDelegatesPage = () => {
  const { modalOpened, detailsModalOpened } = useContext(UserDelegatesContext);
  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
    document.title = "المناديب";
  }, [isMount]);
  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <div className="page-head-wrap">
          <div className="custom-page-title-profile-wrap">
            <div className="page-title">
              <h2 className="bold-font">المندوبين</h2>
              <p>كشف وعرض كافة المندوبين</p>
            </div>
            <SearchNotificationsProfile />
          </div>
        </div>

        <TableFilter />
        <PageTable />

        {modalOpened && <DelegatesTableFormModal />}
        {detailsModalOpened && <DelegatesDetailsModal />}
      </div>
    </div>
  );
};

export default UserDelegatesPage;
