/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import ProductsContext from '../../contexts/products-context/ProductsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import FileInput from '../../common/file-input/FileInput';
import deleteProductImageApi from '../../apis/products/deleteProductImageApi';
import uploadProductImagesApi from '../../apis/products/uploadProductImagesApi';
import './ProductsPage.scss';

const ProductImagesTableForm = () => {
  // const [urls, setUrls] = React.useState([]);
  const { user } = useContext(UserContext);
  const {
    setImagesModalOpened,
    setIsLoading,
    setFetchCount,
    selectedProduct,
    allFetchedProducts,
    setSelectedProduct
  } = useContext(ProductsContext);
  const {
    setValue,
    handleSubmit,
    watch,
    register,
    unregister,
    formState: { isSubmitting }
  } = useForm({
    mode: 'all',
    defaultValues: {
      images: null
    }
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('product_id', selectedProduct?.id);
    if (data?.images?.length > 0) {
      for (let i of data.images) formData.append('image[]', i);
    }
    try {
      setIsLoading(true);
      let res;
      res = await uploadProductImagesApi(user?.token, formData);

      if (checkRes(res)) {
        setIsLoading(false);
        setImagesModalOpened(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: !selectedProduct
            ? 'تم اضافة الصور بنجاج'
            : 'تم تعديل الصور بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ',
          message: res?.data?.message ? res.data.message : 'حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
      console.log(error);
    }
  };

  const deleteImg = async (id) => {
    try {
      setIsLoading(true);
      let res;
      res = await deleteProductImageApi(user?.token, { id });

      if (checkRes(res)) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الصورة بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ',
          message: res?.data?.message ? res.data.message : 'حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (allFetchedProducts?.length > 0) {
      const found = allFetchedProducts.find(
        (obj) => obj.id === selectedProduct.id
      );
      if (found) {
        setSelectedProduct({ ...found });
      }
    }
  }, [allFetchedProducts]);

  const [form] = Form.useForm();
  return (
    <Form
      className="create-product-form custom-shared-form"
      form={form}
      layout="vertical"
      fields={[
        {
          name: 'extras',
          value: watch('extras')
        }
      ]}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <FileInput
          name="images"
          label=""
          // accept="image/png, image/jpg, image/jpeg, image/gif"
          accept="image/*"
          multiple={true}
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          // setUrls={setUrls}
          dropzoneText="اسحب صور المنتج وضعها هنا ..."
          className="product-images-dropzone"
          dropzoneUrls={
            selectedProduct?.images?.length > 0
              ? selectedProduct.images.map((i) => ({ id: i.id, url: i.image }))
              : []
          }
          canDelete={true}
          handleDeleteImgReq={(id) => deleteImg(id)}
        />

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmitting}
        >
          إضافة
        </Button>
      </div>
    </Form>
  );
};

export default ProductImagesTableForm;
