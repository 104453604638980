import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import AddCircledIcon from "../../common/icons/AddCircledIcon";
import SearchNotificationsProfile from "../../components/search-notifications-profile/SearchNotificationsProfile";
import AllCategoriesContext from "../../contexts/all-categories-context/AllCategoriesContext";
import PageModal from "./PageModal";
import PageTable from "./PageTable";

const AllCategoriesPage = () => {
  const { modalOpened, setModalOpened, setSelectedCategory } =
    useContext(AllCategoriesContext);
  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
    document.title = "الفئات";
  }, [isMount]);
  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <div className="page-head-wrap">
          <div className="custom-page-title-profile-wrap">
            <div className="page-title">
              <h2 className="bold-font">جميع الفئات</h2>
              <p>كشف وعرض كافة الفئات</p>
            </div>
            <SearchNotificationsProfile />
          </div>
        </div>

        <div className="add-section" style={{ marginTop: 42 }}>
          <Button
            type="primary"
            className="custom-shared-btn"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              height: 42,
              marginLeft: 18,
            }}
            onClick={() => {
              setSelectedCategory(null);
              setModalOpened(true);
            }}
          >
            <AddCircledIcon color="#fff" />
            إضافة فئة جديدة
          </Button>
        </div>

        <PageTable />

        {modalOpened && <PageModal />}
      </div>
    </div>
  );
};

export default AllCategoriesPage;
