import React, { useContext, useEffect, useState } from "react";
import SearchNotificationsProfile from "../../components/search-notifications-profile/SearchNotificationsProfile";
import PageTable from "./PageTable";
import TableFilter from "./TableFilter";
import FamiliesPageDetailsModal from "./FamiliesPageDetailsModal";
import "./FamiliesPage.scss";
import FamiliesContext from "../../contexts/families-context/FamiliesContext";

const FamiliesPage = () => {
  const { detailsModalOpened } = useContext(FamiliesContext);
  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
    document.title = "الأسر المنتجة";
  }, [isMount]);
  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <div className="page-head-wrap">
          <div className="custom-page-title-profile-wrap">
            <div className="page-title">
              <h2 className="bold-font">الأسر المنتجة</h2>
              <p>كشف وعرض كافة الأسر المنتجة</p>
            </div>
            <SearchNotificationsProfile />
          </div>
        </div>

        <TableFilter />

        <PageTable />

        {detailsModalOpened && <FamiliesPageDetailsModal />}
      </div>
    </div>
  );
};

export default FamiliesPage;
