import React, { useContext } from 'react';
import { Modal, Rate } from 'antd';
import SellersContext from '../../contexts/sellers-context/SellersContext';
import './SellersPageDetailsModal.scss';

const SellersPageDetailsModal = () => {
  const {
    detailsModalOpened,
    setDetailsModalOpened,
    setSelectedSeller,
    selectedSeller
  } = useContext(SellersContext);

  const renderSellerMainInfo = () => {
    return (
      <div className="delegate-main-info">
        {selectedSeller?.name && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الإســم بالكــامل</div>
            <div className="info-value">{selectedSeller.name}</div>
          </div>
        )}
        {selectedSeller.phone && (
          <div className="custom-render-info-wrap">
            <div className="info-label">رقــم الهاتف</div>
            <div className="info-value">{selectedSeller.phone}</div>
          </div>
        )}
        {selectedSeller?.email && (
          <div className="custom-render-info-wrap">
            <div className="info-label">البريد الاكترونى</div>
            <div className="info-value">{selectedSeller.email}</div>
          </div>
        )}
        {selectedSeller.information?.nationality && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الجنســـة</div>
            <div className="info-value">
              {selectedSeller.information?.nationality}
            </div>
          </div>
        )}
        {selectedSeller?.address && (
          <div className="custom-render-info-wrap">
            <div className="info-label">العنــوان</div>
            <div className="info-value">{selectedSeller.address}</div>
          </div>
        )}
        {selectedSeller?.information?.identity_number && (
          <div className="custom-render-info-wrap">
            <div className="info-label">رقم الهوية</div>
            <div className="info-value">
              {selectedSeller.information.identity_number}
            </div>
          </div>
        )}
        {selectedSeller?.information?.tax_record && (
          <div className="custom-render-info-wrap">
            <div className="info-label">الرقم الضريبى</div>
            <div className="info-value">
              {selectedSeller.information.tax_record}
            </div>
          </div>
        )}

        <div
          style={{
            display: 'grid',
            justifyContent: 'center'
            // gridColumn: '1 / -1'
          }}
        >
          <span className="name-it-self">
            <Rate
              allowHalf
              disabled
              style={{ fontSize: 32 }}
              defaultValue={
                selectedSeller.rate ? parseFloat(selectedSeller.rate) : 0
              }
            />
          </span>
        </div>
      </div>
    );
  };

  return (
    <Modal
      transitionName=""
      className="seller-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">تفاصيل المتجر</div>}
      visible={detailsModalOpened}
      onOk={() => {
        setDetailsModalOpened(false);
        setSelectedSeller(null);
      }}
      onCancel={() => {
        setDetailsModalOpened(false);
        setSelectedSeller(null);
      }}
      footer={false}
    >
      <div className="seller-img-wrap">
        <img
          src={selectedSeller?.image ? selectedSeller.image : ''}
          alt="seller"
        />
      </div>

      {renderSellerMainInfo()}
    </Modal>
  );
};

export default SellersPageDetailsModal;
