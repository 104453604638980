import React, { useContext, useEffect, useState } from "react";
import SearchNotificationsProfile from "../../components/search-notifications-profile/SearchNotificationsProfile";
import PageTable from "./PageTable";
import TableFilter from "./TableFilter";
import SellersContext from "../../contexts/sellers-context/SellersContext";
import SellersPageDetailsModal from "./SellersPageDetailsModal";
import "./SellersPage.scss";

const SellersPage = () => {
  const { detailsModalOpened } = useContext(SellersContext);
  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
    document.title = "المتاجر";
  }, [isMount]);
  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <div className="page-head-wrap">
          <div className="custom-page-title-profile-wrap">
            <div className="page-title">
              <h2 className="bold-font">المتاجر</h2>
              <p>كشف وعرض كافة المتاجر</p>
            </div>
            <SearchNotificationsProfile />
          </div>
        </div>

        <TableFilter />

        <PageTable />

        {detailsModalOpened && <SellersPageDetailsModal />}
      </div>
    </div>
  );
};

export default SellersPage;
