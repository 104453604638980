import React from 'react';

const ShoppingBagIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.128"
      height="15.296"
      viewBox="0 0 12.128 15.296"
    >
      <g transform="translate(0)">
        <g>
          <g>
            <path
              d="M65.141,12.613l-.683-8.607a1.076,1.076,0,0,0-1.068-.989H62.041V2.957a2.957,2.957,0,1,0-5.913,0v.061H54.778a1.075,1.075,0,0,0-1.068.988l-.683,8.61A2.491,2.491,0,0,0,55.511,15.3h7.147a2.491,2.491,0,0,0,2.483-2.682ZM57.023,2.957a2.061,2.061,0,1,1,4.121,0v.061H57.023Zm6.8,10.932a1.578,1.578,0,0,1-1.17.511H55.511a1.595,1.595,0,0,1-1.59-1.715l.683-8.61a.176.176,0,0,1,.174-.161h1.349v1.1a.448.448,0,1,0,.9,0v-1.1h4.121v1.1a.448.448,0,0,0,.9,0v-1.1H63.39a.176.176,0,0,1,.174.163l.683,8.607A1.578,1.578,0,0,1,63.827,13.889Z"
              transform="translate(-53.02)"
              fill={color ? color : '#ae3a7d'}
            />
          </g>
        </g>
        <g
          id="Group_8594"
          data-name="Group 8594"
          transform="translate(3.914 7.465)"
        >
          <g id="Group_8593" data-name="Group 8593" transform="translate(0)">
            <path
              d="M188.22,250.024a.448.448,0,0,0-.633,0l-2,2-.769-.769a.448.448,0,1,0-.634.633l1.086,1.086a.448.448,0,0,0,.633,0l2.317-2.317A.448.448,0,0,0,188.22,250.024Z"
              transform="translate(-184.052 -249.893)"
              fill={color ? color : '#ae3a7d'}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ShoppingBagIcon;
