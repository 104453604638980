import React from 'react';

const StoreIcon = ({ color }) => {
  return (
    <svg
      id="Group_8518"
      data-name="Group 8518"
      xmlns="http://www.w3.org/2000/svg"
      width="27.007"
      height="23.58"
      viewBox="0 0 27.007 23.58"
    >
      <path
        id="Path_8405"
        data-name="Path 8405"
        d="M253.691,263.75h-3.67a1.823,1.823,0,0,0-1.821,1.821v2.814a1.823,1.823,0,0,0,1.821,1.821h3.67a1.823,1.823,0,0,0,1.821-1.821v-2.814A1.823,1.823,0,0,0,253.691,263.75Zm.5,4.635a.5.5,0,0,1-.5.5h-3.67a.5.5,0,0,1-.5-.5v-2.814a.5.5,0,0,1,.5-.5h3.67a.5.5,0,0,1,.5.5Z"
        transform="translate(-234.503 -250.909)"
        fill={color ? color : '#5F758E'}
      />
      <path
        id="Path_8406"
        data-name="Path 8406"
        d="M27.007,38.776a.615.615,0,0,0-.088-.326L22.9,31.387a.666.666,0,0,0-.574-.337H4.674a.654.654,0,0,0-.574.337L.088,38.45A.669.669,0,0,0,0,38.776a3.874,3.874,0,0,0,2.318,3.543V53.968a.664.664,0,0,0,.662.662H24.022a.664.664,0,0,0,.662-.662V42.4a.292.292,0,0,0-.006-.072A3.885,3.885,0,0,0,27.007,38.776ZM5.06,32.38H21.941l3.14,5.524H1.926Zm14.16,6.843a2.545,2.545,0,0,1-5.011,0Zm-6.418,0a2.548,2.548,0,0,1-5.016,0Zm-11.434,0H6.385a2.548,2.548,0,0,1-5.016,0Zm9.757,14.083H7.317V46.16a.943.943,0,0,1,.944-.944h1.926a.943.943,0,0,1,.944.944v7.146Zm12.234,0H12.45V46.16a2.272,2.272,0,0,0-2.268-2.268H8.256A2.272,2.272,0,0,0,5.988,46.16v7.152H3.642V42.644c.077.006.155.006.232.006a3.869,3.869,0,0,0,3.212-1.711,3.87,3.87,0,0,0,6.423,0,3.86,3.86,0,0,0,6.412,0,3.869,3.869,0,0,0,3.212,1.711c.077,0,.149-.006.226-.006V53.306Zm-.226-11.981a2.544,2.544,0,0,1-2.505-2.1h5.016A2.559,2.559,0,0,1,23.133,41.325Z"
        transform="translate(0 -31.05)"
        fill={color ? color : '#5F758E'}
      />
    </svg>
  );
};

export default StoreIcon;
