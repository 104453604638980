/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Table, Empty, Pagination } from "antd";
import tableColumns from "./tableColumns";
import checkRes from "../../utils/checkRes";
import { useHistory } from "react-router-dom";
import UserContext from "../../contexts/user-context/UserProvider";
import routerLinks from "../../components/app/routerLinks";
import UserDelegatesContext from "../../contexts/user-delegates-context/UserDelegatesContext";
import getAllUserDelegatesApi from "../../apis/delegates/getAllUserDelegatesApi";

const PageTable = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    isLoading,
    setIsLoading,
    setModalOpened,
    setDetailsModalOpened,
    allFetchedDelegates,
    setAllFetchedDelegates,
    fetchCount,
    setFetchCount,
    setSelectedDelegate,
    delegatesPagination,
    setDelegatesPagination,
    userDelegatesFilter,
  } = useContext(UserDelegatesContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
    }));
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getAllUserDelegatesApi(user?.token, {
          ...userDelegatesFilter,
          ...values,
          // seller_id: user?.id
        });
        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedDelegates(data);
            if (res.data.data?.pagination) {
              setDelegatesPagination(res.data.data.pagination);
            }

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchCount, user]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedDelegates?.length >= 0) {
      const mappedData = mapDataSource(allFetchedDelegates);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedDelegates, allFetchedDelegates?.length]);

  return (
    <>
      <Table
        locale={{
          emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>,
        }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedDelegates,
          setModalOpened,
          setDetailsModalOpened,
          setFetchCount,
          setSelectedDelegate,
          setIsLoading,
          user?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      <Pagination
        defaultCurrent={1}
        current={delegatesPagination.current_page}
        pageSize={delegatesPagination.per_page}
        total={delegatesPagination.total}
        // itemRender={itemRender}
        onChange={(page, pageSize) => {
          setFetchCount((prev) => prev + 1);
          history.push(`${routerLinks.delegates}?page=${page}`);
        }}
        hideOnSinglePage={true}
      />
    </>
  );
};

export default PageTable;
