import React, { useContext } from 'react';
import { Modal } from 'antd';
import ProductsContext from '../../contexts/products-context/ProductsContext';
import ProductTableForm from './ProductTableForm';

const ProductTableFormModal = () => {
  const {
    formModalOpened,
    setFormModalOpened,
    setSelectedProduct,
    selectedProduct
  } = useContext(ProductsContext);

  return (
    <Modal
      transitionName=""
      className="product-form-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <div className="modal-title">
          {selectedProduct ? 'تعديل المنتج' : 'إضافة منتج جديد'}
        </div>
      }
      visible={formModalOpened}
      onOk={() => {
        setFormModalOpened(false);
        setSelectedProduct(null);
      }}
      onCancel={() => {
        setFormModalOpened(false);
        setSelectedProduct(null);
      }}
      footer={false}
    >
      <ProductTableForm />
    </Modal>
  );
};

export default ProductTableFormModal;
