/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { Button } from 'antd';
import { useFieldArray } from 'react-hook-form';
import CustomColorPicker from '../../common/custom-color-picker/CustomColorPicker';
import ProductsContext from '../../contexts/products-context/ProductsContext';
import ProductColorPickerModal from './ProductColorPickerModal';
import randomHexColorCode from '../../utils/generateRandomHexColor';

const NestedColorsFieldArr = React.memo(
  ({
    nestedIndex,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    register,
    extrasFields,
    getNewColorObj,
    allProductExtras
  }) => {
    const {
      setColorPickerModalOpened,
      colorPickerModalOpened,
      setSelectedColorPicker,
      selectedColorPicker,
      allFetchedProducts,
      setSelectedProduct,
      selectedProduct
    } = useContext(ProductsContext);
    const {
      fields: colorsFields,
      // append: appendColorsField,
      remove: removeColorsField
    } = useFieldArray({
      control,
      name: `extras.${nestedIndex}.colors`
    });

    useEffect(() => {
      if (selectedColorPicker) {
        const foundSelectedExtraIndex =
          extrasFields?.length > 0 &&
          extrasFields.findIndex(
            (obj) => obj.extra_custom_id == selectedColorPicker.extra_custom_id
          );
        const foundSelectedColorIndex =
          colorsFields?.length > 0 &&
          colorsFields.findIndex(
            (obj) => obj.color_custom_id == selectedColorPicker.color_custom_id
          );
        if (foundSelectedColorIndex >= 0 && foundSelectedExtraIndex >= 0)
          setValue(
            `extras.${foundSelectedExtraIndex}.colors.${foundSelectedColorIndex}.color`,
            selectedColorPicker.color
          );
      }
    }, [selectedColorPicker]);

    const renderColorPicker = () => {
      return colorsFields.map((color, colorIndex) => {
        return (
          <div key={color.id}>
            {/* <input
            type="text"
            {...register(`extras.${nestedIndex}.colors.${colorIndex}.color`)}
          /> */}

            <div className="colors-wrapper">
              <div
                className="color-wrapper"
                onClick={(e) => {
                  e.stopPropagation();
                  setColorPickerModalOpened(true);
                  setSelectedColorPicker({
                    // ...color
                    ...watch(`extras.${nestedIndex}.colors.${colorIndex}`)
                  });
                }}
              >
                <div
                  className="color-circled"
                  style={{
                    backgroundColor: watch(
                      `extras.${nestedIndex}.colors.${colorIndex}.color`
                    ),
                    width: 32,
                    height: 32,
                    borderRadius: '50%',
                    cursor: 'pointer',
                    border: '1px solid rgba(0, 0, 0 , 0.15)'
                  }}
                >
                  {/* {watch(`extras.${nestedIndex}.colors.${colorIndex}.color`)} */}
                </div>
              </div>
            </div>
          </div>
        );
      });
    };

    // useEffect(() => {
    //   if (allFetchedProducts?.length > 0 && selectedProduct) {
    //     console.log('all fetched products....: ', allFetchedProducts);
    //     console.log('fetching ....');
    //     const found = allFetchedProducts.find(
    //       (obj) => obj.id === selectedProduct.id
    //     );
    //     if (found) {
    //       setSelectedProduct({ ...found });
    //     }
    //   }
    // }, [allFetchedProducts?.length]);

    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: 18
        }}
      >
        <Button
          onClick={() => {
            // appendColorsField(getNewColorObj(randomHexColorCode()));
            setValue(`extras.${nestedIndex}.colors`, [
              ...watch(`extras.${nestedIndex}.colors`),
              { ...getNewColorObj(randomHexColorCode()) }
            ]);

            if (watch('extras')?.length > 0) {
              for (let i = 0; i < watch('extras').length; i++) {
                const found =
                  allProductExtras?.length > 0 &&
                  allProductExtras.find(
                    (obj) => obj.id == watch(`extras.${i}.extra_id`)
                  );

                if (
                  found?.type == 2 &&
                  (!watch(`extras.${i}.colors`) ||
                    watch(`extras.${i}.colors`)?.length === 0)
                ) {
                  setError(`extras.${i}.colors`, {
                    message: 'من فضلك اختار الالوان'
                  });
                } else {
                  clearErrors(`extras.${i}.colors`);
                }
              }
            }
          }}
          className="custom-shared-btn add-more-colors-btn"
        >
          + إضافة لون جديد
        </Button>

        {colorsFields?.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 12
            }}
          >
            {renderColorPicker()}

            {colorPickerModalOpened && (
              <ProductColorPickerModal
                removeColorsField={removeColorsField}
                watch={watch}
                setValue={setValue}
                colorsFields={colorsFields}
                extrasFields={extrasFields}
              >
                <CustomColorPicker
                  color={selectedColorPicker?.color}
                  setColor={(v) => {
                    setSelectedColorPicker((prev) => ({
                      ...prev,
                      color: v
                    }));
                  }}
                />
              </ProductColorPickerModal>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default NestedColorsFieldArr;
