import React from 'react';

const OrderAddressIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.19"
      height="15.19"
      viewBox="0 0 15.19 15.19"
    >
      <path
        d="M17.164,19.228a.313.313,0,0,1-.2-.075C16.84,19.051,14,16.629,14,14.164a3.164,3.164,0,1,1,6.329,0c0,2.465-2.84,4.887-2.961,4.989A.313.313,0,0,1,17.164,19.228Zm0-7.595a2.534,2.534,0,0,0-2.532,2.532c0,1.816,1.9,3.731,2.532,4.321C17.8,17.9,19.7,15.98,19.7,14.164A2.534,2.534,0,0,0,17.164,11.633Z"
        transform="translate(-5.139 -4.038)"
        fill={color ? color : '#ae3a7d'}
      />
      <path
        d="M18.266,16.532a1.266,1.266,0,1,1,1.266-1.266A1.267,1.267,0,0,1,18.266,16.532Zm0-1.9a.633.633,0,1,0,.633.633A.634.634,0,0,0,18.266,14.633Z"
        transform="translate(-6.241 -5.139)"
        fill={color ? color : '#ae3a7d'}
      />
      <path
        d="M14.873,8.633H.316A.316.316,0,0,1,.316,8H14.873a.316.316,0,0,1,0,.633Z"
        transform="translate(0 -2.937)"
        fill={color ? color : '#ae3a7d'}
      />
      <path
        id="Path_8399"
        data-name="Path 8399"
        d="M14.607,5.7H1.316A.316.316,0,0,1,1,5.34L1.566.832A.95.95,0,0,1,2.508,0H13.416a.95.95,0,0,1,.942.832l.564,4.509a.316.316,0,0,1-.314.356ZM1.675,5.063H14.248L13.729.91a.316.316,0,0,0-.314-.277H2.508A.316.316,0,0,0,2.194.91Z"
        transform="translate(-0.367 0)"
        fill={color ? color : '#ae3a7d'}
      />
      <path
        id="Path_8400"
        data-name="Path 8400"
        d="M6.316,5.7a.278.278,0,0,1-.039,0A.316.316,0,0,1,6,5.341L6.635.277a.316.316,0,1,1,.628.078L6.63,5.419A.317.317,0,0,1,6.316,5.7Z"
        transform="translate(-2.203 0)"
        fill={color ? color : '#ae3a7d'}
      />
      <path
        id="Path_8401"
        data-name="Path 8401"
        d="M11.816,5.7A.317.317,0,0,1,11.5,5.38V.316a.316.316,0,0,1,.633,0V5.38A.317.317,0,0,1,11.816,5.7Z"
        transform="translate(-4.222)"
        fill={color ? color : '#ae3a7d'}
      />
      <path
        d="M16.949,5.7a.317.317,0,0,1-.314-.277L16,.356A.316.316,0,0,1,16.63.277l.633,5.063a.316.316,0,0,1-.275.353A.278.278,0,0,1,16.949,5.7Z"
        transform="translate(-5.874 0)"
        fill={color ? color : '#ae3a7d'}
      />
      <path
        d="M9.278,16.861h-5.7A1.584,1.584,0,0,1,2,15.278V8.316A.317.317,0,0,1,2.316,8H14.342a.317.317,0,0,1,.316.316v.633a.316.316,0,1,1-.633,0V8.633H2.633v6.645a.951.951,0,0,0,.949.949h5.7a.316.316,0,1,1,0,.633Z"
        transform="translate(-0.734 -2.937)"
        fill={color ? color : '#ae3a7d'}
      />
      <path
        d="M7.848,15.8h-1.9A.951.951,0,0,1,5,14.848v-1.9A.951.951,0,0,1,5.949,12h1.9a.951.951,0,0,1,.949.949v1.9A.951.951,0,0,1,7.848,15.8Zm-1.9-3.164a.317.317,0,0,0-.316.316v1.9a.317.317,0,0,0,.316.316h1.9a.317.317,0,0,0,.316-.316v-1.9a.317.317,0,0,0-.316-.316Z"
        transform="translate(-1.836 -4.405)"
        fill={color ? color : '#ae3a7d'}
      />
    </svg>
  );
};

export default OrderAddressIcon;
