import React from 'react';

const DashboardIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.141"
      height="19.137"
      viewBox="0 0 19.141 19.137"
    >
      <path
        id="dashboard"
        d="M19.141,4.227a.811.811,0,0,0-.258-.59L16.02.281A.8.8,0,0,0,15.414,0H3.719a.789.789,0,0,0-.605.281L.191,3.711A.8.8,0,0,0,0,4.227V18.34a.8.8,0,0,0,.8.8H18.336a.8.8,0,0,0,.8-.8s.008-14.078.008-14.113Zm-1.6,1.445a1.3,1.3,0,0,1-1.3,1.3h-.2a1.3,1.3,0,0,1-1.3-1.3V5.023h2.793Zm-4.387,0a1.3,1.3,0,0,1-1.3,1.3h-.2a1.3,1.3,0,0,1-1.3-1.3V5.023h2.789v.648Zm-4.379,0a1.3,1.3,0,0,1-1.3,1.3h-.2a1.3,1.3,0,0,1-1.3-1.3V5.023H8.77v.648Zm-4.383,0a1.3,1.3,0,0,1-1.3,1.3H2.9a1.3,1.3,0,0,1-1.3-1.3V5.023H4.391ZM4.1,1.6H15.055L16.617,3.43H2.535L4.1,1.6ZM8.43,17.543V13.414h2.293v4.129Zm3.887,0V12.617a.8.8,0,0,0-.8-.8H7.633a.8.8,0,0,0-.8.8v4.926H1.6V8.254a2.885,2.885,0,0,0,1.3.309h.2a2.892,2.892,0,0,0,2.094-.9,2.877,2.877,0,0,0,2.094.9h.2a2.892,2.892,0,0,0,2.094-.9,2.877,2.877,0,0,0,2.094.9h.2a2.892,2.892,0,0,0,2.094-.9,2.877,2.877,0,0,0,2.094.9h.2a2.9,2.9,0,0,0,1.3-.309v9.289Z"
        fill={color ? color : '#5F758E'}
      />
    </svg>
  );
};

export default DashboardIcon;
