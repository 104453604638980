/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import { Select } from "antd";
import UserContext from "../../contexts/user-context/UserProvider";
import useCategories from "../../custom-hooks/useCategories";
import checkRes from "../../utils/checkRes";
import SelectDropDown from "../../common/icons/SelectDropDown";
import SearchIcon from "../../common/icons/SearchIcon";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import SellersContext from "../../contexts/sellers-context/SellersContext";
import "./TableFilter.scss";
import getAllSellersApi from "../../apis/sellers-apis/getAllSellersApi";
import allUserStatusArr from "../../utils/allUserStatusArr";
import routerLinks from "../../components/app/routerLinks";

const TableFilter = () => {
  const history = useHistory();
  const { Option } = Select;
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    setIsLoading,
    setAllFetchedSellers,
    setSellersPagination,
    sellersFilter,
    setSellersFilter,
  } = useContext(SellersContext);
  const allCategories = useCategories();
  // const [filterValues, setFilterValues] = React.useState({
  //   status: '',
  //   mame: '',
  //   category_id: '',
  //   activity: ''
  // });
  async function handleChange(filterType, value) {
    // console.log(`selected ${value}`);
    setSellersFilter((prevState) => {
      if (filterType === "name") {
        return {
          ...prevState,
          name: value,
        };
      } else if (filterType === "status") {
        return {
          ...prevState,
          status: value,
        };
      } else if (filterType === "activity") {
        return {
          ...prevState,
          activity: value,
        };
      } else if (filterType === "category_id") {
        return {
          ...prevState,
          category_id: value,
        };
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await getAllSellersApi(user?.token, {
        ...sellersFilter,
        ...values,
        page: 1,
      });

      // is response is success
      if (checkRes(res) && res.data?.data?.data?.length >= 0) {
        const data = res.data.data.data;
        history.push(`${routerLinks.sellers}?page=1`);
        setAllFetchedSellers(data);
        if (res.data.data?.pagination) {
          setSellersPagination(res.data.data.pagination);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  return (
    <div className="custom-table-fitler" style={{ marginTop: 42 }}>
      <div className="form-excel-export-wrap">
        <form
          className="filter-form"
          onSubmit={handleSubmit}
          style={{
            gap: 10,
          }}
        >
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder="أدخل اسم المتجر للبحث"
                type="text"
                name="table_search"
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </label>
          </div>
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder="أدخل نشاط المتجر للبحث"
                type="text"
                name="table_search"
                onChange={(e) => handleChange("activity", e.target.value)}
              />
            </label>
          </div>

          <label className="entries-wrap">
            {/* <span className="select-title">إختار الموظف</span> */}
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange("category_id", v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الاقسـام</Option>
              {allCategories?.length > 0 &&
                allCategories.map((obj, index) => (
                  <Option key={index} value={obj.id}>
                    {obj.name}
                  </Option>
                ))}
            </Select>
          </label>
          <label className="entries-wrap">
            {/* <span className="select-title">إختار الموظف</span> */}
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange("status", v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الحالات</Option>
              {allUserStatusArr?.length > 0 &&
                allUserStatusArr.map((obj, index) => (
                  <Option key={index} value={obj.id}>
                    {obj.name}
                  </Option>
                ))}
            </Select>
          </label>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button>
        </form>
      </div>
    </div>
  );
};
export default TableFilter;
