/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Modal, Spin } from 'antd';
import UserContext from '../../contexts/user-context/UserProvider';
import OrdersContext from '../../contexts/orders-context/OrdersContext';
import ShoppingBagIcon from '../../common/icons/ShoppingBagIcon';
import OrderAddressIcon from '../../common/icons/orderAddressIcon';
import MoneyBagIcon from '../../common/icons/MoneyBagIcon';
import getSingleOrderApi from '../../apis/orders/getSingleOrderApi';
import checkRes from '../../utils/checkRes';
import './OrderDetailsModal.scss';

const OrderDetailsModal = () => {
  const { user } = useContext(UserContext);
  const {
    detailsModalOpened,
    setDetailsModalOpened,
    setSelectedOrder,
    selectedOrder,
    setDelegatesModalOpened
  } = useContext(OrdersContext);

  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [fetchedOrderDetails, setFetchedOrderDetails] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (selectedOrder) {
      const fetchData = async () => {
        try {
          setIsLoadingDetails(true);
          const res = await getSingleOrderApi(user?.token, {
            order_id: selectedOrder?.id
          });
          if (isMounted) {
            // is response is success
            if (checkRes(res) && res.data?.data) {
              const data = res.data.data;
              setFetchedOrderDetails(data);

              setIsLoadingDetails(false);
            } else {
              setIsLoadingDetails(false);
            }
          }
        } catch (error) {
          setIsLoadingDetails(false);
          console.log(error);
        }
      };

      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [selectedOrder]);

  const renderOrderProducts = (products) => {
    return products.map((obj) => {
      return (
        <li key={obj.cart_item_id} className="product-li-box">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 18
            }}
          >
            <div className="product-img">
              <img
                src={obj.product?.image ? obj.product.image : ''}
                alt="product"
              />
            </div>
            <div className="p-name-price">
              <div className="product-name">
                {obj.product?.name ? obj.product.name : ''}
              </div>
              <div className="product-price">
                {obj?.product_with_extra_price && obj?.quantity
                  ? parseFloat(obj.product_with_extra_price) *
                    parseFloat(obj.quantity)
                  : ''}{' '}
                ريال
              </div>
            </div>
          </div>

          {obj?.order_extras?.length > 0 && (
            <ul
              style={{
                display: 'grid',
                gap: 5,
                borderTop: '1px dashed rgba(0, 0, 0, .1)',
                paddingTop: 10
              }}
            >
              {obj.order_extras.map((ex) => (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 12,
                    alignItems: 'center'
                  }}
                >
                  <span>{ex?.extra?.extra?.name}</span>
                  <ArrowLeftOutlined style={{ fontSize: 15 }} />
                  {ex?.extra?.extra?.type === 1 && ex.notes}
                  {ex?.extra?.extra?.type === 2 && (
                    <span
                      style={{
                        backgroundColor: ex.notes,
                        width: 22,
                        height: 22,
                        borderRadius: '50%'
                      }}
                    ></span>
                  )}
                  {/* {ex?.price ? ex.price : ''} */}
                </div>
              ))}
            </ul>
          )}

          <div className="quantity-wrap">x {obj.quantity}</div>
        </li>
      );
    });
  };

  const handleOpenDelegatesModal = () => {
    setDelegatesModalOpened(true);
  };

  const renderModalContent = () => {
    if (isLoadingDetails)
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 332
          }}
        >
          <Spin />
        </div>
      );
    if (fetchedOrderDetails) {
      return (
        <div>
          <div className="order-client-details">
            <div className="name-address-wrap">
              <div className="custom-circled-icon">
                <OrderAddressIcon />
              </div>
              <div className="name-address">
                <div className="client-name">
                  {fetchedOrderDetails?.client?.name
                    ? fetchedOrderDetails.client.name
                    : ''}
                </div>
                <div className="client-address">
                  {fetchedOrderDetails?.address
                    ? fetchedOrderDetails.address
                    : ''}
                </div>
              </div>
            </div>

            <div className="order-total">
              <div className="custom-circled-icon">
                <MoneyBagIcon />
              </div>
              {fetchedOrderDetails?.total ? fetchedOrderDetails.total : ''} ريال
            </div>
          </div>

          {fetchedOrderDetails?.details?.length > 0 && (
            <ul className="order-products">
              {renderOrderProducts(fetchedOrderDetails.details)}
            </ul>
          )}

          {fetchedOrderDetails?.status == 2 &&
            (fetchedOrderDetails?.is_assign === 1 ? (
              <div className="action-btns-wrap">
                <Button
                  className="accept-btn"
                  type="primary"
                  onClick={() => handleOpenDelegatesModal()}
                >
                  تعيين مندوب
                </Button>
              </div>
            ) : (
              <div className="action-btns-wrap">
                <div className="reject-btn">تعيين مندوب</div>
              </div>
            ))}
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      transitionName=""
      className="order-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <div className="modal-title">
          <div className="svg-wrap">
            <ShoppingBagIcon />
          </div>
          تفاصيل الطلب
        </div>
      }
      visible={detailsModalOpened}
      onOk={() => {
        setDetailsModalOpened(false);
        setSelectedOrder(null);
        setFetchedOrderDetails(null);
      }}
      onCancel={() => {
        setDetailsModalOpened(false);
        setSelectedOrder(null);
        setFetchedOrderDetails(null);
      }}
      footer={false}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default OrderDetailsModal;
