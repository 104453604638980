import axios from 'axios';

const assignOrderToFreelanceDelegateApi = async (orderId, token) => {
  try {
    const url = `add-order-to-freelance-agent/${orderId}`;
    const res = await axios.post(
      url,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token,
          lang: 'ar'
        }
      }
    );
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default assignOrderToFreelanceDelegateApi;
