import React from 'react';

const MoneyBagIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.917"
      height="14.661"
      viewBox="0 0 11.917 14.661"
    >
      <g id="money_3_" data-name="money (3)" transform="translate(0)">
        <g
          id="Group_8597"
          data-name="Group 8597"
          transform="translate(3.546 3.353)"
        >
          <g id="Group_8596" data-name="Group 8596">
            <g id="Group_8595" data-name="Group 8595">
              <path
                id="Path_8385"
                data-name="Path 8385"
                d="M316.209,117.11h.017S316.213,117.076,316.209,117.11Z"
                transform="translate(-312.072 -117.095)"
                fill={color ? color : '#ae3a7d'}
              />
              <path
                id="Path_8386"
                data-name="Path 8386"
                d="M171.747,117.11h.017C171.76,117.076,171.747,117.11,171.747,117.11Z"
                transform="translate(-171.747 -117.095)"
                fill={color ? color : '#ae3a7d'}
              />
            </g>
          </g>
        </g>
        <g id="Group_8599" data-name="Group 8599">
          <g id="Group_8598" data-name="Group 8598" transform="translate(0 0)">
            <path
              id="Path_8387"
              data-name="Path 8387"
              d="M59.737,4.423a.674.674,0,0,0-.423-.3l-.929-.214.429-.12a.678.678,0,1,0-.365-1.306l-2,.56a.682.682,0,0,0-.215-.236,8.357,8.357,0,0,0,.991-2.59A.219.219,0,0,0,56.965,0,10.731,10.731,0,0,1,54.7.231,13.051,13.051,0,0,1,52.437.005a.218.218,0,0,0-.231.323,2.244,2.244,0,0,1,.278.742A4.483,4.483,0,0,1,50.412.716a.218.218,0,0,0-.31.229,4.688,4.688,0,0,0,.914,2.207h-.008a.678.678,0,0,0-.089,1.35A8.106,8.106,0,0,1,49.968,5.7a6.769,6.769,0,0,0-1.942,3.4,5.036,5.036,0,0,0,.014,2.09,3.872,3.872,0,0,0,.794,1.6,4.889,4.889,0,0,0,2.1,1.426,7.811,7.811,0,0,0,5.234,0,4.889,4.889,0,0,0,2.1-1.426,3.872,3.872,0,0,0,.794-1.6,5.036,5.036,0,0,0,.014-2.09,6.769,6.769,0,0,0-1.942-3.4c-.262-.291-.512-.569-.721-.851l2.608.6a.678.678,0,0,0,.726-1.021ZM54.7.664A10.926,10.926,0,0,0,56.75.486,11.208,11.208,0,0,1,55.8,2.693a.679.679,0,0,0-.591.459h-.6l-.045-.1a5.036,5.036,0,0,1-.572-1.915.218.218,0,0,0-.259-.212,7.635,7.635,0,0,1-.817.117,2.791,2.791,0,0,0-.145-.539A14.57,14.57,0,0,0,54.7.664Zm1.4,2.7v.923a.245.245,0,1,1-.49,0V3.369a.245.245,0,1,1,.49,0Zm-5.493-2.1a5.471,5.471,0,0,0,1.925.241l.429-.027c.2-.019.407-.045.614-.08a5.781,5.781,0,0,0,.559,1.755H51.568a.223.223,0,0,0-.016-.022A4.14,4.14,0,0,1,50.606,1.264Zm6.2,4.722a6.414,6.414,0,0,1,1.839,3.192,3.867,3.867,0,0,1-.716,3.33,5.683,5.683,0,0,1-4.379,1.721,5.683,5.683,0,0,1-4.379-1.721,3.867,3.867,0,0,1-.716-3.33A6.413,6.413,0,0,1,50.29,5.985a7.093,7.093,0,0,0,1.12-1.477h.528a.216.216,0,1,0,0-.433h-.93a.245.245,0,1,1,0-.49h4.168v.49H52.969a.216.216,0,0,0,0,.433h2.242a.678.678,0,0,0,.744.454A10.52,10.52,0,0,0,56.8,5.985Zm-.271-2.516L58.566,2.9a.245.245,0,1,1,.132.472L57.535,3.7l-.027.01-.977-.224ZM59.4,4.838a.245.245,0,0,1-.294.184l-2.59-.6a.68.68,0,0,0,.014-.135V3.927l2.686.617A.248.248,0,0,1,59.4,4.838Z"
              transform="translate(-47.923 -0.001)"
              fill={color ? color : '#ae3a7d'}
            />
          </g>
        </g>
        <g
          id="Group_8601"
          data-name="Group 8601"
          transform="translate(3.675 5.459)"
        >
          <g id="Group_8600" data-name="Group 8600">
            <path
              id="Path_8388"
              data-name="Path 8388"
              d="M179.609,194.082a.563.563,0,0,0,.563-.563v-.346a1.6,1.6,0,0,0-1.385-1.586v-.375a.563.563,0,1,0-1.125,0v.4a1.774,1.774,0,0,0,.389,3.5h.346a.649.649,0,0,1,0,1.3h-.519a.477.477,0,0,1-.476-.476.563.563,0,0,0-1.125,0,1.6,1.6,0,0,0,1.385,1.586v.375a.563.563,0,1,0,1.125,0v-.4a1.774,1.774,0,0,0-.389-3.5h-.346a.649.649,0,0,1,0-1.3h.519a.477.477,0,0,1,.476.476v.346A.563.563,0,0,0,179.609,194.082Zm-1.038-1.817h-.519a1.082,1.082,0,0,0,0,2.163h.346a1.341,1.341,0,0,1,.149,2.674.216.216,0,0,0-.192.215v.587a.13.13,0,1,1-.26,0v-.577a.216.216,0,0,0-.216-.216,1.17,1.17,0,0,1-1.168-1.168.13.13,0,1,1,.26,0,.91.91,0,0,0,.909.909h.519a1.082,1.082,0,0,0,0-2.163h-.346a1.341,1.341,0,0,1-.149-2.674.216.216,0,0,0,.192-.215v-.587a.13.13,0,0,1,.26,0v.577a.216.216,0,0,0,.216.216,1.17,1.17,0,0,1,1.168,1.168v.346a.13.13,0,0,1-.26,0v-.346A.91.91,0,0,0,178.57,192.264Z"
              transform="translate(-176.277 -190.649)"
              fill={color ? color : '#ae3a7d'}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MoneyBagIcon;
