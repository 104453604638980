import axios from 'axios';

const updateDelegateApi = async (token, values) => {
  try {
    const url = '/seller-agent-update-profile';
    const res = await axios.post(url, values, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });

    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default updateDelegateApi;
