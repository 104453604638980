/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import UserContext from '../../contexts/user-context/UserProvider';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import FileInput from '../../common/file-input/FileInput';
import createNewDelegateApi from '../../apis/orders/createNewDelegateApi';
import './DelegateTableForm.scss';
import AllDelegatesContext from '../../contexts/all-delegates-context/AllDelegatesContext';

const schema = Yup.object().shape({
  name: Yup.string().required('ادخل اسم المنتج'),
  email: Yup.string()
    .required('من فضلك ادخل البريد الاكترونى')
    .email('ادخل بريد الكترونى صحيح'),
  phone: Yup.string()
    .required('ادخل رقم الهاتف')
    .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام')
    .min(10, 'اقل حد 10 ارقام'),
  password: Yup.string().required('من فضلك ادخل كلمة المرور'),
  confirm_password: Yup.string()
    .required('اعد كلمة المرور')
    .oneOf([Yup.ref('password')], 'كلمة المرور غير مطابقة'),
  nationality: Yup.string().required('أدخل الجنسية'),
  identity_number: Yup.string()
    .required('أدخل رقم الهوية')
    .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام'),
  address: Yup.string().required('ادخل العنوان'),
  driving_image: Yup.mixed().required('ادخل صورة القيادة'),
  vehicle_image: Yup.mixed().required('أدخل صورة المركبة'),
  vehicle_registration: Yup.mixed().required('أدخل صورة استمارة المركبة'),
  vehicle_tablet_image: Yup.mixed().required('أدخل صورة لوحة المركبة')
});

const AllDelegatesTableForm = () => {
  // const [urls, setUrls] = React.useState([]);
  const { user } = useContext(UserContext);
  const [passwrodVisible, setPasswordVisible] = useState(false);
  const { setModalOpened, setIsLoading, setFetchCount, selectedDelegate } =
    useContext(AllDelegatesContext);
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    register,
    unregister,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      password: '',
      confirm_password: '',
      nationality: '',
      address: '',
      identity_number: '',
      driving_image: null,
      vehicle_image: null,
      vehicle_registration: null,
      vehicle_tablet_image: null
    }
  });

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data.name) formData.append('name', data.name);
    if (data.phone) formData.append('phone', data.phone);
    if (data.email) formData.append('email', data.email);
    if (data.password) formData.append('password', data.password);
    if (data.nationality) formData.append('nationality', data.nationality);
    if (data.address) formData.append('address', data.address);
    if (data.identity_number)
      formData.append('identity_number', data.identity_number);
    if (data?.driving_image?.length > 0) {
      formData.append('driving_image', data.driving_image[0]);
    }
    if (data?.vehicle_image?.length > 0) {
      formData.append('vehicle_image', data.vehicle_image[0]);
    }
    if (data?.vehicle_registration?.length > 0) {
      formData.append('vehicle_registration', data.vehicle_registration[0]);
    }
    if (data?.vehicle_tablet_image?.length > 0) {
      formData.append('vehicle_tablet_image', data.vehicle_tablet_image[0]);
    }
    try {
      setIsLoading(true);
      let res;
      if (selectedDelegate) {
        formData.append('id', selectedDelegate.id);
        res = await createNewDelegateApi(user?.token, formData, true);
      } else {
        res = await createNewDelegateApi(user?.token, formData);
      }

      if (checkRes(res)) {
        setIsLoading(false);
        setModalOpened(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم اضافه المندوب بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ',
          message: res?.data?.message ? res.data.message : 'حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
      console.log(error);
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedDelegate) {
      setValue('name', selectedDelegate?.name ? selectedDelegate.name : '');
      setValue('phone', selectedDelegate?.phone ? selectedDelegate.phone : '');
      setValue('email', selectedDelegate?.email ? selectedDelegate.email : '');
      setValue(
        'password',
        selectedDelegate?.password ? selectedDelegate.password : ''
      );
      setValue(
        'nationality',
        selectedDelegate?.nationality ? selectedDelegate.nationality : ''
      );
      setValue(
        'address',
        selectedDelegate?.address ? selectedDelegate.address : ''
      );
      setValue(
        'identity_number',
        selectedDelegate?.identity_number
          ? selectedDelegate.identity_number
          : ''
      );
    }
  }, [selectedDelegate, selectedDelegate?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="create-delegate-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="name-phone-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">اســم المندوب</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="name"
                type="text"
                placeholder="اسـم المندوب..."
                errorMsg={errors?.name?.message}
                validateStatus={errors?.name ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">رقم الهاتف</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="phone"
                type="text"
                placeholder="رقم الهاتف..."
                errorMsg={errors?.phone?.message}
                validateStatus={errors?.phone ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="email-nationality-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">البريد الاكترونى</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="email"
                type="text"
                placeholder="البريد الاكترونى..."
                errorMsg={errors?.email?.message}
                validateStatus={errors?.email ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">الجنســية</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="nationality"
                type="text"
                placeholder="الجنســية..."
                errorMsg={errors?.nationality?.message}
                validateStatus={errors?.nationality ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="address-identity-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">العنوان</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="address"
                type="text"
                placeholder="العنوان..."
                errorMsg={errors?.address?.message}
                validateStatus={errors?.address ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">رقم الهوية</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="identity_number"
                type="text"
                placeholder="رقم الهوية..."
                errorMsg={errors?.identity_number?.message}
                validateStatus={errors?.identity_number ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="passwords-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">كلمة المرور</p>
            <div className="text-field-wrap with-eye-icon">
              <AntdTextField
                className="form-text-field"
                name="password"
                type={passwrodVisible ? 'text' : 'password'}
                placeholder="كلمة المرور..."
                errorMsg={errors?.password?.message}
                validateStatus={errors?.password ? 'error' : ''}
                control={control}
              />

              {watch('password') && (
                <div
                  className="eye-icon-btn"
                  onClick={() => {
                    setPasswordVisible((prevState) => !prevState);
                  }}
                >
                  {passwrodVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              )}
            </div>
          </div>

          <div className="text-field-label-wrap">
            <p className="label-p">تأكيد كلمة المرور</p>
            <div className="text-field-wrap with-eye-icon">
              <AntdTextField
                className="form-text-field"
                name="confirm_password"
                type={passwrodVisible ? 'text' : 'password'}
                placeholder="تأكيد كلمة المرور..."
                errorMsg={errors?.confirm_password?.message}
                validateStatus={errors?.confirm_password ? 'error' : ''}
                control={control}
              />

              {watch('confirm_password') && (
                <div
                  className="eye-icon-btn"
                  onClick={() => {
                    setPasswordVisible((prevState) => !prevState);
                  }}
                >
                  {passwrodVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="file-inputs-wrap">
          <FileInput
            name="driving_image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label="صورة القيادة"
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب صورة الفيادة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedDelegate?.information?.driving_image
                ? [{ url: selectedDelegate.information.driving_image }]
                : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.driving_image?.message}
          />
          <FileInput
            name="vehicle_image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label="صورة المركبة"
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب صورة المركبة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedDelegate?.information?.vehicle_image
                ? [{ url: selectedDelegate.information.vehicle_image }]
                : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.vehicle_image?.message}
          />
          <FileInput
            name="vehicle_registration"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label="صورة استمارة المركبة"
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب صورة استمارة المركبة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedDelegate?.information?.vehicle_registration
                ? [{ url: selectedDelegate.information.vehicle_registration }]
                : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.vehicle_registration?.message}
          />
          <FileInput
            name="vehicle_tablet_image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label="صورة لوحة المركبة"
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب صورة لوحة المركبة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedDelegate?.information?.vehicle_tablet_image
                ? [{ url: selectedDelegate.information.vehicle_tablet_image }]
                : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.vehicle_tablet_image?.message}
          />
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmitting}
        >
          إضافة
        </Button>
      </div>
    </Form>
  );
};

export default AllDelegatesTableForm;
