import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  detailsModalOpened: false,
  setDetailsModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedFamilies: null,
  setAllFetchedFamilies: (v) => {},
  familiesPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setFamiliesPagination: (v) => {},
  selectedFamily: null,
  setSelectedFamily: (v) => {},
  familiesFilter: {
    status: '',
    mame: '',
    category_id: '',
    activity: ''
  },
  setFamiliesFilter: (v) => {}
};

const FamiliesContext = createContext(INITIAL_VALUES);

export const FamiliesProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedFamilies, setAllFetchedFamilies] = useState(
    INITIAL_VALUES.allFetchedFamilies
  );
  const [selectedFamily, setSelectedFamily] = useState(
    INITIAL_VALUES.selectedFamily
  );
  const [familiesPagination, setFamiliesPagination] = useState(
    INITIAL_VALUES.familiesPagination
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES.detailsModalOpened
  );
  const [familiesFilter, setFamiliesFilter] = useState(
    INITIAL_VALUES.familiesFilter
  );

  return (
    <FamiliesContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        detailsModalOpened,
        setDetailsModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedFamilies,
        setAllFetchedFamilies,
        selectedFamily,
        setSelectedFamily,
        familiesPagination,
        setFamiliesPagination,
        familiesFilter,
        setFamiliesFilter
      }}
    >
      {children}
    </FamiliesContext.Provider>
  );
};

export default FamiliesContext;
