/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ceateNewProductApi from '../../apis/products/createNewProductApi';
import ProductsContext from '../../contexts/products-context/ProductsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import RenderExtrasFields from './RenderExtrasFields';
import FileInput from '../../common/file-input/FileInput';
import useProductExtras from '../../custom-hooks/useProductExtras';
import useUserCategories from '../../custom-hooks/useUserCategories';
import { nanoid } from 'nanoid';
import './ProductsPage.scss';

const ProductTableForm = () => {
  // const [urls, setUrls] = React.useState([]);
  const { user } = useContext(UserContext);
  const allProductExtras = useProductExtras();
  const allUserCategories = useUserCategories();
  const {
    setFormModalOpened,
    setIsLoading,
    setFetchCount,
    selectedProduct,
    setSelectedProduct,
    allFetchedProducts
  } = useContext(ProductsContext);
  const schema = Yup.object().shape({
    name: Yup.string().required('ادخل اسم المنتج'),
    price: Yup.string()
      .required('ادخل سعر المنتج')
      .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام'),
    quantity: Yup.string()
      .required('أدخل كمية المنتج')
      .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام'),
    category_id: Yup.string().required('إختار فئة المنتج'),
    notes: Yup.string().required('ادخل ملاحظات المنتج'),
    extras: Yup.array().of(
      Yup.object().shape({
        extra_id: Yup.string().test(
          'extras.index.extra_id',
          'اختار إضافة اخرى',
          (v, context) => {
            let result = true;
            if (
              v &&
              context.from[1]?.value?.extras?.length > 0 &&
              context.from[1]?.value?.extras.filter((p) => p.extra_id == v)
                ?.length > 1
            ) {
              result = false;
            }
            return result;
          }
        ),

        price: Yup.string()
          .test('extras.index.price', 'ادخل سعر المنتج', (v, context) => {
            let result = true;
            // if the bill of type extras === 1 and no value of price
            if ((!v || v == 0) && context?.parent?.extra_id) result = false;
            return result;
          })
          .test(
            'extras.index.price',
            'لا يسمح الا بكتابة الارقام',
            (v, context) => {
              let result = true;
              if (
                v &&
                !v.match(/^(\d+)?(\.\d+)?$/) &&
                context?.parent?.extra_id
              )
                result = false;
              return result;
            }
          )
        // colors: Yup.array().test(
        //   'extras.index.colors',
        //   'colors error',
        //   (v, context) => {
        //     let result = true;
        //     console.log('context : ', context);
        //     console.log('v : ', v);
        //     console.log('all product extras : ', allProductExtras);
        //     if (context?.parent?.extra_id) {
        //       const found =
        //         allProductExtras?.length > 0 &&
        //         allProductExtras.find((ex) => ex.id == context.parent.extra_id);
        //       console.log('found: ', found);
        //       console.log('context colors : ', context.parent.colors);
        //       console.log('watch : ', watch());
        //       if (
        //         found?.type == 2 &&
        //         (!context.parent.colors || context.parent?.colors?.length === 0)
        //       ) {
        //         result = false;
        //       }
        //     }
        //     return result;
        //   }
        // )
      })
    )
  });

  const {
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    register,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      price: '',
      category_id: '',
      notes: '',
      image: null
      // extras: [{ extra_id: '', price: '', colors: [] }]
      // extras: [
      //   {
      //     extra_id: '',
      //     price: '',
      //     colors: [],
      //     extra_custom_id: nanoid()
      //   }
      // ]
    }
  });
  const {
    fields: extrasFields,
    append: appendExtraField,
    remove: removeExtraField
  } = useFieldArray({
    control,
    name: 'extras'
  });

  // useEffect(() => {
  //   if (watch('extras')?.length > 0) {
  //     for (let i = 0; i < watch('extras').length; i++) {
  //       const found =
  //         allProductExtras?.length > 0 &&
  //         allProductExtras.find(
  //           (obj) => obj.id == watch(`extras.${i}.extra_id`)
  //         );
  //       console.log('found : ', found);
  //       console.log('watch me : ', watch(`extras.${i}.colors`));
  //       console.log(
  //         'watch bool : ',
  //         !watch(`extras.${i}.colors`) ||
  //           watch(`extras.${i}.colors`)?.length === 0
  //       );
  //       if (
  //         found?.type == 2 &&
  //         (!watch(`extras.${i}.colors`) ||
  //           watch(`extras.${i}.colors`)?.length === 0)
  //       ) {
  //         setError(`extras.${i}.colors`, {
  //           message: 'من فضلك اختار الالوان'
  //         });
  //       } else {
  //         clearErrors(`extras.${i}.colors`);
  //       }
  //     }
  //   }
  // }, [watch()]);
  const [validForm, setValidForm] = React.useState(false);
  const [submitFormCount, setSubmitFormCount] = React.useState(0);
  const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);
  const [formValues, setFormValues] = React.useState(null);

  const onSubmit = async (data) => {
    let valid = false;
    if (watch('extras')?.length > 0) {
      for (let i = 0; i < watch('extras').length; i++) {
        const found =
          allProductExtras?.length > 0 &&
          allProductExtras.find(
            (obj) => obj.id == watch(`extras.${i}.extra_id`)
          );
        if (
          found?.type == 2 &&
          (!watch(`extras.${i}.colors`) ||
            watch(`extras.${i}.colors`)?.length === 0)
        ) {
          setError(`extras.${i}.colors`, {
            message: 'من فضلك اختار الالوان'
          });
          valid = false;
          break;
        } else {
          clearErrors(`extras.${i}.colors`);
          valid = true;
          setSubmitFormCount((prev) => prev + 1);
        }
      }
    } else {
      valid = true;
      setSubmitFormCount((prev) => prev + 1);
    }
    setValidForm(valid);
    setFormValues({ ...data });
  };

  useEffect(() => {
    if (validForm && formValues) {
      const sumbitForm = async () => {
        const formData = new FormData();
        if (formValues.name) formData.append('name', formValues.name);
        if (String(formValues.price))
          formData.append('price', formValues.price);
        if (String(formValues.quantity))
          formData.append('quantity', formValues.quantity);
        if (formValues.category_id)
          formData.append('category_id', formValues.category_id);
        if (formValues.notes) formData.append('notes', formValues.notes);
        if (formValues.extras?.length > 0) {
          formData.append('extras', JSON.stringify(formValues.extras));
        }
        if (formValues?.image?.length > 0) {
          formData.append('image', formValues.image[0]);
        }

        console.log('count');

        try {
          setIsSubmittingForm(true);
          setIsLoading(true);
          let res;
          if (selectedProduct) {
            formData.append('id', selectedProduct.id);
            res = await ceateNewProductApi(user?.token, formData, true);
          } else {
            res = await ceateNewProductApi(user?.token, formData);
          }

          if (checkRes(res)) {
            setIsLoading(false);
            setIsSubmittingForm(false);
            setFormModalOpened(false);
            setSelectedProduct(null);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم اضافه المنتج بنجاح'
            });
          } else {
            setIsLoading(false);
            setIsSubmittingForm(false);
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        } catch (error) {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: 'حدث خطأ',
            message: 'حاول فى وقت لاحق'
          });
          console.log(error);
        }
      };
      sumbitForm();
    }
  }, [formValues]);

  console.log('form values : ', formValues);
  console.log('valid form : ', validForm);

  // handle initial values
  useEffect(() => {
    if (selectedProduct) {
      setValue('name', selectedProduct?.name ? selectedProduct.name : '');
      setValue('price', selectedProduct?.price ? selectedProduct.price : '');
      setValue(
        'quantity',
        selectedProduct?.quantity ? selectedProduct.quantity : ''
      );
      setValue(
        'category_id',
        selectedProduct?.category ? String(selectedProduct.category.id) : ''
      );
      setValue('notes', selectedProduct?.notes ? selectedProduct.notes : '');
    }
    if (selectedProduct) {
      const sharedReset = {
        name: selectedProduct.name,
        price: selectedProduct.price,
        quantity: selectedProduct.quantity,
        category_id: String(selectedProduct.category?.id),
        notes: selectedProduct.notes
      };
      if (selectedProduct?.product_extras?.length > 0) {
        let mappedProductExtras = [];
        mappedProductExtras = selectedProduct.product_extras.map((extra) => ({
          extra_id: String(extra?.extra?.id),
          id: String(extra?.product_extra_id),
          price: extra?.price,
          extra_custom_id: extra.extra.id,
          disabledSelect: true,
          colors:
            extra.colors?.length > 0
              ? extra.colors.map((c) => ({
                  ...c,
                  extra_custom_id: extra.extra.id,
                  color_custom_id: c.id
                }))
              : []
        }));

        reset({
          ...sharedReset,
          extras:
            selectedProduct?.product_extras?.length > 0
              ? [...mappedProductExtras]
              : []
        });
      }
    }
  }, [selectedProduct, selectedProduct?.id, reset]);

  // console.log('watch : ', watch());
  // console.log('errors : ', errors);

  const [form] = Form.useForm();
  return (
    <Form
      className="create-product-form custom-shared-form"
      form={form}
      layout="vertical"
      fields={[
        {
          name: 'extras',
          value: watch('extras')
        }
      ]}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">اســم المنتج</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder="اسـم المنتج..."
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">سـعر المنتج</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="price"
              type="text"
              placeholder="سـعر المنتج..."
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">كمية المنتج</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="quantity"
              type="text"
              placeholder="كمية المنتج..."
              errorMsg={errors?.quantity?.message}
              validateStatus={errors?.quantity ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="select-label-wrap">
          <p className="label-p">فئة المنتج</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="category_id"
              errorMsg={errors?.category_id?.message}
              validateStatus={errors?.category_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="فئة المنتج"
              options={
                allUserCategories?.length > 0
                  ? allUserCategories.map((p) => ({
                      title: p?.name,
                      value: p?.id
                    }))
                  : []
              }
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="create-product-form"
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">ملاحظات</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="notes"
              rows={8}
              placeholder="ملاحظات..."
              errorMsg={errors?.notes?.message}
              validateStatus={errors?.notes ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="products-add-btn-wrap">
          <div
            className="add-new-field-btn"
            onClick={() =>
              appendExtraField({
                extra_custom_id: nanoid(),
                extra_id: '',
                price: '',
                disabledSelect: false
              })
            }
          >
            <span>+ إضافة جديدة</span>
          </div>

          <RenderExtrasFields
            allFetchedProducts={allFetchedProducts}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            allExtras={allProductExtras}
            extrasFields={extrasFields}
            removeExtraField={removeExtraField}
            watch={watch}
            errors={errors}
            setValue={setValue}
            setError={setError}
            clearErrors={clearErrors}
            control={control}
            register={register}
          />
        </div>

        <FileInput
          name="image"
          label="صورة المنتج"
          // accept="image/png, image/jpg, image/jpeg, image/gif"
          accept="image/*"
          multiple={false}
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          // setUrls={setUrls}
          dropzoneText="اسحب صورة المنتج وضعها هنا ..."
          className="product-images-dropzone"
          dropzoneUrls={
            selectedProduct?.image ? [{ url: selectedProduct.image }] : []
          }
          canDelete={false}
        />

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          إضافة
        </Button>
      </div>
    </Form>
  );
};

export default ProductTableForm;
