import { ReadOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import countWords from '../../utils/countWords';
import trimWords from '../../utils/trimWords';
import checkRes from '../../utils/checkRes';
import EditIcon from '../../common/icons/EditIcon';
import BlockedIcon from '../../common/icons/BlockedIcon';
import VerifiedIcon from '../../common/icons/VerifiedIcon';
import toggleProductActivationApi from '../../apis/products/toggleProductActivationApi';

const tableColumns = (
  allFetchedProducts,
  setFormModalOpened,
  setImagesModalOpened,
  setFetchCount,
  setSelectedProduct,
  setIsLoading,
  token
) => {
  const renderProductExtras = (arr) => {
    return (
      <ul className="p-names-ul">
        {arr.map((obj) => (
          <li
            style={{ marginBottom: 12 }}
            key={obj?.extra?.id}
            className="product-name"
          >
            {obj?.extra?.image ? (
              <img
                style={{
                  width: 18,
                  borderRadius: 4,
                  objectFit: 'cover',
                  objectPosition: 'top center',
                  marginLeft: 8
                }}
                src={obj.extra.image}
                alt="extra"
              />
            ) : (
              ''
            )}
            {obj?.extra?.name ? obj.extra.name : ''}
          </li>
        ))}
      </ul>
    );
  };

  const handleEditClick = (record) => {
    const found = allFetchedProducts.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedProduct({ ...found });
      setFormModalOpened(true);
    }
  };
  const toggleProductActivation = async (record) => {
    try {
      setIsLoading(true);
      const res = await toggleProductActivationApi(token, { id: record.id });
      if (checkRes(res)) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تغيير حالة المنتج بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };
  const handleProductImagesModal = (record) => {
    const found = allFetchedProducts.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedProduct({ ...found });
      setImagesModalOpened(true);
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          تعديل
        </div>

        {obj?.user_category?.active === 1 && (
          <Button
            className={`custom-shared-btn ${!obj.active ? 'dimmed' : ''}`}
            type="primary"
            size="large"
            icon={
              !obj.active ? (
                <BlockedIcon color="#444" />
              ) : (
                <VerifiedIcon color="#fff" />
              )
            }
            onClick={() => toggleProductActivation(obj)}
            // style={{ paddingBottom: 10 }}
          >
            {obj.active ? 'مفعل' : 'غير مفعل'}
          </Button>
        )}
      </div>
    );
  };

  return [
    {
      title: 'إســم المنتج',
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.name ? record.name : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'صورة المنتج',
      dataIndex: 'image',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="img-wrap">
              {record?.image ? (
                <img
                  style={{
                    width: 62,
                    height: 62,
                    borderRadius: 4,
                    objectFit: 'cover',
                    objectPosition: 'top center',
                    border: '1px solid rgba(0, 0, 0, .1)'
                  }}
                  src={record.image}
                  alt={record.name}
                />
              ) : (
                ''
              )}
            </span>
          </div>
        );
      }
    },
    {
      title: 'سعر المنتج',
      dataIndex: 'price',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.price ? record.price + ' ريال' : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'القســم',
      dataIndex: 'category',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.category?.name ? record.category.name : '---'}
            </span>
          </div>
        );
      }
    },
    {
      title: 'الإضافات',
      dataIndex: 'extras',
      // width: 192,
      render: (_, record) => {
        if (record?.product_extras?.length >= 0) {
          return (
            <Popover
              title="إضافات المنتج"
              content={renderProductExtras(record.product_extras)}
            >
              <Button
                size="large"
                type="dashed"
                shape="round"
                icon={<EyeOutlined />}
              />
            </Popover>
          );
        } else {
          return '----';
        }
      }
    },

    {
      title: 'ملاحظات',
      dataIndex: 'notes',
      render: (_, record) => {
        if (record.notes) {
          const wordsCount = countWords(String(record.notes));
          if (wordsCount > 3) {
            return (
              <Popover title="اقرأ المزيد" content={String(record.notes)}>
                <Button size="large" type="text">
                  <p className="has-read-more">
                    <span>{trimWords(String(record.notes), 4)}</span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return (
              <div className="row-cell row-text">{String(record.notes)}</div>
            );
        } else {
          return '----';
        }
      }
    },

    {
      title: 'صور المنتج',
      dataIndex: 'details',
      // width: 192,
      render: (_, record) => {
        return (
          <Tooltip title="عرض التفاصيل">
            <Button
              size="large"
              type="dashed"
              shape="round"
              icon={<EyeOutlined />}
              onClick={() => handleProductImagesModal(record)}
            />
          </Tooltip>
        );
      }
    },

    {
      title: 'الاعدادات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
