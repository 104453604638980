import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  formModalOpened: false,
  setFormModalOpened: (v) => {},
  imagesModalOpened: false,
  setImagesModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedProducts: null,
  setAllFetchedProducts: (v) => {},
  productsPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setProductsPagination: (v) => {},
  selectedProduct: null,
  setSelectedProduct: (v) => {},
  //
  colorPickerModalOpened: false,
  setColorPickerModalOpened: (v) => {},
  selectedColorPicker: null,
  setSelectedColorPicker: (v) => {},
  // filter
  productsFilter: {
    page: 1,
    mame: '',
    category_id: '',
    // seller_id: user?.id,
    active: ''
  },
  setProductsFilter: (v) => {}
};

const ProductsContext = createContext(INITIAL_VALUES);

export const ProductsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [formModalOpened, setFormModalOpened] = useState(
    INITIAL_VALUES.formModalOpened
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedProducts, setAllFetchedProducts] = useState(
    INITIAL_VALUES.allFetchedProducts
  );
  const [selectedProduct, setSelectedProduct] = useState(
    INITIAL_VALUES.selectedProduct
  );
  const [productsPagination, setProductsPagination] = useState(
    INITIAL_VALUES.productsPagination
  );
  const [imagesModalOpened, setImagesModalOpened] = useState(
    INITIAL_VALUES.imagesModalOpened
  );
  //
  const [colorPickerModalOpened, setColorPickerModalOpened] = useState(
    INITIAL_VALUES.colorPickerModalOpened
  );
  const [selectedColorPicker, setSelectedColorPicker] = useState(
    INITIAL_VALUES.selectedColorPicker
  );
  //
  const [productsFilter, setProductsFilter] = useState(
    INITIAL_VALUES.selectedColorPicker
  );

  return (
    <ProductsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        formModalOpened,
        setFormModalOpened,
        imagesModalOpened,
        setImagesModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedProducts,
        setAllFetchedProducts,
        selectedProduct,
        setSelectedProduct,
        productsPagination,
        setProductsPagination,
        //
        colorPickerModalOpened,
        setColorPickerModalOpened,
        selectedColorPicker,
        setSelectedColorPicker,
        //
        productsFilter,
        setProductsFilter
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsContext;
