import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import AllExtrasContext from '../../contexts/all-extras-context/AllExtrasContext';

const PageModal = () => {
  const { modalOpened, setModalOpened, setSelectedExtra, selectedExtra } =
    useContext(AllExtrasContext);

  return (
    <Modal
      transitionName=""
      className="product-form-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <div className="modal-title">
          {selectedExtra ? 'تعديل الإضافة' : 'إضافة جديدة'}
        </div>
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedExtra(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedExtra(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default PageModal;
