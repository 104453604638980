/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import { Button, Select } from "antd";
import UserContext from "../../contexts/user-context/UserProvider";
import ProductsContext from "../../contexts/products-context/ProductsContext";
import getAllProductsApi from "../../apis/products/getAllProductsApi";
import checkRes from "../../utils/checkRes";
import SelectDropDown from "../../common/icons/SelectDropDown";
import SearchIcon from "../../common/icons/SearchIcon";
import AddCircledIcon from "../../common/icons/AddCircledIcon";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import useUserCategories from "../../custom-hooks/useUserCategories";
import "./TableFilter.scss";
import routerLinks from "../../components/app/routerLinks";

const TableFilter = () => {
  const history = useHistory();
  const { Option } = Select;
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    setIsLoading,
    setAllFetchedProducts,
    setProductsPagination,
    setFormModalOpened,
    productsFilter,
    setProductsFilter,
  } = useContext(ProductsContext);
  const allUserCategories = useUserCategories();
  // const [filterValues, setFilterValues] = React.useState({
  //   mame: '',
  //   category_id: '',
  //   seller_id: user?.id,
  //   active: ''
  // });

  async function handleChange(filterType, value) {
    // console.log(`selected ${value}`);
    setProductsFilter((prevState) => {
      if (filterType === "name") {
        return {
          ...prevState,
          name: value,
        };
      } else if (filterType === "category_id") {
        return {
          ...prevState,
          category_id: value,
        };
      } else if (filterType === "active") {
        return {
          ...prevState,
          active: value,
        };
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await getAllProductsApi(user?.token, {
        ...productsFilter,
        ...values,
        page: 1,
      });

      // is response is success
      if (checkRes(res) && res.data?.data?.data?.length >= 0) {
        const data = res.data.data.data;
        history.push(`${routerLinks.products}?page=1`);
        setAllFetchedProducts(data);
        if (res.data.data?.pagination) {
          setProductsPagination(res.data.data.pagination);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  return (
    <div className="custom-table-fitler" style={{ marginTop: 42 }}>
      <div className="form-excel-export-wrap" style={{ gap: 12 }}>
        <div className="add-section">
          <Button
            type="primary"
            className="custom-shared-btn"
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              height: 42,
            }}
            onClick={() => {
              setFormModalOpened(true);
            }}
          >
            <AddCircledIcon color="#fff" />
            إضافة منتج جديد
          </Button>
        </div>
        <form className="filter-form" onSubmit={handleSubmit}>
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder="أدخل اسم المنتج للبحث"
                type="text"
                name="table_search"
                onChange={(e) => handleChange("name", e.target.value)}
              />
            </label>
          </div>

          <label className="entries-wrap">
            {/* <span className="select-title">إختار الموظف</span> */}
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange("category_id", v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الاقسـام</Option>
              {allUserCategories?.length > 0 &&
                allUserCategories.map((obj, index) => (
                  <Option key={index} value={obj.id}>
                    {obj.name}
                  </Option>
                ))}
            </Select>
          </label>
          <label className="entries-wrap">
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange("active", v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الحالات</Option>
              <Option value={1}>مفعل</Option>
              <Option value={2}>غير مفعل</Option>
            </Select>
          </label>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button>
        </form>
      </div>
    </div>
  );
};
export default TableFilter;
