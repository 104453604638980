import React, { useEffect, useState } from "react";
import { ForgetPasswordProvider } from "../../contexts/forget-password-context/ForgetPasswordContext";
import LoginForm from "./LoginForm";
import "./LoginPage.scss";

const LoginPage = () => {
  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
    document.title = "تسجيل الدخول";
  }, [isMount]);
  return (
    <div className="login-page">
      <div className="custom-container">
        <div className="page-wrapper">
          <div className="text-section">
            <div className="text-logo">
              <img
                className="logo-itself"
                src="/assets/imgs/logo/logo.png"
                alt="logo"
              />
            </div>
          </div>
          <div className="form-top-level-wrapper">
            <ForgetPasswordProvider>
              <LoginForm />
            </ForgetPasswordProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
