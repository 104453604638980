/* eslint-disable eqeqeq */
import { Button } from 'antd';
import BlockedIcon from '../../common/icons/BlockedIcon';
import VerifiedIcon from '../../common/icons/VerifiedIcon';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';
import checkRes from '../../utils/checkRes';
import EditIcon from '../../common/icons/EditIcon';
import toggleCategoryActivationApi from '../../apis/categories-apis/toggleCategoryActivationApi';

const tableColumns = (
  allFetchedCategories,
  setModalOpened,
  setFetchCount,
  setSelectedCategory,
  setIsLoading,
  token
) => {
  const handleEditClick = (record) => {
    const found = allFetchedCategories.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedCategory({ ...found });
      setModalOpened(true);
    }
  };

  const handleCategoryStateChange = async (record) => {
    try {
      setIsLoading(true);
      const res = await toggleCategoryActivationApi(
        {
          category_id: record.id
        },
        token
      );
      if (checkRes(res)) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تغيير الحالة بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };
  return [
    {
      title: 'صورة فئة المنتج',
      dataIndex: 'image',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="img-wrap">
              {record?.image ? (
                <img
                  style={{
                    width: 62,
                    height: 62,
                    borderRadius: 4,
                    objectFit: 'cover',
                    objectPosition: 'top center',
                    border: '1px solid rgba(0, 0, 0, .1)'
                  }}
                  src={record.image}
                  alt={record.name}
                />
              ) : (
                ''
              )}
            </span>
          </div>
        );
      }
    },
    {
      title: 'إســم الفئة',
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.name ? record.name : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'حالة الفئة',
      dataIndex: 'status',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            <Button
              className={`custom-shared-btn ${
                record.active === 0 ? 'dimmed' : ''
              }`}
              type="primary"
              size="large"
              icon={
                record.active == 0 ? (
                  <BlockedIcon color={record.active == 0 ? '#444' : '#fff'} />
                ) : (
                  <VerifiedIcon color={record.active == 0 ? '#444' : '#fff'} />
                )
              }
              onClick={() => handleCategoryStateChange(record)}
              // style={{ paddingBottom: 10 }}
            >
              {record.active === 1 && 'مفعل'}
              {record.active === 0 && 'غير مفعل'}
            </Button>
          </div>
        );
      }
    },
    {
      title: 'الاعدادات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            <div className="edit-btn" onClick={() => handleEditClick(record)}>
              <EditIcon />
              تعديل
            </div>
          </div>
        );
      }
    }
  ];
};

export default tableColumns;
