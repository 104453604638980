import React, { useContext, useEffect, useState } from "react";
import SearchNotificationsProfile from "../../components/search-notifications-profile/SearchNotificationsProfile";
import PageTable from "./PageTable";
import TableFilter from "./TableFilter";
import AllDelegatesDetailsModal from "./AllDelegatesDetailsModal";
import AllDelegatesContext from "../../contexts/all-delegates-context/AllDelegatesContext";
import "./DelegatesPage.scss";
import AllDelegatesTableFormModal from "./AllDelegatesTableFormModal";

const AllDelegatesPage = () => {
  const { modalOpened, detailsModalOpened } = useContext(AllDelegatesContext);
  const [isMount, setIsMount] = useState(false);
  useEffect(() => {
    setIsMount(true);
    document.title = "المناديب";
  }, [isMount]);
  return (
    <div className="custom-container">
      <div className="orders-page shared-custom-page">
        <div className="page-head-wrap">
          <div className="custom-page-title-profile-wrap">
            <div className="page-title">
              <h2 className="bold-font">المندوبين</h2>
              <p>كشف وعرض كافة المندوبين</p>
            </div>
            <SearchNotificationsProfile />
          </div>
        </div>

        <TableFilter />
        <PageTable />

        {modalOpened && <AllDelegatesTableFormModal />}
        {detailsModalOpened && <AllDelegatesDetailsModal />}
      </div>
    </div>
  );
};

export default AllDelegatesPage;
