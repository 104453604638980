/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import UserContext from '../../contexts/user-context/UserProvider';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import FileInput from '../../common/file-input/FileInput';
import createNewDelegateApi from '../../apis/orders/createNewDelegateApi';
import UserDelegatesContext from '../../contexts/user-delegates-context/UserDelegatesContext';
import getUserDelegatesSchema from './userDelegatesSchema';
import updateDelegateApi from '../../apis/orders/updateDelegateApi';
import CustomMap from '../../components/custom-map/CustomMap';
import './DelegateTableForm.scss';

const DelegatesTableForm = () => {
  // const [urls, setUrls] = React.useState([]);
  const { user } = useContext(UserContext);
  const [selectedLocation, setSelecectedLocation] = React.useState({
    lat: '',
    lng: ''
  });
  const [selectedAddress, setSelectedAddress] = React.useState('');
  const [passwrodVisible, setPasswordVisible] = useState(false);
  const { setModalOpened, setIsLoading, setFetchCount, selectedDelegate } =
    useContext(UserDelegatesContext);
  const schema = getUserDelegatesSchema(selectedDelegate);
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    register,
    unregister,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      password: '',
      confirm_password: '',
      nationality: '',
      address: '',
      identity_number: '',
      driving_image: null,
      vehicle_images: null,
      vehicle_registration: null,
      vehicle_tablet_image: null
    }
  });

  console.log('watch : ', watch());
  console.log('errors : ', errors);

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data.name) formData.append('name', data.name);
    if (data.phone) formData.append('phone', data.phone);
    if (data.email) formData.append('email', data.email);
    if (data.password) formData.append('password', data.password);
    if (data.nationality) formData.append('nationality', data.nationality);
    if (data.address) formData.append('address', data.address);
    if (data.identity_number)
      formData.append('identity_number', data.identity_number);
    if (data?.driving_image?.length > 0) {
      formData.append('driving_image', data.driving_image[0]);
    }
    if (data?.vehicle_images?.length > 0) {
      for (let i of data.vehicle_images) {
        formData.append('vehicle_images[]', i);
      }
    }
    if (data?.vehicle_registration?.length > 0) {
      formData.append('vehicle_registration', data.vehicle_registration[0]);
    }
    if (data?.vehicle_tablet_image?.length > 0) {
      formData.append('vehicle_tablet_image', data.vehicle_tablet_image[0]);
    }
    formData.append(
      'lat',
      selectedLocation?.lat ? selectedLocation.lat : '23.8859'
    );
    formData.append(
      'lng',
      selectedLocation?.lng ? selectedLocation.lng : '45.0792'
    );

    try {
      setIsLoading(true);
      let res;
      if (selectedDelegate) {
        formData.append('user_id', selectedDelegate.id);
        res = await updateDelegateApi(user?.token, formData, true);
      } else {
        res = await createNewDelegateApi(user?.token, formData);
      }

      if (checkRes(res)) {
        setIsLoading(false);
        setModalOpened(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم اضافه المندوب بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ',
          message: res?.data?.message ? res.data.message : 'حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
      console.log(error);
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedDelegate) {
      setValue('name', selectedDelegate?.name ? selectedDelegate.name : '');
      setValue('phone', selectedDelegate?.phone ? selectedDelegate.phone : '');
      setValue('email', selectedDelegate?.email ? selectedDelegate.email : '');

      setValue(
        'nationality',
        selectedDelegate?.information?.nationality
          ? selectedDelegate.information.nationality
          : ''
      );
      setValue(
        'address',
        selectedDelegate?.address ? selectedDelegate.address : ''
      );
      setValue(
        'identity_number',
        selectedDelegate?.information?.identity_number
          ? selectedDelegate.information.identity_number
          : ''
      );
      if (selectedDelegate?.lat && selectedDelegate?.lng) {
        setSelecectedLocation({
          lat: parseFloat(selectedDelegate.lat),
          lng: parseFloat(selectedDelegate.lng)
        });
      }
    }
  }, [selectedDelegate, selectedDelegate?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="create-delegate-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="name-phone-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">اســم المندوب</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="name"
                type="text"
                placeholder="اسـم المندوب..."
                errorMsg={errors?.name?.message}
                validateStatus={errors?.name ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">رقم الهاتف</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="phone"
                type="text"
                placeholder="رقم الهاتف..."
                errorMsg={errors?.phone?.message}
                validateStatus={errors?.phone ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="email-nationality-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">البريد الاكترونى</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="email"
                type="text"
                placeholder="البريد الاكترونى..."
                errorMsg={errors?.email?.message}
                validateStatus={errors?.email ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">الجنســية</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="nationality"
                type="text"
                placeholder="الجنســية..."
                errorMsg={errors?.nationality?.message}
                validateStatus={errors?.nationality ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="address-identity-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">العنوان</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="address"
                type="text"
                placeholder="العنوان..."
                errorMsg={errors?.address?.message}
                validateStatus={errors?.address ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">رقم الهوية</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="identity_number"
                type="text"
                placeholder="رقم الهوية..."
                errorMsg={errors?.identity_number?.message}
                validateStatus={errors?.identity_number ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>
        {!selectedDelegate && (
          <div className="passwords-wrap">
            <div className="text-field-label-wrap">
              <p className="label-p">كلمة المرور</p>
              <div className="text-field-wrap with-eye-icon">
                <AntdTextField
                  className="form-text-field"
                  name="password"
                  type={passwrodVisible ? 'text' : 'password'}
                  placeholder="كلمة المرور..."
                  errorMsg={errors?.password?.message}
                  validateStatus={errors?.password ? 'error' : ''}
                  control={control}
                />

                {watch('password') && (
                  <div
                    className="eye-icon-btn"
                    onClick={() => {
                      setPasswordVisible((prevState) => !prevState);
                    }}
                  >
                    {passwrodVisible ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="text-field-label-wrap">
              <p className="label-p">تأكيد كلمة المرور</p>
              <div className="text-field-wrap with-eye-icon">
                <AntdTextField
                  className="form-text-field"
                  name="confirm_password"
                  type={passwrodVisible ? 'text' : 'password'}
                  placeholder="تأكيد كلمة المرور..."
                  errorMsg={errors?.confirm_password?.message}
                  validateStatus={errors?.confirm_password ? 'error' : ''}
                  control={control}
                />

                {watch('confirm_password') && (
                  <div
                    className="eye-icon-btn"
                    onClick={() => {
                      setPasswordVisible((prevState) => !prevState);
                    }}
                  >
                    {passwrodVisible ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <CustomMap
          width="100%"
          height="400px"
          selectedLocation={selectedLocation}
          setSelecectedLocation={setSelecectedLocation}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />

        <div className="file-inputs-wrap">
          <FileInput
            name="driving_image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label="صورة القيادة"
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب صورة القيادة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedDelegate?.information?.driving_image
                ? [{ url: selectedDelegate.information.driving_image }]
                : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.driving_image?.message}
          />
          <FileInput
            name="vehicle_images"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label="صورة المركبة"
            accept="image/*"
            multiple={true}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب صورة المركبة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedDelegate?.vehicle_images?.length > 0
                ? selectedDelegate.vehicle_images.map((obj) => ({
                    ...obj,
                    url: obj.image
                  }))
                : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.vehicle_images?.message}
          />
          <FileInput
            name="vehicle_registration"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label="صورة استمارة المركبة"
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب صورة استمارة المركبة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedDelegate?.information?.vehicle_registration
                ? [{ url: selectedDelegate.information.vehicle_registration }]
                : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.vehicle_registration?.message}
          />
          <FileInput
            name="vehicle_tablet_image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label="صورة لوحة المركبة"
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب صورة لوحة المركبة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedDelegate?.information?.vehicle_tablet_image
                ? [{ url: selectedDelegate.information.vehicle_tablet_image }]
                : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.vehicle_tablet_image?.message}
          />
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmitting}
        >
          إضافة
        </Button>
      </div>
    </Form>
  );
};

export default DelegatesTableForm;
