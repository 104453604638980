/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import { Select, DatePicker } from "antd";
import { useHistory } from "react-router-dom";
import UserContext from "../../contexts/user-context/UserProvider";
import checkRes from "../../utils/checkRes";
import SelectDropDown from "../../common/icons/SelectDropDown";
import SearchIcon from "../../common/icons/SearchIcon";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import getAllOrdersApi from "../../apis/orders/getAllOrdersApi";
import OrdersContext from "../../contexts/orders-context/OrdersContext";
import CalendarIcon from "../../common/icons/CalendarIcon";
import routerLinks from "../../components/app/routerLinks";
const { RangePicker } = DatePicker;
const TableFilter = () => {
  const history = useHistory();
  const { Option } = Select;
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    setIsLoading,
    setAllFetchedOrders,
    setOrdersPagination,
    ordersFilter,
    setOrdersFilter,
  } = useContext(OrdersContext);
  const [selectedPicker, setSelectedPicker] = React.useState(null);
  // const [filterValues, setFilterValues] = React.useState({
  //   client_name: '',
  //   payment_type: '',
  //   payment_status: '',
  //   from_date: '',
  //   to_date: ''
  // });

  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string,
    });
  };

  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setOrdersFilter((prev) => ({
        ...prev,
        from_date: selectedPicker.strings[0],
        to_date: selectedPicker.strings[1],
      }));
    }
  }, [selectedPicker?.strings]);

  async function handleChange(filterType, value) {
    setOrdersFilter((prevState) => {
      if (filterType === "client_name") {
        return {
          ...prevState,
          client_name: value,
        };
      } else if (filterType === "payment_type") {
        return {
          ...prevState,
          payment_type: value,
        };
      } else if (filterType === "payment_status") {
        return {
          ...prevState,
          payment_status: value,
        };
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await getAllOrdersApi(user?.token, {
        ...ordersFilter,
        ...values,
        page: 1,
      });

      // is response is success
      if (checkRes(res) && res.data?.data?.data?.length >= 0) {
        const data = res.data.data.data;
        history.push(`${routerLinks.orders}?page=1`);
        setAllFetchedOrders(data);
        if (res.data.data?.pagination) {
          setOrdersPagination(res.data.data.pagination);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  return (
    <div className="custom-table-fitler" style={{ marginTop: 42 }}>
      <div className="form-excel-export-wrap">
        <form
          className="filter-form"
          style={{ gap: 8 }}
          onSubmit={handleSubmit}
        >
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder="أدخل اسم العميل للبحث"
                type="text"
                name="client_name"
                onChange={(e) => handleChange("client_name", e.target.value)}
              />
            </label>
          </div>

          <label className="entries-wrap">
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange("payment_type", v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>نوع الدفع</Option>
              <Option value={1}>كاش</Option>
              <Option value={2}>إلكترونى</Option>
            </Select>
          </label>
          <label className="entries-wrap">
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange("payment_status", v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>حالة الطلب</Option>
              <Option value={2}>المدفوع</Option>
              <Option value={1}>الغير مدفوع</Option>
            </Select>
          </label>

          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              // format="MM/DD/YYYY"
              className={`filter-range-picker`}
              allowClear={true}
              suffixIcon={<CalendarIcon />}
              placeholder={["تاريخ من", "تاريخ الى"]}
            />
          </div>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button>
        </form>
      </div>
    </div>
  );
};
export default TableFilter;
