import axios from 'axios';

const changeOrderStatusApi = async (values, token) => {
  try {
    const url = '/change-order-status';
    const res = await axios.post(
      url,
      {
        order_id: values.order_id,
        status: values.status
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token,
          lang: 'ar'
        }
      }
    );
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default changeOrderStatusApi;
