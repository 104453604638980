const routerLinks = {
  ////////////////////////////
  homePage: '/',
  signinPage: '/',
  orders: '/orders',
  userCategories: '/user-categories',
  allCategories: '/all-categories',
  allExtras: '/all-extras',
  products: '/products',
  delegates: '/delegates',
  allDelegates: '/all-delegates',
  sellers: '/sellers',
  messages: '/messages',
  families: '/productive-families',
  userClients: '/user-clients',
  allClients: '/all-clients',
  //////////////////////////////
  //////////////////////////////
  //////////////////////////////
  // settingsPage: '/settings',

  notificationsPage: '/notifications',
  ProfilePage: '/profile',
  ////////////////////////////////
  notFound: '/not-found'
};

export default routerLinks;
