import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  detailsModalOpened: false,
  setDetailsModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedSellers: null,
  setAllFetchedSellers: (v) => {},
  sellersPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setSellersPagination: (v) => {},
  selectedSeller: null,
  setSelectedSeller: (v) => {},
  // filter
  sellersFilter: {
    page: 1,
    status: '',
    mame: '',
    category_id: '',
    activity: ''
  },
  setSellersFilter: (v) => {}
};

const SellersContext = createContext(INITIAL_VALUES);

export const SellersProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedSellers, setAllFetchedSellers] = useState(
    INITIAL_VALUES.allFetchedSellers
  );
  const [selectedSeller, setSelectedSeller] = useState(
    INITIAL_VALUES.selectedSeller
  );
  const [sellersPagination, setSellersPagination] = useState(
    INITIAL_VALUES.sellersPagination
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES.detailsModalOpened
  );
  const [sellersFilter, setSellersFilter] = useState(
    INITIAL_VALUES.sellersFilter
  );

  return (
    <SellersContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        detailsModalOpened,
        setDetailsModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedSellers,
        setAllFetchedSellers,
        selectedSeller,
        setSelectedSeller,
        sellersPagination,
        setSellersPagination,
        sellersFilter,
        setSellersFilter
      }}
    >
      {children}
    </SellersContext.Provider>
  );
};

export default SellersContext;
