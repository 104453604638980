import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedCategories: null,
  setAllFetchedCategories: (v) => {},
  selectedCategory: null,
  setSelectedCategory: (v) => {}
};

const UserCategoriesContext = createContext(INITIAL_VALUES);

export const UserCategoriesProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedCategories, setAllFetchedCategories] = useState(
    INITIAL_VALUES.allFetchedCategories
  );
  const [selectedCategory, setSelectedCategory] = useState(
    INITIAL_VALUES.selectedCategory
  );

  return (
    <UserCategoriesContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        allFetchedCategories,
        setAllFetchedCategories,
        selectedCategory,
        setSelectedCategory
      }}
    >
      {children}
    </UserCategoriesContext.Provider>
  );
};

export default UserCategoriesContext;
