/* eslint-disable eqeqeq */
import { Button, Tooltip } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import changeUserStatusApi from '../../apis/auth/changeUserStatusApi';
import checkRes from '../../utils/checkRes';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import BlockedIcon from '../../common/icons/BlockedIcon';
import VerifiedIcon from '../../common/icons/VerifiedIcon';

const tableColumns = (
  allFetchedSellers,
  setModalOpened,
  setDetailsModalOpened,
  setFetchCount,
  setSelectedSeller,
  setIsLoading,
  token
) => {
  const handleViewSellerDetails = (record) => {
    const found = allFetchedSellers.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedSeller({ ...found });
      setDetailsModalOpened(true);
    }
  };

  const handleDelegateStatus = (status) => {
    if (status === 1) return 3;
    else if (status === 2 || status === 3) return 1;
    return '';
  };
  const handleChangeDelegateStatus = async (record) => {
    try {
      setIsLoading(true);
      const res = await changeUserStatusApi(
        {
          user_id: record.id,
          status: handleDelegateStatus(record.status)
        },
        token
      );
      if (checkRes(res)) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تغيير الحالة بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };

  return [
    {
      title: 'صورة المتجر',
      dataIndex: 'image',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="img-wrap">
              {record?.image ? (
                <img
                  style={{
                    width: 62,
                    height: 62,
                    borderRadius: 4,
                    objectFit: 'cover',
                    objectPosition: 'top center',
                    border: '1px solid rgba(0, 0, 0, .1)'
                  }}
                  src={record.image}
                  alt={record.name}
                />
              ) : (
                ''
              )}
            </span>
          </div>
        );
      }
    },
    {
      title: 'إســم المتجر',
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.name ? record.name : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'نشاط المتجر',
      dataIndex: 'activity',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.activity ? record.activity : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.phone ? record.phone : ''}
            </span>
          </div>
        );
      }
    },
    {
      title: 'البريد الاكترونى',
      dataIndex: 'email',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.email ? record.email : ''}
            </span>
          </div>
        );
      }
    },
    // {
    //   title: 'تقييم المتجر',
    //   dataIndex: 'rate',
    //   width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">
    //           <Rate
    //             allowHalf
    //             disabled
    //             defaultValue={record.rate ? parseFloat(record.rate) : 0}
    //           />
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: 'حالة المتجر',
      dataIndex: 'status',
      render: (_, record) => {
        return (
          <Button
            className={`custom-shared-btn ${
              record.status == 2 || record.status == 3 ? 'dimmed' : ''
            }`}
            type="primary"
            size="large"
            icon={
              record.status == 2 || record.status == 3 ? (
                <BlockedIcon color="#444" />
              ) : (
                <VerifiedIcon color="#fff" />
              )
            }
            onClick={() => handleChangeDelegateStatus(record)}
            // style={{ paddingBottom: 10 }}
          >
            {record.status == 1 && 'مفعل'}
            {record.status == 2 && 'قيد الانتظار'}
            {record.status == 3 && 'غير مفعل'}
          </Button>
        );
      }
    },
    {
      title: 'تفاصيل المتجر',
      dataIndex: 'details',
      // width: 192,
      render: (_, record) => {
        return (
          <Tooltip title="عرض التفاصيل">
            <Button
              size="large"
              type="dashed"
              shape="round"
              icon={<EyeOutlined />}
              onClick={() => handleViewSellerDetails(record)}
            />
          </Tooltip>
        );
      }
    }
  ];
};

export default tableColumns;
