/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Table, Empty, Pagination } from "antd";
import tableColumns from "./tableColumns";
import checkRes from "../../utils/checkRes";
import { useHistory } from "react-router-dom";
import UserContext from "../../contexts/user-context/UserProvider";
import routerLinks from "../../components/app/routerLinks";
import getAllFamiliesApi from "../../apis/families-apis/getAllFamiliesApi";
import FamiliesContext from "../../contexts/families-context/FamiliesContext";

const PageTable = () => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    isLoading,
    setIsLoading,
    setModalOpened,
    setDetailsModalOpened,
    allFetchedFamilies,
    setAllFetchedFamilies,
    fetchCount,
    setFetchCount,
    setSelectedFamily,
    familiesPagination,
    setFamiliesPagination,
    familiesFilter,
  } = useContext(FamiliesContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
    }));
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await getAllFamiliesApi(user?.token, {
          familiesFilter,
          ...values,
        });
        if (isMounted) {
          // is response is success
          if (checkRes(res) && res.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedFamilies(data);
            if (res.data.data?.pagination) {
              setFamiliesPagination(res.data.data.pagination);
            }

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [fetchCount, user]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedFamilies?.length >= 0) {
      const mappedData = mapDataSource(allFetchedFamilies);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedFamilies, allFetchedFamilies?.length]);

  return (
    <>
      <Table
        locale={{
          emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>,
        }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedFamilies,
          setModalOpened,
          setDetailsModalOpened,
          setFetchCount,
          setSelectedFamily,
          setIsLoading,
          user?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      <Pagination
        defaultCurrent={1}
        current={familiesPagination.current_page}
        pageSize={familiesPagination.per_page}
        total={familiesPagination.total}
        // itemRender={itemRender}
        onChange={(page, pageSize) => {
          setFetchCount((prev) => prev + 1);
          history.push(`${routerLinks.families}?page=${page}`);
        }}
        hideOnSinglePage={true}
      />
    </>
  );
};

export default PageTable;
